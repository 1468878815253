import { apiGate } from '../../services/api_gate'



export async function getListOperadorEscala(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do getOperador') }
  const api = await apiGate.post('/crm/escala/operatorlistescala', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}


export async function getOperEscala(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do getOperador') }
  const api = await apiGate.post('/crm/escala/operatorget', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}



export async function setOperEscala(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou do setOperador')
  const api = await apiGate.post('/crm/escala/opertorsetescala', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}


export async function updateDisponibilidade(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou do updateCorretor')
  const api = await apiGate.post('/crm/escala/operatorupdatedisponibilidade', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}