import { Box, Checkbox, Divider, FormControl, FormControlLabel, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { TextFieldMask } from '../../../utils/TextfieldMask/TextfieldMask'

export const Caracteristicas2 = ({ setPlaceEdit, placeEdit, setNewPlace, newPlace }) => {

  // console.log('placeEdit', placeEdit)

  const [caracteristica, setCaracteristica] = useState(placeEdit)


  // console.log('caracteristica', caracteristica)

  useEffect(() => {
    // console.log('caracteristica', caracteristica)
    setPlaceEdit(caracteristica)
  }, [caracteristica])

  return (
    <Box>
      <Divider />
      <Box sx={{ display: 'flex', flexDirection: 'column', padding: '10px', }}>
        <FormControl
          sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px', gap: '10px' }}
        >
          <TextFieldMask
            label="Dorm. De:"
            variant="standard"
            mask="number"
            value={caracteristica.quartosqtdmin ? caracteristica.quartosqtdmin : ""}
            onChange={(e) => setCaracteristica({ ...caracteristica, quartosqtdmin: e.target.value })}
            onBlur={(e) => setCaracteristica({ ...caracteristica, quartosqtdmin: e.target.value })}
            sx={{ marginBottom: '10px', width: '80px' }}
            inputProps={{ maxLength: 2, style: { textAlign: 'center' } }}
          />
          <TextFieldMask
            label="Dorm. Até:"
            variant="standard"
            mask="number"
            value={caracteristica.quartosqtdmax ? caracteristica.quartosqtdmax : ""}
            onChange={(e) => setCaracteristica({ ...caracteristica, quartosqtdmax: e.target.value })}
            onBlur={(e) => setCaracteristica({ ...caracteristica, quartosqtdmax: e.target.value })}
            sx={{ marginBottom: '10px', width: '80px' }}
            inputProps={{ maxLength: 2, style: { textAlign: 'center' } }}
          />
          <TextFieldMask
            label="Área Menor"
            variant="standard"
            mask="decimal"
            value={caracteristica.minarea ? caracteristica.minarea : ""}
            onChange={(e) => setCaracteristica({ ...caracteristica, minarea: e.target.value })}
            onBlur={(e) => setCaracteristica({ ...caracteristica, minarea: e.target.value })}
            sx={{ marginBottom: '10px', width: '70px' }}
            inputProps={{ maxLength: 6, style: { textAlign: 'center' } }}
          />
          <TextFieldMask
            label="Área Maior"
            variant="standard"
            mask="decimal"
            value={caracteristica.maxarea ? caracteristica.maxarea : ""}
            onChange={(e) => setCaracteristica({ ...caracteristica, maxarea: e.target.value })}
            onBlur={(e) => setCaracteristica({ ...caracteristica, maxarea: e.target.value })}
            sx={{ marginBottom: '10px', width: '70px' }}
            inputProps={{ maxLength: 6, style: { textAlign: 'center' } }}
          />
          <TextFieldMask
            label="Pavimentos"
            variant="standard"
            mask="number"
            value={caracteristica.andares ? caracteristica.andares : ""}
            onChange={(e) => setCaracteristica({ ...caracteristica, andares: e.target.value })}
            onBlur={(e) => setCaracteristica({ ...caracteristica, andares: e.target.value })}
            sx={{ marginBottom: '10px', width: '70px' }}
            inputProps={{ maxLength: 2, style: { textAlign: 'center' } }}
          />
          <TextFieldMask
            label="Torres"
            variant="standard"
            mask="number"
            value={caracteristica.blocos ? caracteristica.blocos : ""}
            onChange={(e) => setCaracteristica({ ...caracteristica, blocos: e.target.value })}
            onBlur={(e) => setCaracteristica({ ...caracteristica, blocos: e.target.value })}
            sx={{ marginBottom: '10px', width: '40px' }}
            inputProps={{ maxLength: 2, style: { textAlign: 'center' } }}
          />
        </FormControl>
        <Grid container spacing={2} direction={'row'} >
          <Grid item xs={4} >
            <FormControlLabel
              sx={{ width: '100%' }}
              control={<Checkbox id='sacada' checked={caracteristica?.sacada ? caracteristica.sacada : false} onChange={(e) => setCaracteristica({ ...caracteristica, sacada: e.target.checked })} />}
              label="Sacada"
            />
            <FormControlLabel
              sx={{ width: '100%' }}
              control={<Checkbox id='elevador' checked={caracteristica?.elevador ? caracteristica.elevador : false} onChange={(e) => setCaracteristica({ ...caracteristica, elevador: e.target.checked })} />}
              label="Elevador"
            />
            <FormControlLabel
              sx={{ width: '100%' }}
              control={<Checkbox id='piscina' checked={caracteristica?.piscina ? caracteristica.piscina : false} onChange={(e) => setCaracteristica({ ...caracteristica, piscina: e.target.checked })} />}
              label="Piscina"
            />
            <FormControlLabel
              sx={{ width: '100%' }}
              control={<Checkbox id='salaofesta' checked={caracteristica?.salafesta ? caracteristica.salafesta : false} onChange={(e) => setCaracteristica({ ...caracteristica, salafesta: e.target.checked })} />}
              label="Salão de festas"
            />
            <FormControlLabel
              sx={{ width: '100%' }}
              control={<Checkbox id='churrasqueira' checked={caracteristica?.churrasqueira ? caracteristica.churrasqueira : false} onChange={(e) => setCaracteristica({ ...caracteristica, churrasqueira: e.target.checked })} />}
              label="Churrasqueira"
            />
            <FormControlLabel
              sx={{ width: '100%' }}
              control={<Checkbox id='quiosquechur' checked={caracteristica?.quiosquechur ? caracteristica.quiosquechur : false} onChange={(e) => setCaracteristica({ ...caracteristica, quiosquechur: e.target.checked })} />}
              label="Quiosque com churrasqueira"
            />
            <FormControlLabel
              sx={{ width: '100%' }}
              control={<Checkbox id='playgroud' checked={caracteristica?.playgroud ? caracteristica.playgroud : false} onChange={(e) => setCaracteristica({ ...caracteristica, playgroud: e.target.checked })} />}
              label="Playground"
            />
            <FormControlLabel
              sx={{ width: '100%' }}
              control={<Checkbox id='espacokids' checked={caracteristica?.espacokids ? caracteristica.espacokids : false} onChange={(e) => setCaracteristica({ ...caracteristica, espacokids: e.target.checked })} />}
              label="Espaço kids"
            />
            <FormControlLabel
              sx={{ width: '100%' }}
              control={<Checkbox id='salajogos' checked={caracteristica?.salajogos ? caracteristica.salajogos : false} onChange={(e) => setCaracteristica({ ...caracteristica, salajogos: e.target.checked })} />}
              label="Salão de jogos"
            />
          </Grid>
          <Grid item xs={4} >
            <FormControlLabel
              sx={{ width: '100%' }}
              control={<Checkbox id='espacogourmet' checked={caracteristica?.gourmet ? caracteristica.gourmet : false} onChange={(e) => setCaracteristica({ ...caracteristica, gourmet: e.target.checked })} />}
              label="Espaço gourmet"
            />
            <FormControlLabel
              sx={{ width: '100%' }}
              control={<Checkbox id='vagafixa' checked={caracteristica?.vagafixa ? caracteristica.vagafixa : false} onChange={(e) => setCaracteristica({ ...caracteristica, vagafixa: e.target.checked })} />}
              label="Vaga fixa"
            />
            <FormControlLabel
              sx={{ width: '100%' }}
              control={<Checkbox id='vagarotativa' checked={caracteristica?.vagarotativa ? caracteristica.vagarotativa : false} onChange={(e) => setCaracteristica({ ...caracteristica, vagarotativa: e.target.checked })} />}
              label="Vaga rotativa"
            />
            <FormControlLabel
              sx={{ width: '100%' }}
              control={<Checkbox id='garedem' checked={caracteristica?.gardem ? caracteristica.gardem : false} onChange={(e) => setCaracteristica({ ...caracteristica, gardem: e.target.checked })} />}
              label="Gardem"
            />
            <FormControlLabel
              sx={{ width: '100%' }}
              control={<Checkbox id='academia' checked={caracteristica?.academia ? caracteristica.academia : false} onChange={(e) => setCaracteristica({ ...caracteristica, academia: e.target.checked })} />}
              label="Academia"
            />
            <FormControlLabel
              sx={{ width: '100%' }}
              control={<Checkbox id='qdesportiva' checked={caracteristica?.qdesportiva ? caracteristica.qdesportiva : false} onChange={(e) => setCaracteristica({ ...caracteristica, qdesportiva: e.target.checked })} />}
              label="Quadra esportiva"
            />
            <FormControlLabel
              sx={{ width: '100%' }}
              control={<Checkbox id='qdtenis' checked={caracteristica?.qdtenis ? caracteristica.qdtenis : false} onChange={(e) => setCaracteristica({ ...caracteristica, qdtenis: e.target.checked })} />}
              label="Quadra de tênis"
            />
            <FormControlLabel
              sx={{ width: '100%' }}
              control={<Checkbox id='qdvolei' checked={caracteristica?.qdvolei ? caracteristica.qdvolei : false} onChange={(e) => setCaracteristica({ ...caracteristica, qdvolei: e.target.checked })} />}
              label="Quadra de vôlei"
            />
            <FormControlLabel
              sx={{ width: '100%' }}
              control={<Checkbox id='esportebar' checked={caracteristica?.esportebar ? caracteristica.esportebar : false} onChange={(e) => setCaracteristica({ ...caracteristica, esportebar: e.target.checked })} />}
              label="Esporte bar"
            />

          </Grid>

          <Grid item xs={4}>
            <FormControlLabel
              sx={{ width: '100%' }}
              control={<Checkbox id='petcare' checked={caracteristica?.petcare ? caracteristica.petcare : false} onChange={(e) => setCaracteristica({ ...caracteristica, petcare: e.target.checked })} />}
              label="Pet care"
            />
            <FormControlLabel
              sx={{ width: '100%' }}
              control={<Checkbox id='petplace' checked={caracteristica?.petplace ? caracteristica.petplace : false} onChange={(e) => setCaracteristica({ ...caracteristica, petplace: e.target.checked })} />}
              label="Pet place"
            />
            <FormControlLabel
              sx={{ width: '100%' }}
              control={<Checkbox id='salamult' checked={caracteristica?.salamult ? caracteristica.salamult : false} onChange={(e) => setCaracteristica({ ...caracteristica, salamult: e.target.checked })} />}
              label="Sala multiuso"
            />

            <FormControlLabel
              sx={{ width: '100%' }}
              control={<Checkbox id='coworking' checked={caracteristica?.coworking ? caracteristica.coworking : false} onChange={(e) => setCaracteristica({ ...caracteristica, coworking: e.target.checked })} />}
              label="Coworking"
            />
            <FormControlLabel
              sx={{ width: '100%' }}
              control={<Checkbox id='marketplace' checked={caracteristica?.marketplace ? caracteristica.marketplace : false} onChange={(e) => setCaracteristica({ ...caracteristica, marketplace: e.target.checked })} />}
              label="Marketplace"
            />
            <FormControlLabel
              sx={{ width: '100%' }}
              control={<Checkbox id='lavanderia' checked={caracteristica?.lavanderia ? caracteristica.lavanderia : false} onChange={(e) => setCaracteristica({ ...caracteristica, lavanderia: e.target.checked })} />}
              label="Lavanderia"
            />
            <FormControlLabel
              sx={{ width: '100%' }}
              control={<Checkbox id='sauna' checked={caracteristica?.sauna ? caracteristica.sauna : false} onChange={(e) => setCaracteristica({ ...caracteristica, sauna: e.target.checked })} />}
              label="Sauna"
            />
            <FormControlLabel
              sx={{ width: '100%' }}
              control={<Checkbox id='rooftop' checked={caracteristica?.rooftop ? caracteristica.rooftop : false} onChange={(e) => setCaracteristica({ ...caracteristica, rooftop: e.target.checked })} />}
              label="Rooftop"
            />
            <FormControlLabel
              sx={{ width: '100%' }}
              control={<Checkbox id='cinema' checked={caracteristica?.cinema ? caracteristica.cinema : false} onChange={(e) => setCaracteristica({ ...caracteristica, cinema: e.target.checked })} />}
              label="Cinema"
            />
          </Grid>
        </Grid>
      </Box>
    </Box>

  )
}