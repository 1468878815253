import { useEffect, useState } from 'react'
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { TextFieldMask } from '../../../utils/TextfieldMask/TextfieldMask';
import { Meses } from '../../../utils/dados/DadosMeses';
import { Anos } from '../../../utils/dados/DadosAnos';




export const PolVendas = ({
  setPlaceEdit,
  placeEdit,
  setNewPlace,
  newPlace }) => {

  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em PolVendas', placeEdit)

  const [politicaVendas, setPoliticaVendas] = useState({ ...placeEdit })

  useEffect(() => {
    setPlaceEdit(politicaVendas)
  }, [politicaVendas])

  return (
    <FormControl sx={{ width: '100%' }}>
      <Grid container direction={'row'} gap={1}>
        <TextFieldMask
          variant='standard'
          autoFocus
          id="renda"
          label="Renda"
          value={politicaVendas?.renda ? politicaVendas.renda : ''}
          onChange={(e) => setPoliticaVendas({ ...politicaVendas, renda: e.target.value })}
          onBlur={(e) => setPoliticaVendas({ ...politicaVendas, renda: e.target.value })}
          mask={'currency'} moeda={'R$'}
          sx={{ maxWidth: 100 }}
          inputProps={{ maxLength: 12 }}
        />

        <TextFieldMask
          variant='standard'
          id="vlrinicial"
          label="Imóv. Menor Valor"
          value={politicaVendas?.vlrinicial ? politicaVendas.vlrinicial : ''}
          onChange={(e) => setPoliticaVendas({ ...politicaVendas, vlrinicial: e.target.value })}
          onBlur={(e) => setPoliticaVendas({ ...politicaVendas, vlrinicial: e.target.value })}
          mask={'currency'} moeda={'R$'}
          sx={{ maxWidth: 120 }}
          inputProps={{ maxLength: 15 }}
        />
        <TextFieldMask
          variant='standard'
          id="vlrfinal"

          label="Imóv. Maior Valor"
          value={politicaVendas?.vlrfinal ? politicaVendas.vlrfinal : ''}
          onChange={(e) => setPoliticaVendas({ ...politicaVendas, vlrfinal: e.target.value })}
          onBlur={(e) => setPoliticaVendas({ ...politicaVendas, vlrfinal: e.target.value })}
          mask={'currency'} moeda={'R$'}
          sx={{ maxWidth: 125 }}
          inputProps={{ maxLength: 16 }}
        />
        <FormControl
          sx={{ minWidth: 85, marginTop: '2.5px' }}
        >
          <InputLabel
            id='lb_refvlrano'
            sx={{ transform: 'translate(0, -3px) scale(0.75)' }} // Ajustar a posição do label
          >Mês Ref</InputLabel>
          <Select
            variant='standard'
            labelId='lb_refvlrano'
            label='Mês ref'
            name='refvlrmes'
            id="refvlrmes"
            value={politicaVendas?.refvlrmes ? politicaVendas.refvlrmes : ''}
            onChange={(e) => setPoliticaVendas({ ...politicaVendas, refvlrmes: e.target.value })}
            sx={{ height: '30px', paddingTop: '10px' }} // Diminuir a altura do componente Select e ajustar o padding
          >
            <MenuItem value=''><em>None</em></MenuItem>
            {Meses.map((m) =>
              <MenuItem key={m.mes} value={m.mes}> {m.sigla}</MenuItem>
            )}
          </Select>
        </FormControl>
        <TextFieldMask
          variant='standard'
          id="refvlrano"
          name="refvlrano"
          label="Ano ref"
          value={politicaVendas?.refvlrano ? politicaVendas.refvlrano : ''}
          onChange={(e) => setPoliticaVendas({ ...politicaVendas, refvlrano: e.target.value })}
          onBlur={(e) => setPoliticaVendas({ ...politicaVendas, refvlrano: e.target.value })}
          mask={'number'}
          sx={{ maxWidth: 65 }}
          inputProps={{ maxLength: 4 }}
        />
      </Grid>

      <Grid container mt={3}>
        <TextField
          variant='outlined'
          fullWidth
          id="promocao"
          name='promocao'
          label="Bonus e Promoções"
          multiline
          rows={10}
          value={politicaVendas?.promocao ? politicaVendas.promocao : ''}
          onChange={(e) => setPoliticaVendas({ ...politicaVendas, promocao: e.target.value })}
          helperText="Bonificações e promoções para o corretor e ou cliente "
          inputProps={{ maxLength: 1000 }}
        />
      </Grid>
    </FormControl>
  )
}