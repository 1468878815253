import { CircularProgress, Grid, Typography, Box, useTheme } from '@mui/material';
import { useState, useEffect, createRef } from 'react';
import { Detail } from '../Details/Detail';
import { plans } from '../../constants/plans';
import { rules } from '../../constants/rules';


const List = ({ user, places, childClicked, isLoading, setChildClicked, setCoordinates, setActiveMarker, setAddMap, posAtual, showMySnackbar }) => {

  const [elRefs, setElRefs] = useState([]);

  useEffect(() => {
    setElRefs((refs) => Array(places?.length).fill().map((_, i) => refs[i] || createRef()));
  }, [places]);

  function findRef(i) {
    return Number(childClicked) === i
  }

  const onClick = (pos, i) => {
    setChildClicked(-1);
    setCoordinates(pos);
    setActiveMarker(i);
    setAddMap(false)
  }

  return (
    isLoading ?
      (
        <Box sx={{ height: '95vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress size='5rm' />
        </Box>
      ) : (
        <>
          <Grid container>
            {user.plano === plans.planUser.title.toLocaleUpperCase() && user.rules[0] === rules.ruleAdm.title ? (
              <Grid
                container
                alignContent={'end'}
                justifyContent={'center'}
                  sx={{
                  backgroundPosition: 'bottom',
                  backgroundImage: 'url(./assets/images/gimov_hand.png)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '100% 100%',
                  opacity: .5,
                  color: 'yellow',
                  p: 1
                }}
              >
                <Typography variant='h5' mr={'5px'}>{'Usuário com plano USER ativo,'}</Typography>
                <Typography variant='h5'>{'aguardando convite.'}</Typography>
              </Grid>
              // <Grid container
              //   mx={'5px'}
              //   height='100px'
              //   width='100%'
              //   justifyContent={'center'}
              //   alignContent={'center'}
              //   sx={{ backgroundColor: 'ButtonFace' }}  >
              //   <Typography variant='h5'>Usuário com plano USER ativo,</Typography>
              //   <Typography variant='h5'>aguardando convite...</Typography>
              // </Grid>
            ) :
              places?.length > 0 ?
                places.map((place, i) => (
                  <Grid ref={elRefs[i]} item key={i} xs={12}>
                    <div id={elRefs[i]} onClick={() => onClick(place.position, i)}>
                      <Detail
                        user={user}
                        place={place}
                        selected={findRef(i)}
                        refProp={elRefs[i]}
                        posAtual={posAtual}
                        showMySnackbar={showMySnackbar}

                      />
                    </div>
                  </Grid>
                )) :
                <Grid
                  container
                  height={'100%'}
                  width={'100%'}
                  alignContent={'center'}
                  justifyContent={'center'}
                  p={2}
                  sx={{ backgroundColor: 'primary.main' }}
                >
                  <Grid
                    container
                    alignContent={'end'}
                    justifyContent={'center'}
                    height={'100%'}
                    width={'100%'}
                    sx={{
                      backgroundPosition: 'bottom',
                      // backgroundImage: 'url(./logo.svg)',
                      backgroundImage: 'url(./assets/images/gimov_hand.png)',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: '100% 100%',
                      opacity: .5,
                      color: 'yellow',
                      p: 1
                    }}
                  >
                    <Typography variant='body1' marginBottom={1}>Não há imóveis cadastrados nesta região</Typography>
                  </Grid>
                </Grid>
            }
          </Grid>
        </>
      )
  )
}

export default List;