
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react';
import ArrowCircleRightTwoToneIcon from '@mui/icons-material/ArrowCircleRightTwoTone';
import ArrowCircleLeftTwoToneIcon from '@mui/icons-material/ArrowCircleLeftTwoTone';
import CreateTwoToneIcon from '@mui/icons-material/CreateTwoTone';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import CropFreeSharpIcon from '@mui/icons-material/CropFreeSharp';
import BoyTwoToneIcon from '@mui/icons-material/BoyTwoTone';
import CropLandscapeTwoToneIcon from '@mui/icons-material/CropLandscapeTwoTone';
import GpsFixedTwoToneIcon from '@mui/icons-material/GpsFixedTwoTone';
import GroupAddTwoToneIcon from '@mui/icons-material/GroupAddTwoTone';



export const Welcome = (isExample) => {



  const theme = useTheme();
  const [isMobile] = useState(useMediaQuery(theme.breakpoints.down('md')));


  const [page, setPage] = useState(1)

  return (
    <Grid
      width='25vw'
      // height='100%'
      border={1}

      sx={{

        overflow: 'scroll',
        scrollBehavior: 'auto',
        backgroundColor: 'background.paper',
        [theme.breakpoints.down('sm')]: {
          height: '50vh',
          width: '100vw',
        },
        [theme.breakpoints.between('sm', 'lg')]: {
          height: '92vh',
          width: '30vw',
        },
        [theme.breakpoints.up('lg')]: {
          height: '92vh',
          width: '25vw',
        }
      }}
    >
      <Box name='Area de Bem Vindo' display={page === 1 ? 'block' : 'none'}>
        <Typography variant='h4' align='center' sx={{ mt: '20px' }}>
          Seja Bem Vindo!
        </Typography>
        <Box sx={{ m: '10px', mt: '20px' }}>
          <Typography variant='subtitle1' sx={{ m: '10px', mt: '30px' }}>
            Descubra o que o GIMOV oferece para você ter em suas mãos todas as informações necessárias para aumentar as suas vendas.
          </Typography>
          <Typography variant='subtitle1' sx={{ m: '10px', mt: '30px' }}>
            Tenha acesso a todas informações disponibilizadas pelas construtoras de forma fácil e em qualquer lugar, mantendo seu banco de dados de imóveis.
          </Typography>
          <Typography variant='subtitle1' sx={{ m: '10px', mt: '30px' }}>
            Use o poder da tecnologia a seu favor, produza mensagens de relacionamento com o uso da Inteligência Artificial; Acesse os dados dos imóveis de forma georreferenciada; Use rotas do GPS para ir ao local; Conheça o local pelo Google Street View;   Acesse o material disponibilizado na nuvem de forma direta e muito mais...
          </Typography>
          <Typography variant='h6' sx={{ m: '10px', mt: '40px' }}>
            Tenha a informação certa, no lugar certo e no momento oportuno.
          </Typography>
          <Typography variant='subtitle1' sx={{ m: '10px', mt: '40px' }}>
            Ofereça ao seu cliente, de forma rápida, opções de imóveis, mesmo sem conhecer o local. Zona Norte, zona Sul, zona Oeste...
          </Typography>
          <Typography variant='subtitle1' sx={{ m: '10px', mt: '30px' }}>
            Faça um tour pela região através do Google Street View e conheça-o antes de ir lá.
          </Typography>
          <Typography variant='subtitle1' sx={{ m: '10px', mt: '30px' }}>
            Lembre da Política de Venda, dos descontos, dos vouchers, dos benefícios de cada empreendimento, tudo na palma de sua mão.
          </Typography>
          <Typography variant='subtitle1' sx={{ m: '10px', mt: '30px' }}>
            Enfim, imagine a satisfação de realizar um excelente atendimento, com todas as informações que o seu cliente precisa. Cliente Satisfeito é Corretor Feliz!
          </Typography>
          {isMobile && (<>
            <Typography variant='subtitle1' sx={{ m: '10px', mt: '30px' }} color='red'>
              Acesse todas funcionalidades do GIMOV em telas maiores como em computadores e tablets...
            </Typography>
            <Typography variant='subtitle1' sx={{ m: '10px', mt: '30px' }} color='red'>
              Em telefones móveis, celulares, o GIMOV atualmente só opera em modo de CONSULTA.
            </Typography>
          </>
          )}

          <Typography variant='h5' sx={{ m: '10px', mt: '30px' }} align='center'>
            Corretor Feliz é Cliente Satisfeito!
          </Typography>
          {!isMobile && (
            <Grid container width='100%' justifyContent='flex-end' marginTop={3}>
              <Button onClick={() => { setPage(2) }} >
                Vamos Começar
                <ArrowCircleRightTwoToneIcon />
              </Button>
            </Grid>
          )}
        </Box>
      </Box>


      <Box name='Tutorial Conhecendo' display={page === 2 ? 'block' : 'none'}>
        <Typography variant='h4' align='center' sx={{ mt: '20px' }}>
          Mãos à obra!
        </Typography>
        <Box sx={{ m: '10px', mt: '20px' }} alignContent='center' >
          <Typography variant='h5' sx={{ m: '10px', mt: '30px' }}>
            Conhecendo o GIMOV
          </Typography>
          <Typography variant='subtitle1' sx={{ m: '10px', mt: '30px' }}>
            O GIMOV foi criado para ser uma ferramenta de fácil utilização, contendo o que você precisa para fechar negócio.
          </Typography>
          <Typography variant='subtitle1' sx={{ m: '10px', mt: '5px' }}>
            A tela está dividida em 03 áreas, sendo elas: Menu Superior, Mapa e Área de Listagem.
          </Typography>
          <Typography variant='subtitle1' sx={{ m: '10px', mt: '5px' }}>
            Algumas opções do menu somente são visiveis em telas grandes (Computadores e Tables).
          </Typography>

          <Typography variant='h5' sx={{ m: '10px', mt: '30px' }}>
            Opções do Menu Superior
          </Typography>
          <Typography variant='subtitle2' sx={{ m: '10px', mt: '30px' }}>
            <ExitToAppTwoToneIcon fontSize='large' />
            Botão para sair do aplicativo
          </Typography>
          <Typography variant='subtitle2' sx={{ m: '10px', mt: '10px' }}>
            <GroupAddTwoToneIcon fontSize='large' />
            Botão para administrar equipes e gerar convites
          </Typography>
          <Typography variant='subtitle2' sx={{ m: '10px', mt: '10px' }}>
            <CreateTwoToneIcon fontSize='large' />
            Botão para ADICIONAR e EDITAR os imóveis
          </Typography>
          <Typography variant='subtitle2' sx={{ m: '10px', mt: '10px' }}>
            <GpsFixedTwoToneIcon fontSize='large' />
            Botão para posicionar o mapa na região atual
          </Typography>

          <Typography variant='h5' sx={{ m: '10px', mt: '30px' }}>
            Opções do Mapa
          </Typography>
          <Typography variant='subtitle2' sx={{ m: '10px', mt: '30px' }}>
            <CropFreeSharpIcon fontSize='large' />
            Botão para Maximizar o Mapa, localizado no canto superior direito do mapa.
          </Typography>
          <Typography variant='subtitle2' sx={{ m: '10px', mt: '10px' }}>
            <BoyTwoToneIcon fontSize='large' />
            Botão para ativar o Google Street View, localizado no centro direito do mapa.
          </Typography>
          <Typography variant='subtitle2' sx={{ m: '10px', mt: '10px' }}>
            <CropLandscapeTwoToneIcon fontSize='large' />
            Pesquisar endereços/logradouros, localizado no canto superior esquerdo do mapa.
          </Typography>

          <Typography variant='h5' sx={{ m: '10px', mt: '30px' }}>
            Área de Listagem
          </Typography>
          <Typography variant='subtitle2' sx={{ m: '10px', mt: '30px' }}>
            É a área onde é mostrado os detalhes dos imóveis que estão pontuados no mapa, selecione um imóvel da lista e ele será centralizado no mapa, selecione um imóvel do mapa e ele será posicionado na lista. Onde? Onde este tutorial está sendo lido.
          </Typography>
          <Grid container width='100%' justifyContent='space-between' marginTop={3}>
            <Button onClick={() => { setPage(1) }} >
              <ArrowCircleLeftTwoToneIcon />
              Voltar
            </Button>
            <Button onClick={() => { setPage(3) }} >
              Continuar
              <ArrowCircleRightTwoToneIcon />
            </Button>

          </Grid>
        </Box>
      </Box>
      <Box name='Tutorial Cadastro' display={page === 3 ? 'block' : 'none'}>
        <Typography variant='h4' align='center' sx={{ mt: '20px' }}>
          Agora é só cadastrar!
        </Typography>
        <Box sx={{ m: '10px', mt: '20px' }} alignContent='center' >
          <Typography variant='h5' sx={{ m: '10px', mt: '30px' }}>
            Como Cadastrar um Imóvel
          </Typography>
          <Typography variant='subtitle1' sx={{ m: '10px', mt: '30px' }}>
            1) Pesquise um endereço no mapa ou arraste o cursor até o local desejado;
          </Typography>
          <Typography variant='subtitle1' sx={{ m: '10px', mt: '30px' }}>
            2) Selecione o botão de EDITAR no menu superior;
          </Typography>
          <Typography variant='subtitle1' sx={{ m: '10px', mt: '30px' }}>
            3) Com o cursor no modo edição, click no mapa no local desejado;
          </Typography>
          <Typography variant='subtitle1' sx={{ m: '10px', mt: '30px' }}>
            4) Preencha os campos do formulário de cadastramento, organizado por IDENTIFICAÇÃO, POLÍTICA DE VENDAS, CARACTERISTICA e NUVEM;
          </Typography>
          <Typography variant='h5' sx={{ m: '10px', mt: '30px' }}>
            Como Editar ou Excluir um Imóvel Cadastrado
          </Typography>
          <Typography variant='subtitle1' sx={{ m: '10px', mt: '30px' }}>
            1) Localize o imóvel a ser editado
          </Typography>
          <Typography variant='subtitle1' sx={{ m: '10px', mt: '30px' }}>
            2) Selecione o botão de EDITAR no menu superior;
          </Typography>
          <Typography variant='subtitle1' sx={{ m: '10px', mt: '30px' }}>
            3) Com o cursor no modo de edição, click sobre o ícone do imóvel desejado e acesse o formulário de cadastramento;
          </Typography>
          <Typography variant='subtitle1' sx={{ m: '10px', mt: '30px' }}>
            4) No formulário de cadastro realize as alteraçõe e pressione o botão GRAVAR ou EXCLUIR.
          </Typography>

          <Grid container width='100%' justifyContent='space-between' marginTop={3}>
            <Button onClick={() => { setPage(2) }} >
              <ArrowCircleLeftTwoToneIcon />
              Voltar
            </Button>
            <Button onClick={() => { setPage(4) }} >
              Dados da Nuvem
              <ArrowCircleRightTwoToneIcon />
            </Button>
          </Grid>
        </Box>
      </Box>
      <Box name='Tutorial Nuvem' display={page === 4 ? 'block' : 'none'}>
        <Typography variant='h4' align='center' sx={{ mt: '20px' }}>
          Dados da Nuvem!
        </Typography>
        <Box sx={{ m: '10px', mt: '20px' }} alignContent='center' >
          <Typography variant='h5' sx={{ m: '10px', mt: '30px' }}>
            Como Localizar e Cadastrar os Links da Nuvem
          </Typography>
          <Typography variant='subtitle1' sx={{ m: '10px', mt: '30px' }}>
            1) Tendo recebido um link de acesso a uma pasta na nuvem do empreendimento de interesse:
          </Typography>
          <Typography variant='subtitle2' sx={{ m: '10px', ml: '30px', mt: '30px' }}>
            a) Abra a pasta no seu navegador, acesse a pasta interna desejada e copie o link de acesso mostrado no navegador.
          </Typography>
          <Typography variant='subtitle2' sx={{ m: '10px', ml: '30px', mt: '30px' }}>
            b) Cole o link copiado em um dos campos do formulário de cadastramento:
          </Typography>
          <Typography variant='subtitle2' sx={{ m: '10px', ml: '50px', mt: '10px' }}>
            1) <b>Drive</b>: Link para acesso direto a PASTA PRINCIPAL da nuvem;
          </Typography>
          <Typography variant='subtitle2' sx={{ m: '10px', ml: '50px', mt: '10px' }}>
            2) <b>Tabela de Vendas</b>: Link para acesso direto a pasta TABELA DE VENDAS da nuvem;
          </Typography>
          <Typography variant='subtitle2' sx={{ m: '10px', ml: '50px', mt: '10px' }}>
            3) <b>Plantas</b>: Link para acesso direto a pasta PLANTAS da nuvem;
          </Typography>
          <Typography variant='subtitle2' sx={{ m: '10px', ml: '50px', mt: '10px' }}>
            4) <b>Memorial Descritivo</b>: Link para acesso direto a pasta MEMORIA DESCRITIVO da nuvem;
          </Typography>
          <Typography variant='subtitle2' sx={{ m: '10px', ml: '50px', mt: '10px' }}>
            5) <b>Implantação</b>: Link para acesso direto a pasta IMPLANTAÇÃO da nuvem ;
          </Typography>
          <Typography variant='subtitle2' sx={{ m: '10px', ml: '50px', mt: '10px' }}>
            6) <b>Divulgação</b>: Link para acesso direto a pasta DIVULGAÇÃO da nuvem.
          </Typography>
          <Typography variant='subtitle1' sx={{ m: '10px', mt: '10px' }}>
            Incentive o fornecedor (Construtoras/Incorporadoras) a organizar suas pastas da nuvem conforme segue:
          </Typography>
          <Grid container width='100%' justifyContent='center'>
            <img src='./assets/images/pasta_220.png' alt='patas' />
          </Grid>
          <Grid container width='100%' justifyContent='space-between' marginTop={3}>
            <Button onClick={() => { setPage(3) }} >
              <ArrowCircleLeftTwoToneIcon />
              Voltar
            </Button>
          </Grid>
        </Box>
      </Box>

    </Grid>
  )
}