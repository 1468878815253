import { Box, Button, CircularProgress, Container, Divider, Grid, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import { distributeLeads, getLeads } from '../../../controllers/crm/leadController';
import { useEffect, useState } from 'react';
import { green, grey, red, yellow } from '@mui/material/colors';
import { escalaTipo, leadStatus } from '../../../constants/crm';
import { listCorretoresDistribuicao } from '../../../controllers/crm/escalaController';
import ReplayTwoToneIcon from '@mui/icons-material/ReplayTwoTone';
import dayjs from 'dayjs';


export default function LeadsDistribuicao(props) {

  // Variáveis de Recebidas
  const idconta = props.account.conta
  const idusuario = props.account.id
  const setUpdate = props.setUpdate


  // Constantes do Menu
  const drawerWidth = 120;
  const navItems = ['Leads', 'Escalas', 'Operadores', 'Carteiras'];
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  // Funções Mobile
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        GIMOV - OnLine
      </Typography>
      <Divider />
      <List>
        <ListItem key={'imoveis'} disablePadding>
          <ListItemButton sx={{ textAlign: 'left' }}>
            <ListItemText primary={'Imóveis'} />
          </ListItemButton>
        </ListItem>

        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'left' }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  // Constantes de Estilo

  // const maxWidthMD = '20%'
  // const maxWidthSM = '50%'
  // const maxWidthXS = '100%'

  // const corAguardando = red[400]
  // const corContatoInicial = red[100]
  // const corInformativo = red[200]
  // const corAquisitivo = red[300]
  // const corFechamento = green[400]
  // const corFundo = grey[200]
  const corFundo2 = grey[400]

  // Constante de Estado

  const [leads, setLeads] = useState([])
  const [corretores, setCorretores] = useState([])
  const [dia, setDia] = useState(dayjs().format('DD-MM-YYYY'))
  const [disabled, setDisabled] = useState(false)
  const [msgDist, setMsgDist] = useState(null)


  // Funções de Layout

  async function handleReload() {
    await loadLeads()
    await loadCorretores()
  }

  async function loadLeads() {
    setDisabled(true)
    const query = {
      where: {
        idconta: idconta,
        status: leadStatus.Disponivel,
        isactive: true
      }
    }
    try {
      const api = await getLeads(query)
      setMsgDist(null)
      if (api.status === 200) {
        if (api.data.length > 0) {
          setUpdate(true)
          setLeads(api.data)
        }
        else {
          setLeads([])
        }
      }
      else {
        setLeads([])
      }
    } catch (e) {
      // console.log('Erro', e)
    }
    finally {
      setDisabled(false)
    }
  }

  async function loadCorretores() {
    setDisabled(true)
    const dados = {
      diamesano: dayjs().format('YYYY-MM-DD'),
      idconta: idconta
    }
    try {
      const api = await listCorretoresDistribuicao(dados)
      if (api.status === 200) {
        if (api.data.length > 0) {
          setCorretores(api.data)
          // console.log('Corretores', api.data)
        }
        else {
          setCorretores([])
        }
      }
      else {
        setCorretores([])
      }
      // console.log('api', api)
    } catch (e) {
      // console.log('Erro', e)
    }
    finally {
      setDisabled(false)
    }
  }

  async function distribuirLeads() {
    setMsgDist(null)
    const dados = {
      idconta: idconta,
      diamesano: dayjs().format('YYYY-MM-DD'),
      idusuario: idusuario,
      processo: escalaTipo.Manual
    }
    try {
      const result = await distributeLeads(dados).then((response) => {
        // console.log('response', response)
        if (response.status === 200) {
          setLeads([])
          // setCorretores([])
          // loadCorretores()
          // loadLeads()
          setUpdate(true)
          setMsgDist(response.data.length + ' Leads Distribuídos com Sucesso!')
        }
        else {
          setLeads([])
          setMsgDist(response.data)
        }
      })
      // console.log('Leads Distribuídos', result.data)

    } catch (e) {
      console.log('Erro', e)
    }
  }

  useEffect(() => {
    loadLeads()
    loadCorretores()
  }, [])

  return (
    <Container>


      <Grid container
        height={'85vh'}
        // sx={{ backgroundColor: 'grey' }}
        overflow={'auto'}
        direction="row"
        alignContent="flex-start"
        display={'flex'}
      >
        <Grid container  >
          <Grid item xs={12}>
            {(disabled) && ((
              <div style={{ position: 'fixed', top: '50vh', left: '50vw' }}>
                <CircularProgress />
              </div>
            ))}
            <Grid item
              mt={2}
              bgcolor={'white'}
              p={1} >
              <Typography variant="h3" textAlign={'center'} >
                Distribuição de Leads
              </Typography>
              <Typography variant="h4" textAlign={'center'} >
                Data: {dia}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container
          mt={1}
        >
          <Grid container
            direction={'row'}
            justifyContent={'space-between'}
            width={'100%'}
            bgcolor={'white'}
            p={1}
            mb={1}
          >
            <Typography variant="h4" component="h4" alignContent={'center'} >
              Corretores disponíveis ({corretores.length})
            </Typography>
            <Button
              variant="contained"
              disabled={disabled}
              onClick={handleReload}
            >
              <ReplayTwoToneIcon />
            </Button>
          </Grid>
          <Grid container
            direction={'row'}
            overflow={'auto'}
            justifyContent={'start'}
            maxHeight={'150px'}
            bgcolor={grey[100]}
            gap={1}
            p={1}
            mb={1}
          >
            {
              corretores.length > 0 ? corretores.map((corretor, i) => (
                <Box key={i}
                  minWidth={'120px'}
                  p={1}
                  bgcolor={green[200]}
                  borderRadius={0}
                >
                  <div style={{ display: 'flex' }}>
                    <Typography variant='body1' fontSize={'12px'} mr={1}>Ordem:</Typography>
                    <Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'}>{corretor.ordem}</Typography>
                  </div>
                  <Typography variant='body1' fontSize={'12px'}>Nome:</Typography>
                  <Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'}>{corretor.nome.length > 20 ? corretor.nome.substring(0, 15) + '...' : corretor.nome}</Typography>
                  <Typography variant='body1' fontSize={'12px'}>Telefone:</Typography>
                  <Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'}>{corretor.telefone}</Typography>
                  <Typography variant='body1' fontSize={'12px'}>Leads:{corretor.qtd}</Typography>
                </Box>
              ))
                :
                <Box mx={1} my={1} sx={{ p: '10px', backgroundColor: red[100], width: '100%', textAlign: 'center' }}  >
                  <Typography variant='h3' >Não há Corretores disponíveis para distribuição!</Typography>
                </Box>
            }
          </Grid>

          <Grid item
            xs={12}
          >
            <Grid container direction={'row'}
              justifyContent={{ xs: 'space-between', sm: 'space-between' }}
              width={'100%'}
              bgcolor={'white'}
            >
              <Grid item
                bgcolor={'white'}
                p={1}
                alignContent={'center'}
                textAlign={'start'}
              >
                <Typography variant="h4"  >
                  Leads para distribuição ({leads.length})
                </Typography>
              </Grid>
              <Button variant="contained" color="primary" size='large'
                onClick={distribuirLeads}
                disabled={leads.length === 0 || corretores.length === 0}
                sx={{ display: 'flex', borderRadius: '0', mt: '0px', mb: '0px', color: yellow[500] }}
              >
                Distribuir Leads
              </Button>
            </Grid>
            <Grid container
              direction={'row'}
              overflow={'auto'}
              justifyContent={{ xs: 'center', sm: 'flex-start' }}
              height={'100%'}
              alignContent={'flex-start'}

              bgcolor={corFundo2}
              gap={1}
              p={1}>
              {
                leads.map((lead, i) => (
                  <Box key={i}
                    bgcolor={'white'}
                    p={1}
                    borderRadius={0}
                    maxWidth={'250px'}
                  >
                    <Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'} >Nr:{i + 1}</Typography>
                    <Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'}>{new Date(lead.createdat).toLocaleDateString() + ' ' + new Date(lead.createdat).toLocaleTimeString()}</Typography>
                    <Typography variant='body1' fontSize={'12px'}>Nome:</Typography>
                    <Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'}>{lead.nome}</Typography>
                    {/* <Typography variant='body1' fontSize={'12px'}>Telefone:</Typography>
                    <Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'}>{lead.telefone}</Typography>
                    <Typography variant='body1' fontSize={'12px'}>Email:</Typography>
                    <Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'}>{lead.email}</Typography>
                    <Typography variant='body1' fontSize={'12px'}>Interesse:</Typography>
                    <Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'}>{lead.interesse}</Typography> */}
                    <Typography variant='body1' fontSize={'12px'}>Origem:</Typography>
                    <Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'}>{lead.origem}</Typography>
                  </Box>
                ))
              }
            </Grid>
            {msgDist && (
              <Box mx={0} my={1} sx={{ p: '10px', backgroundColor: 'white' }} >
                <Typography variant='h3'>{msgDist}</Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>

  );
}