import { green, grey, red, yellow } from '@mui/material/colors'
import { taskPriority, taskStatus } from '../../constants/crm'

import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import 'dayjs/locale/pt-br';
dayjs.locale('pt-br');
dayjs.extend(localeData)
dayjs().localeData();


export function getColorDay(_day) {
  const today = dayjs().format('dddd')
  if (_day === today) return green[200]
  else return grey[100]
}

export function getColorStatus(_status, index) {
  if (_status === taskStatus.Description[taskStatus.Concluida]) return green[300]
  if (_status === taskStatus.Description[taskStatus.Suspensa]) return red[200]
  if (index % 2 === 0) return grey[400]
  else return grey[200]
}

export function getColorPrioridade(_status) {
  if (_status === taskPriority.Description[taskPriority.Alta]) return red[200]
  if (_status === taskPriority.Description[taskPriority.Normal]) return green[200]
  if (_status === taskPriority.Description[taskPriority.Baixa]) return yellow[200]
}

export function getColorFaseByOrdem(_ordem) {
  if (_ordem >=9) _ordem = 9
  if (_ordem === 1) return grey[100]
  if (_ordem === 2) return yellow[100]
  if (_ordem === 3) return yellow[200]
  if (_ordem === 4) return red[200]
  if (_ordem === 5) return red[300]
  if (_ordem > 5) return red[0 + _ordem * 100]
} 