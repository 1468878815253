import { Grid, Typography } from '@mui/material';

export function Copyright(props) {


  return (
    <Grid container width={'100%'} justifyContent={'center'}>
      <Typography variant="body2" 
      color="text.secondary" 
      {...props}>
        {'Copyright © '}
        {' '}
        {process.env.REACT_APP_COPYRIGHT}
        {/* <Link color="inherit" href="https://mui.com/">
          Your Website
        </Link>{' '} */}
        {' - '}
        {new Date().getFullYear()}
        {'.'}
        {' - V.'}
        {process.env.REACT_APP_VERSION}
      </Typography>
    </Grid>
  );
}