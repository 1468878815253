import { useTheme } from '@emotion/react';
import { Button, Card, CardActions, CardContent, Grid, IconButton, MenuItem, Select, Typography } from '@mui/material'
import { useEffect, useState } from 'react';
import { taskStatus, taskType } from '../../../constants/crm';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { getLeadTasks } from '../../../controllers/crm/leadTaskController';
import LeadTaskDetails from './LeadTaskDetails';
import dayjs from 'dayjs';
import { green, red, yellow } from '@mui/material/colors';


export function LeadTaskSelect({ account, lead, setSnackbar, setLoading, setUpdate, update,
  setShowMenu, setShowMyLeads, setShowLeadDetails }) {

  const theme = useTheme();

  const [taskList, setTaskList] = useState([]);
  const [taskListFiltered, setTaskListFiltered] = useState([]);
  const [filtro, setFiltro] = useState('');
  const [typeStatus, setTypeStatus] = useState(taskStatus.Pendente);
  const [showTaskDetail, setShowTaskDetail] = useState(false);
  const [taskDetail, setTaskDetail] = useState({});


  function handleClose() {
    setShowLeadDetails(false)
  }


  function filterTasks() {
    if (!filtro) {
      setTaskListFiltered(taskList.filter(task => task.situacao === taskStatus.Description[typeStatus]))
    }
    else {
      setTaskListFiltered(taskList.filter(
        (task) => {
          if (task.tipo.includes(taskType.Description[filtro])
            && task.situacao === taskStatus.Description[typeStatus])
            return task
          else return null
        }
      ))
    }
  }

  async function loadTasks() {
    setLoading(true)
    // console.log('loadTasks',account)
    try {
      const query = {
        // take: 8,
        where: {
          idlead: lead.idlead,
          idoperador: account.id,
          isactive: true,
        },
        orderBy: [
          { dtacao: 'asc' }
        ]
      };
      const dados = await getLeadTasks(query)
      if (dados.status === 200) {
        setTaskList(dados.data)
        setFiltro(null)
      }
    } catch (error) {
      setSnackbar({ open: true, message: error.message, severity: 'error' })
    }
    finally {
      setLoading(false)
    }

  }

  function handleShowTask(task) {
    setShowTaskDetail(true)
    setTaskDetail(task)
  }

  useEffect(() => {
    loadTasks()
  }, [])

  useEffect(() => {
    filterTasks()
  }, [filtro, typeStatus])

  return (
    // todo: ajustar o layout para telas pequenas
    <Grid container
      width={{
        xs: '100%', // largura máxima em telas pequenas
        md: '500px' // largura de 500px em telas grandes
      }}
      height={'60vh'}
      direction={'row'}
      p={2}
      bgcolor={'white'}
      border={'1px solid #ccc'}
      justifyContent="space-between"
    >
      <Grid container>
        <Grid container
          bgcolor={theme.palette.grey[200]}
          mb={1}
        >
          <Grid item xs={8}
            justifyContent={'space-between'}
            alignContent={'center'}
            p={1}
          >
            <Typography component={'div'} variant="h3">ATIVIDADES - {filtro ? taskType.Description[filtro].toUpperCase() : 'TODAS'}</Typography>
          </Grid>
          <Grid item xs={4} >
            <Select variant='standard' size='small'
              value={typeStatus}
              onChange={(e) => setTypeStatus(e.target.value)}>
              <MenuItem value={taskStatus.Pendente}>Pendente</MenuItem>
              <MenuItem value={taskStatus.Concluida}>Concluida</MenuItem>
              <MenuItem value={taskStatus.Suspensa}>Suspensa</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid container height={'30px'}
          gap={1} justifyContent={'space-between'}>
          <Button sx={{ borderRadius: '0px' }} variant='contained' size='small' onClick={() => setFiltro(null)}>Todas</Button>
          <Button sx={{ borderRadius: '0px' }} variant='contained' size='small' onClick={() => setFiltro(taskType.Lembrete)}>Lembrete</Button>
          <Button sx={{ borderRadius: '0px' }} variant='contained' size='small' onClick={() => setFiltro(taskType.Tarefa)}>Tarefa</Button>
          <Button sx={{ borderRadius: '0px' }} variant='contained' size='small' onClick={() => setFiltro(taskType.Reuniao)}>Reunião</Button>
        </Grid>
        <Grid container
          mt={1}
          height={'40vh'}
          gap={.5}
          overflow={'auto'}
          bgcolor={theme.palette.grey[200]}
        >
          {taskListFiltered.map((_task, i) => {
            return (
              <Grid container
                border={0}
                key={i}
                direction={'row'}
                gap={0}
                bgcolor={i % 2 === 0 ? theme.palette.grey[400] : theme.palette.grey[300]}
                onClick={() => handleShowTask(_task)}
                alignContent={'flex-start'}
                sx={{
                  cursor: 'pointer',
                  "&:hover": {
                    bgcolor: yellow[300],
                  },
                }}
              >
                <Grid container
                  bgcolor={green[100]}
                >
                  <Grid item xs={10}
                    textAlign={'left'}
                  >
                    <Typography component={'div'} variant="body1"
                      p={0.5}>{_task ? (i + 1).toString().padStart(2, "0") + ' - ' + _task.tipo : ''}</Typography>
                  </Grid>
                  <Grid item
                    border={1}
                    xs={2}
                    bgcolor={_task ?
                      dayjs().isBefore(dayjs(_task.dtacao).add(1, 'day'), 'day') ? green[300]
                        : taskStatus[_task.situacao] === taskStatus.Concluida ? green[300]
                          : taskStatus[_task.situacao] === taskStatus.Suspensa ? yellow[200] : red[100]
                      : ''}
                  >
                    <Typography component={'div'} variant="body1" textAlign={'center'}
                      p={0.5}>{_task ? dayjs(_task.dtacao).format('DD/MM') : ''}</Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Typography component={'div'} variant="body1" p={0.5}>
                    {_task ? _task.titulo : ''}
                  </Typography>
                </Grid>
              </Grid>
            )
          })}
        </Grid>

        {showTaskDetail && (
          <LeadTaskDetails
            account={account}
            lead={lead}
            task={taskDetail}
            taskList={taskList}
            taskListFiltered={taskListFiltered}
            filtro={filtro}
            filterTasks={filterTasks}
            setShowTask={setShowTaskDetail}
            showTask={showTaskDetail}
            setSnackbar={setSnackbar}
            setUpdate={setUpdate}
            update={update}
          />
        )}
      </Grid>
      <Grid container justifyContent={'space-between'} mt={1}>
        <Grid item>
          <Button variant='contained' size='small' onClick={() => handleShowTask({})}>Adicionar</Button>
        </Grid>
        <Grid item>
          <Button variant='contained' size='small' onClick={handleClose}>Fechar</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
