import {
  Box, Typography, Button, Card, CardMedia,
  CardContent, CardActions, Grid, Divider, Link,
  DialogActions, DialogContentText, DialogContent, DialogTitle, Dialog, TextField
} from '@mui/material'
import { LocationOn, Phone } from '@mui/icons-material'
import ConstructionIcon from '@mui/icons-material/Construction';
import SavingsTwoToneIcon from '@mui/icons-material/SavingsTwoTone';
import AttachMoneyTwoToneIcon from '@mui/icons-material/AttachMoneyTwoTone';
import { blue, lime, pink, red, yellow } from '@mui/material/colors';
import StraightenIcon from '@mui/icons-material/Straighten';
import BedroomChildTwoToneIcon from '@mui/icons-material/BedroomChildTwoTone';
import DirectionsTwoToneIcon from '@mui/icons-material/DirectionsTwoTone';
import { useEffect, useRef, useState } from 'react';
import CloudTwoToneIcon from '@mui/icons-material/CloudTwoTone';
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import { AssistenteAI } from '../AssistenteAI/AssistenteAI';
import { descDormitorios } from '../../utils/description';

const classes = require('./Styles')


export const Detail = ({ user, place, selected, refProp, posAtual, showMySnackbar }) => {

  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em Details:', place)
  let corfundo = 'white'
  if (selected) {
    refProp?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    corfundo = lime[100]
  }

  const canOpenAI = true
  const [openAI, setOpenAI] = useState(false)

  let mes = 0
  let ano = 0
  // if (place.vlrrefmesano) {
  //   mes = new Date(place.vlrrefmesano).getMonth() + 1
  //   ano = new Date(place.vlrrefmesano).getFullYear()
  // }
  if (place.refvlrmes) mes = place.refvlrmes
  if (place.refvlrano) ano = place.refvlrano


  const [mostraDescricao, setMostraDescricao] = useState(false)
  const [mostraPolVendas, setMostraPolVendas] = useState(false)
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState('paper');

  const tpQuartos = ['Std', '1d', '2d', '3d', '4d', '5d', '6d+']
  let qtdQuartos = tpQuartos[Number(place.quartosqtdmin)] + ' - ' + tpQuartos[Number(place.quartosqtdmax)]
  let dormitorios = descDormitorios(place)

  let lnk = ''
  if (posAtual) lnk = 'https://www.google.com/maps/dir/?api=1&origin=' + posAtual.lat + ',' + posAtual.lng + '&destination=' + place.position.lat + ',' + place.position.lng
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('posAtual', posAtual)

  const descriptionElementRef = useRef(null);


  const handleOpenDescricao = () => {
    setMostraDescricao(true)
    setMostraPolVendas(false)
    setOpen(true)
  }

  const handleOpenPolVendas = () => {
    setMostraPolVendas(true)
    setMostraDescricao(false)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);


  return (
    <Grid >
      <Card elevation={6} style={{ margin: 3, backgroundColor: corfundo, cursor: 'pointer' }}>
        <CardMedia
          style={{ justifyContent: 'center', height: '220px', width: '100%' }}
          image={place.urlimagem ? place.urlimagem : 'assets/images/gimov.png'}
          title={place.empreendimento}
        />
        <CardContent>
          <Grid item >
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h6">{place.empreendimento}</Typography>
              {place.construtora && (
                <Typography variant="body2" color="textSecondary" sx={classes.spacing}>
                  <ConstructionIcon /> {place.construtora}
                </Typography>
              )}
            </Box>
            {place.renda && (
              <Box display="flex" justifyContent="flex-start">
                <SavingsTwoToneIcon sx={{ mr: '10px' }} />
                <Typography variant="body1" sx={{ mr: '10px' }} >Renda:</Typography>
                <Typography variant="body1" color={'red'} sx={classes.subtitle}>
                  {place.renda}
                </Typography>
              </Box>
            )}
            {place.vlrinicial && (
              <Box display="flex" justifyContent="space-between">
                <Box display={'flex'}>
                  <AttachMoneyTwoToneIcon sx={{ mr: '10px' }} />
                  <Typography variant="body1" sx={{ mr: '10px' }}>Menor:</Typography>
                  <Typography variant="body1" color={'red'}>
                    {place.vlrinicial}
                  </Typography>
                </Box>
                <Typography variant="body2">
                  {'Ref(' + mes + '/' + ano + ')'}
                </Typography>
              </Box>
            )}
            {place.vlrfinal && (
              <Box display="flex" justifyContent="space-between">
                <Box display={'flex'}>
                  <AttachMoneyTwoToneIcon sx={{ mr: '10px' }} />
                  <Typography variant="body1" sx={{ mr: '10px' }} >Maior:</Typography>
                  <Typography variant="body1" color={'red'}>{place.vlrfinal}</Typography>
                </Box>
              </Box>
            )}
            {/* {(place.quartosqtdmax > 0 && place.quartosqtdmin > 0) && ( */}
            {dormitorios && (
              <Box display="flex" justifyContent="space-between">
                <Box display={'flex'}>
                  <BedroomChildTwoToneIcon sx={{ mr: '10px' }} />
                  <Typography variant="body1" sx={{ mr: '10px' }}>Dormitórios:</Typography>
                  <Typography variant="body1" color={'red'}>{dormitorios}</Typography>
                </Box>
              </Box>
            )}
            {place.minarea && (
              <Box display="flex" justifyContent="flex-start">
                <StraightenIcon sx={{ mr: '10px' }} />
                <Typography variant="body1" sx={{ mr: '10px' }}>Metragem:</Typography>
                <Typography variant="body1" color={'red'} sx={classes.subtitle}>
                  {place.minarea + 'm2 - ' + place.maxarea + 'm2'}
                </Typography>
              </Box>
            )}
            {place.endereco && (
              <Typography variant="body2" color="textSecondary" sx={classes.subtitle}>
                <LocationOn sx={{ mr: '10px' }} />{place.endereco}
              </Typography>
            )}
            {place.telefone && (
              <Typography variant="body2" color="textSecondary" justifyContent="flex-end" sx={classes.subtitle}>
                <Phone sx={{ mr: '10px' }} /> {place.telefone}
              </Typography>
            )}
          </Grid>
        </CardContent>
        <Divider />
        <CardActions >
          <Box display="flex" width={'100%'}>
            {canOpenAI && (
              <Button fullWidth sx={{ backgroundColor: blue['A100'], mx: '3px' }}
                onClick={() => { setOpenAI(true) }}
              ><AutoAwesomeTwoToneIcon color={yellow[500]} sx={{ mr: '10px' }} />
                <Typography variant="body" fontSize={'10px'} fontWeight={500} color="textSecondary">
                  Assistente IA
                </Typography>
              </Button>
            )}
            {posAtual && (
              <Button fullWidth sx={{ backgroundColor: blue['A100'], mx: '3px', maxWidth: '110px' }}
                onClick={() => window.open(lnk, '_blank')}
              ><DirectionsTwoToneIcon sx={{ mr: '10px' }} />
                <Typography variant="body" fontSize={'10px'} fontWeight={500} color="textSecondary">
                  Rotas
                </Typography>
              </Button>
            )}
          </Box>
        </CardActions>
        <CardActions >
          <Box display="flex" width={'100%'}>
            {place.descricao && (
              <Button fullWidth sx={{ backgroundColor: blue[100], mx: '3px' }}
                onClick={handleOpenDescricao}
              >
                <Typography variant="body" fontSize={'10px'} fontWeight={500} color="textSecondary">
                  Descrição
                </Typography>
              </Button>
            )}
            {place.promocao && (
              <Button fullWidth sx={{ backgroundColor: blue[100], mx: '3px' }}
                onClick={handleOpenPolVendas}
              >
                <Typography variant="body" fontSize={'10px'} fontWeight={500} color="textSecondary">
                  Pol.Vendas
                </Typography>
              </Button>
            )}
          </Box>
        </CardActions>
        <Divider />
        <CardActions >
          <Box display="flex" width={'100%'}>
            {place.drive && (
              <Button fullWidth sx={{ backgroundColor: blue[100], mx: '3px' }}
                onClick={() => window.open(place.drive, '_blank')}
              >
                {/* <CloudTwoToneIcon fontSize='small' sx={{ mr: '5px' }} /> */}
                <Typography variant="body" fontSize={'10px'} fontWeight={500} color="textSecondary">
                  Nuvem
                </Typography>
              </Button>
            )}
            {place.tbvendas && (
              <Button fullWidth sx={{ backgroundColor: blue[100], mx: '3px' }}
                onClick={() => window.open(place.tbvendas, '_blank')}
              >
                {/* <CloudTwoToneIcon fontSize='small' sx={{ mr: '5px' }} /> */}
                <Typography variant="body" fontSize={'10px'} fontWeight={500} color="textSecondary">
                  Tb Vendas
                </Typography>
              </Button>
            )}
            {place.plantas && (
              <Button fullWidth sx={{ backgroundColor: blue[100], mx: '3px' }}
                onClick={() => window.open(place.plantas, '_blank')}
              >
                {/* <CloudTwoToneIcon fontSize='small' sx={{ mr: '5px' }} /> */}
                <Typography variant="body" fontSize={'10px'} fontWeight={500} color="textSecondary">
                  Plantas
                </Typography>
              </Button>
            )}
          </Box>
        </CardActions>
        <CardActions >
          <Box display="flex" width={'100%'}>
            {place.memorial && (
              <Button fullWidth sx={{ backgroundColor: blue[100], mx: '3px' }}
                onClick={() => window.open(place.memorial, '_blank')}
              >
                {/* <CloudTwoToneIcon fontSize='small' sx={{ mr: '5px' }} /> */}
                <Typography variant="body" fontSize={'10px'} fontWeight={500} color="textSecondary">
                  Memorial
                </Typography>
              </Button>
            )}
            {place.implantacao && (
              <Button fullWidth sx={{ backgroundColor: blue[100], mx: '3px' }}
                onClick={() => window.open(place.implantacao, '_blank')}
              >
                {/* <CloudTwoToneIcon fontSize='small' sx={{ mr: '5px' }} /> */}
                <Typography variant="body" fontSize={'10px'} fontWeight={500} color="textSecondary">
                  Implantação
                </Typography>
              </Button>
            )}
            {place.divulgacao && (
              <Button fullWidth sx={{ backgroundColor: blue[100], mx: '3px' }}
                onClick={() => window.open(place.divulgacao, '_blank')}
              >
                {/* <CloudTwoToneIcon fontSize='small' sx={{ mr: '5px' }} /> */}
                <Typography variant="body" fontSize={'10px'} fontWeight={500} color="textSecondary">
                  Divulgação
                </Typography>
              </Button>
            )}
          </Box>
        </CardActions>
      </Card>

      {/* Mostra Assistente AI */}
      {openAI && (<AssistenteAI
        user={user}
        place={place}
        openAI={openAI}
        setOpenAI={setOpenAI}
        showMySnackbar={showMySnackbar}
      />)}

      {/* Mostra Descrição do Empreendimento */}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="title"
        aria-describedby="dialog"
        fullWidth
      >
        <DialogTitle id="title">
          {mostraDescricao ? 'Descrição' : 'Política de Vendas'}
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}

        >
          <DialogContentText
            id="dialog"
            ref={descriptionElementRef}
            tabIndex={-1}
            height={'400px'}
          >
            <TextField
              fullWidth
              id="standard-multiline-static"
              multiline
              rows={22}
              defaultValue={mostraDescricao ? place.descricao : place.promocao}
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} >Fechar</Button>
        </DialogActions>
      </Dialog>
    </Grid >
  )
}
