import { useRef, useState } from 'react';
import { InfoWindowF, GoogleMap, useJsApiLoader, Autocomplete, MarkerF } from '@react-google-maps/api';
import { InputBase, useTheme, Grid, Typography, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Box, Divider, FormControlLabel, Checkbox } from '@mui/material';
import { green } from '@mui/material/colors';
import FilterAltTwoToneIcon from '@mui/icons-material/FilterAltTwoTone';
import { situacaoTipo } from '../../utils/tipos';
import { descDormitorios } from '../../utils/description';

const classes = require('./Styles')

const Map = ({
  setCoordinates,
  setBounds,
  coordinates,
  places,
  setChildClicked, setActiveMarker, setOpenModal, setNewPosition,
  activeMarker, isAddMap, setNewPlace, totImoveis, isFiltering, showMySnackbar }) => {

  const theme = useTheme()
  const mapRef = useRef(null)
  const { google } = window

  async function handleLoad(map) {
    mapRef.current = map;
  }

  function handleCenterChanged() {
    if (!mapRef.current) {
      return;
    }
    const bounds = mapRef.current.getBounds().toJSON();
    const center = mapRef.current.getCenter().toJSON();
    setCoordinates(center);
    setBounds(bounds);
    setChildClicked(-1);
    setPutArrow(false)

  }

  const [googleMapsLibraries] = useState(['places']);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_API_GOOGLE_MAP,
    libraries: googleMapsLibraries
  })

  // const isMobile = useMediaQuery('(min-width:600px)');


  const [autocomplete, setAutocomplete] = useState(null);

  const onLoad = (autoC) => setAutocomplete(autoC)
  const [putArrow, setPutArrow] = useState(false)
  const onPlaceChanged = (event, value) => {
    try {
      mapRef.current.setZoom(19)
      const lat = autocomplete.getPlace().geometry.location.lat();
      const lng = autocomplete.getPlace().geometry.location.lng();
      setCoordinates({ lat, lng });
      setPutArrow(true)
    } catch {
      setPutArrow(false)
    }
  }


  // Marker setup

  let urlCursor = 'url(assets/images/lapis40px.png),auto'

  const optionsMap = {
    //disableDefaultUI: true,
    draggableCursor: isAddMap ? urlCursor : 'default',
    draggingCursor: isAddMap ? urlCursor : 'default',
    minZoom: 9,
    mapTypeControl: false,
    scaleControl: true,
    streetViewControl: true,
    streetViewControlOptions: {
      position: 8
    },
    rotateControl: true,
    zoomControl: false,
    region: 'BR',
    language: 'pt-BR'
    // zoomControlOptions: { position: 8 },

    // Bug no GoogleMaps -> Desabilita a fullscreen depois de movimentar o mapa
    // fullscreenControl: true,
    // fullscreenControlOptions: {
    //   position: 8
    // },    
  }

  function pinSeta(color) {
    return {
      //path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
      path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
      //url: '../assets/images/casa_map.png',
      fillColor: color,
      fillOpacity: 1,
      strokeColor: '#000',
      strokeWeight: 1,
      scale: 3
    };
  }

  function pinSymbol(color) {
    return {
      //path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
      //path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
      //url: '../assets/images/casa_map.png',
      url: '../assets/images/predio_map.png',
      fillColor: color,
      fillOpacity: 1,
      strokeColor: '#000',
      strokeWeight: 1,
      scale: 2
    };
  }

  // const onLoadMarker = marker => {
  //   if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('marker: ', marker.position)
  // }

  const handleActiveMarker = (marker) => {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em handlerActiveMarker', marker)
    if (marker === activeMarker) {
      return;
    } else {
      setChildClicked(marker)
      if (!isAddMap) {
        setActiveMarker(marker)
      }
      else {
        // console.log('Entrou em handleActiveMarker',places[marker])
        setNewPosition(places[marker].position)
        setNewPlace(places[marker])
        setOpenModal(true)
      }
    }
  };
  const handleDeactiveMarker = (marker) => {
    setActiveMarker(null)
  }
  function handleOnClickMap(marker) {

    setActiveMarker(null)
    setPutArrow(false)

    if (isAddMap) {
      // console.log('Entrou em handleOnClickMap isADD', isAddMap, marker.latLng.toJSON())
      setOpenModal(true)
      setNewPosition(marker.latLng.toJSON())
    } else {
      // console.log('Entrou em handleOnClickMap', marker.latLng.toJSON())
      setOpenModal(false)
      setNewPosition(null)
    }
  }
  const [openExtrato, setOpenExtrato] = useState(false)
  const [putDivulgacao, setPutDivulgacao] = useState(true)

  const [dormitorios, setDormitorios] = useState('')

  function mostrarExtrato() {
    setDormitorios(descDormitorios(places[activeMarker]))
    setOpenExtrato(true)
  }


  async function copyDivToClipboard() {
    var range = document.createRange();
    range.selectNode(document.getElementById("dialog"))
    // window.getSelection().removeAllRanges(); // clear current selection.
    // window.getSelection().addRange(range); // to select text.
    // document.execCommand("copy");
    await navigator.clipboard.writeText(range.toString().split('/ln').join('\n')).then(() => {
      showMySnackbar('Copiado para área de transferência...', 2000, 'success')
    }).catch((e) => {
      showMySnackbar('Erro na operação', 2000, 'error')
    })

    // window.getSelection()
  }


  return (
    isLoaded ? (
      <>
        {/* MAPA */}
        <Grid
          container
          height={{ xs: '100%', md: '98%' }}
        >
          <GoogleMap
            onLoad={handleLoad}
            options={optionsMap}
            mapContainerStyle={{ width: '100%', height: '100%', }}
            center={coordinates?coordinates:{lat: -23.5505199, lng: -46.63330939999999}}
            zoom={15}
            mapTypeId='roadmap'
            onDragEnd={handleCenterChanged}
            onClick={handleOnClickMap}
            onZoomChanged={handleCenterChanged}
          >
            <Autocomplete
              onPlaceChanged={onPlaceChanged}
              onLoad={onLoad}
            >

              <InputBase id='pesquisar_map'
                name='pesquisar_map'
                placeholder='Pesquisar...' sx={{
                  root: { display: 'flex' },
                  input: {
                    color: 'Red',
                    backgroundColor: 'white',
                    padding: theme.spacing(1, 1, 1, 0),
                    paddingLeft: theme.spacing(2),
                    transition: theme.transitions.create('width'),
                    width: '50ch',
                    position: 'absolute',
                    marginTop: '30px',
                    marginLeft: '5px',
                    borderRadius: `3px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    [theme.breakpoints.down('md')]: { width: '30ch', display: 'none' }
                  },
                }} />
            </Autocomplete>
            {putArrow &&
              <MarkerF
                key={'a'}
                // onLoad={onLoadMarker}
                position={coordinates}
                title={'Ponto'}
                animation={(google.maps.Animation.BOUNCE)}
                icon={pinSeta('red')}
              />
            }
            {places && places.map((placeA, i) => (
              <MarkerF
                key={i}
                // onLoad={onLoadMarker}
                position={placeA.position}
                onClick={() => handleActiveMarker(i)}
                title={placeA.name}
                animation={activeMarker === i ? (google.maps.Animation.DROP) : (google.maps.Animation.DROP)}
                icon={pinSymbol('blue')}
              >
                {activeMarker === i ? (
                  <InfoWindowF
                    key={i}
                    onCloseClick={handleDeactiveMarker}
                    onDomReady={() => {
                      const iwOuter = document.querySelector('button[title="Close"]');
                      if (iwOuter) { iwOuter.parentNode.style.display = 'none'; }
                    }}
                  >
                    <div name={placeA.key}
                      style={classes.divStyle}
                      onClick={mostrarExtrato}
                    >
                      <Typography
                        mt={2}
                        color={'white'}
                        variant='subtitle1'
                        sx={{ fontStyle: 'normal', backgroundColor: theme.palette.primary.main, px: '3px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                      >
                        {placeA.empreendimento}
                      </Typography>
                      <img alt=''
                        height='100px'
                        width='100%'
                        src={placeA.urlimagem || 'assets/images/gimov_map_symbol.png'}
                      />
                    </div>
                  </InfoWindowF>
                ) : null}
              </MarkerF>
            ))}
          </GoogleMap>
        </Grid>
        {/* BARRA DE INFORMAÇÕES*/}
        <Grid
          container
          justifyContent={'center'}
          alignContent={'center'}
          bgcolor={isFiltering ? green[500] : 'white'}
          height={'17px'}
        >
          {isFiltering && (<FilterAltTwoToneIcon fontSize='small' />)}
          <Typography pl={1} variant='body2' color={isFiltering ? 'black' : 'black'}> {'Empreendimentos: (' + places.length + '/' + totImoveis + ')'}</Typography>
        </Grid>
        {/* MOSTRA EXTRATO DO IMÓVEL */}
        <Dialog
          open={openExtrato}
          scroll={'paper'}
          aria-labelledby="title"
          aria-describedby="dialog"
          fullWidth
        >
          <DialogTitle id="title">
            <Typography component={'span'} variant='h6'>
              Extrato - {places[activeMarker]?.empreendimento}
            </Typography>
          </DialogTitle>
          <DialogContent dividers >
            <DialogContentText
              id="dialog"
              tabIndex={-1}
              height={'400px'}
              component={'div'}
            ><Grid container flex direction={'column'} sx={{ pb: 2 }} >
                {places[activeMarker]?.empreendimento && (<Box>Empreendimento: {places[activeMarker]?.empreendimento}<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.construtora && (<Box>Construtora: {places[activeMarker]?.construtora}<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.blocos && (<Box>Torres: {places[activeMarker]?.blocos} </Box>)}
                {places[activeMarker]?.andares && (<Box>Andares: {places[activeMarker].andares}<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.endereco && (<Box>Endereço: {places[activeMarker]?.endereco}<p hidden>/ln</p></Box>)}
                {(places[activeMarker]?.maxarea && places[activeMarker]?.minarea) && (<Box>Metragens: {places[activeMarker]?.minarea}m2 até {places[activeMarker]?.maxarea}m2<p hidden>/ln</p></Box>)}
                {dormitorios && (<Box>Dormitórios: {dormitorios}</Box>)}
                {(places[activeMarker]?.vlrinicial && places[activeMarker]?.vlrfinal) && (<Box maxWidth={'80vw'} sx={{ whiteSpace: 'normal' }}>Investimento: {places[activeMarker]?.vlrinicial} até {places[activeMarker]?.vlrfinal} aproximadamente<p hidden>/ln</p></Box>)}
                {(places[activeMarker]?.refvlrano && places[activeMarker]?.refvlrmes) && (<Box>Referência mês/ano dos valores: {places[activeMarker]?.refvlrmes}/{places[activeMarker]?.refvlrano}<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.renda && (<Box>Renda: {places[activeMarker]?.renda}<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.entrega && (<Box>Entrega prevista: {places[activeMarker]?.entrega}<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.situacao && (<Box>Situação: {situacaoTipo(places[activeMarker].situacao)}<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.site && (<Box>Site: {places[activeMarker].site}<p hidden>/ln</p></Box>)}
                <Divider sx={{ my: 1 }} />
                <Box my={0}>Facilidades:<p hidden>/ln</p></Box>
                <Divider sx={{ my: 1 }} />
                {places[activeMarker]?.academia && (<Box >Academia<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.cinema && (<Box>Cinema<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.churrasqueira && (<Box>Churrasqueira<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.elevador && (<Box>Elevador<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.espacokids && (<Box>Espaço Kids<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.esportebar && (<Box>Esporte Bar<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.gardem && (<Box>Garden<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.gourmet && (<Box>Espaço Gourmet<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.lavanderia && (<Box>Lavanderia<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.marketplace && (<Box>Marketplace<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.petcare && (<Box>Petcare<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.petplace && (<Box>Petplace<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.piscina && (<Box>Piscina<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.playgroud && (<Box>Playgroud<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.qdesportiva && (<Box>Quadra Esportiva<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.qdtenis && (<Box>Quadra de Tênis<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.qdvolei && (<Box>Quadra de Volei<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.quiosquechur && (<Box>Quiosque com churrasqueira<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.rooftop && (<Box>Rooftop<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.sacada && (<Box>Sacada<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.salafesta && (<Box>Salão de festas<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.salajogos && (<Box>Sala de jogos<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.salamult && (<Box>Sala multiuso<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.sauna && (<Box>Sauna<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.vagafixa && (<Box>Vaga fixa<p hidden>/ln</p></Box>)}
                {places[activeMarker]?.vagarotativa && (<Box>Vaga rotativa<p hidden>/ln</p></Box>)}
                {(putDivulgacao) && (places[activeMarker]?.divulgacao && (<Divider sx={{ my: 1 }} />))}
                {(putDivulgacao) && (places[activeMarker]?.divulgacao && (<Box my={0}>Divulgação:<p hidden>/ln</p></Box>))}
                {(putDivulgacao) && (places[activeMarker]?.divulgacao && (<Box >{places[activeMarker].divulgacao}<p hidden>/ln</p></Box>))}
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent={'flex-end'} px={2}>
              {places[activeMarker]?.divulgacao && (<FormControlLabel control={<Checkbox
                onClick={() => { setPutDivulgacao(!putDivulgacao) }}
                checked={putDivulgacao} />}
                label="Pasta de Divulgação" />
              )}
              <Button onClick={copyDivToClipboard}>Copiar</Button>
              <Button onClick={() => { setOpenExtrato(false) }} sx={{ mr: 1 }}>Fechar</Button>
            </Grid>
          </DialogActions>
        </Dialog>
      </>
    ) : <></>
  )
}


export default Map;