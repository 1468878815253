import { useTheme } from '@emotion/react';
import { Button, Grid } from '@mui/material';
import { green, yellow } from '@mui/material/colors';

export function WhastForm({ account, lead, setSnackbar, setLoading, setShowWhatsapp, showWhatsapp, setShowTaskList }) {

  const theme = useTheme();

  // função para fechar o componente
  const handleClose = () => {
    setShowWhatsapp(false);
    setShowTaskList(true);
  }

  function handleSendWebWhatsapp() {
    const num = "51992870204";
    const msg = "teste de whatsapp";
    const name = "riardo";
  
    const win = window.open(`https://wa.me/${num}?text=I%27m%20api%20msg%20hello%20${name}%20friend%20${msg}`, '_blank');
    win.focus();
  }
  return (
    <Grid container >
      <Grid container
        bgcolor={green[400]}
        height={'90%'}

      >
        <h1>Whatsapp</h1>
      </Grid>
      <Grid container
        height={'10%'}
        minHeight={'50px'}
        bgcolor={theme.palette.background.paper}
        alignContent={'center'}
        justifyContent={'center'}
        px={2}
        pt={1}
      >
        <Grid item>
            <Button variant='contained' size='small' onClick={handleClose}>Fechar</Button>
            <Button variant='contained' size='small' onClick={handleSendWebWhatsapp}>Abrir</Button>
          </Grid>
      </Grid>
    </Grid>
  )
}