
export function diffDate(_dtInicial, _dtFinal) {

  const dtInicial = new Date(_dtInicial);
  const dtFinal = new Date(_dtFinal)

  const tempoRestante = dtFinal.getTime() - dtInicial.getTime();

  const diffDays = Math.floor(tempoRestante / (1000 * 60 * 60 * 24))

  const diffHours = Math.floor((tempoRestante - diffDays * 1000 * 60 * 60 * 24) / (1000 * 60 * 60))

  const diffMinutes = Math.floor((tempoRestante - diffDays * 1000 * 60 * 60 * 24 - diffHours * 1000 * 60 * 60) / (1000 * 60))

  const diffSeconds = Math.floor((tempoRestante - diffDays * 1000 * 60 * 60 * 24 - diffHours * 1000 * 60 * 60 - diffMinutes * 1000 * 60) / 1000);

  const dias = diffDays < 10 ? `0${diffDays}` : diffDays;
  const horas = diffHours < 10 ? `0${diffHours}` : diffHours;
  const min = diffMinutes < 10 ? `0${diffMinutes}` : diffMinutes;
  const seg = diffSeconds < 10 ? `0${diffSeconds}` : diffSeconds;
  const diff = `${dias}d ${horas}h ${min}m`


  // console.log(diffDays);
  // console.log(diffHours);
  // console.log(diffMinutes);
  // console.log(diffSeconds);

  return {
    diff,
    diffDays,
    diffHours,
    diffMinutes,
    diffSeconds
  }
}
