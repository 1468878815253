import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from '../firebase';
import Resizer from "react-image-file-resizer"

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      220,
      220,
      "png",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

export async function sendImgEmpreendimentos(name, file) {
  console.log('sendImgEmpreendimentos', name,file)
  let up = null
  try {
    if (!name) return false
    const image = await resizeFile(file);
    const imgRef = ref(storage, 'empreendimentos/' + name)
    await uploadBytes(imgRef, image).then( async (url) => {
       await getDownloadURL(imgRef).then((url)=> {
        up = url
      })
    }).catch(() => {
      up = null
    })
  }
  catch(e) {
    console.log('Erro sendImgEmpreendimentos', e)
    up = null
  }
  finally {
    return up
  }
}

export async function loadURlImgEmpreendimentos(name) {

  let url = null
  try {
    if (!name) return null
    const pathReference = ref(storage, 'empreendimentos/' + name)
    await getDownloadURL(pathReference).then((_url) => {
      url = _url
    }).catch((error) => {
      url = null
    })
  } catch {
    url = null
  }
  finally {
    return url
  }
}

export async function deleteImgEmpreendimentos(name) {
  let del = false
  try {
    if (!name) return false
    const imgRef = ref(storage, 'empreendimentos/' + name)
    deleteObject(imgRef).then(() => {
      del = true
    }).catch((error) => {
      del = false
    });
  }
  catch {
    del = false
  }
  finally {
    return del
  }
}