import { useState } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { deleteEscala } from '../../../controllers/crm/escalaController';
import dayjs from 'dayjs';
import { red } from '@mui/material/colors';


export function ExcluirEscala(
  {
    setOpenDialogExcluir,
    openDialogExcluir,
    escala, escalas, filteredEscalas,
    idusuario,
    setSnack,
    setSnackbar,
    listEscalas
  }) {

  const [load, setLoad] = useState(false)
  const [disabled, setDisabled] = useState(false)

  // Função para exclusão dos arrays de escalas
  function removeItemFromArr(someArray, idescala) {
    for (var i = 0; i < someArray.length; i++)
      if (someArray[i].idescala === idescala) {
        someArray.splice(i, 1);
        break;
      }
  }

  const handleNo = () => {
    setOpenDialogExcluir(false);
  };

  const handleExcluir = async () => {
    try {
      setDisabled(true)
      setLoad(true)
      const response = await deleteEscala({ idescala: escala.idescala, isactive: false, idusuario: idusuario, version: escala.version});
      if (response.status !== 200) {
        setSnackbar({ severity: 'error', message: 'Não foi possível excluir este controle, verifique os dados informados!' });
        return;
      }
     if (response.status === 200) {
        removeItemFromArr(escalas, escala.idescala)
        removeItemFromArr(filteredEscalas, escala.idescala)
        setSnackbar({ severity: 'success', message: 'Controle excluído com sucesso!' });
      }
    }
    catch (error) {
      setSnackbar({ severity: 'error', message: 'Acesso Concorrente!' });
      setSnack(true)
      listEscalas()
    }
    finally {
      setDisabled(false)
      setLoad(false)
      setOpenDialogExcluir(false)
    }
  };

  return (
    <Dialog
      maxWidth="xs"
      open={openDialogExcluir}
      onClose={handleNo}
    >
      {(load) && ((
        <div style={{ position: 'fixed', top: '50vh', left: '50vw' }}>
          <CircularProgress />
        </div>))}
      <DialogTitle>
        <Typography variant="h4" component="div">
          CONTROLE - Exclusão
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1" component="div">
          Confirma a exclusão do controle do dia {dayjs(escala.diamesano).format('DD/MM/YYYY')} ?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent='flex-end' gap={2}>
          <Button onClick={handleExcluir} disabled={disabled} variant="contained" size='small'sx={{backgroundColor:red[500]}}>
            Excluir
          </Button>
          <Button onClick={handleNo} disabled={disabled} variant="contained" size='small'>
            Não
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};