import { gFases } from '../../constants/fases'
import { apiGate, apiGate2 } from '../../services/api_gate'

export async function createLead(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do createLead') }
  const api = await apiGate2.post('/crm/lead/create', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api)
  return api
}

export async function getLeads(dados) {

  try {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do getLeads') }
    const api = await apiGate.post('/crm/lead/list', dados)
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
    return api
  } catch (e) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Erro em getLeads', e) }
    return []
  }
}

export async function distributeLeads(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou do distributeLeads', dados)
  const api = await apiGate.post('/crm/lead/distribuir', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}

export async function listLeadsDistribuidos(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do listLeadsDistribuido') }
  const api = await apiGate.post('/crm/lead/listleadsdistribuidos', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}

export async function listFasesEtapas(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do listFasesEtapas') }
  const api = await apiGate.post('/crm/lead/listFasesEtapas', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}

export async function liberarLead(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do liberarLead') }
  const api = await apiGate.post('/crm/lead/liberar', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}

export function estatistica(_leads) {
  let cont = []
  let dados = []
  let total = 0
  const leadsQtd = _leads.length
  for (let i = 0; i < gFases.length; i++) {
    let _fase = gFases[i]
    cont[i] = 0
    for (let j = 0; j < leadsQtd; j++) {
      if (_leads[j].idfase === _fase.idfase) {
        cont[i]++
      }
    }
    _fase.total = cont[i]
    total += cont[i]
    dados.push(_fase)
  }
  // Separar os leads por nome do lead e contar as quantidades
  let _leadsNome = []
  let _leadsNomeQtd = []
  for (let i = 0; i < leadsQtd; i++) {
    let _lead = _leads[i]
    let _nome = _lead.nome
    let _index = _leadsNome.indexOf(_nome)
    if (_index === -1) {
      _leadsNome.push(_nome)
      _leadsNomeQtd.push(1)
    } else {
      _leadsNomeQtd[_index]++
    }
  }


  // Colocar os nomes ordem crescente
  _leadsNome = _leadsNome.sort(function (a, b) {
    return a - b;
  })
  // Colocar as quantidades no nomes
  const _oper = []
  for (let i = 0; i < _leadsNome.length; i++) {
    _oper.push({ nome: _leadsNome[i], qtd: _leadsNomeQtd[i] })
  }

  dados.total = total
  dados.oper = _oper
  // console.log('Dados  ', dados)
  return dados
}