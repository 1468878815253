import { useEffect, useState } from 'react';
import {
  Button, Checkbox, Grid, IconButton,
  Link, MenuItem, Select, Table, TableBody, TableCell,
  TableContainer, TableHead, TablePagination, TableRow, TextField, Typography
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextFieldMask } from '../../utils/TextfieldMask/TextfieldMask';
import { getAllUserContas, getUserByEmail, gravarUser } from '../../controllers/userController';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { leaveTheTeam } from '../../controllers/equipeController';
import { arrayDelete } from '../../utils/arrayDelete';
import cep from 'cep-promise';
import { serverTimestamp } from 'firebase/firestore';
import { result } from '../../utils/result';

import { UF } from '../../utils/dados/DadosUF';
import { blue, blueGrey, red, yellow } from '@mui/material/colors';
import { validarCPF } from '../../utils/TextfieldMask/validarCPF';
import { validarCNPJ } from '../../utils/TextfieldMask/validarCPNJ';
import { MySnackBar } from '../snackbar/Snackbar';
import { deleteConta } from '../../controllers/contaController';
import { useNavigate } from 'react-router-dom';
import { getOperador, setOperador, updateOperador } from '../../controllers/crm/operadorController';
import { operadorStatus } from '../../constants/crm';




export default function Perfil({ user, openPerfil, setOpenPerfil, setUser, showMySnackbar }) {

  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em Perfil', user)

  const [openExcluirTeam, setOpenExcluirTeam] = useState(false)
  const [msg, setMsg] = useState('teste')
  const [openSnack, setOpenSnack] = useState(false)
  const [severity, setSeverity] = useState('info') // info error warning success
  const [openEncerrarConta, setOpenEncerrarConta] = useState(false)

  // Estrutra da Tabela de Dados
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([])
  const columns = [
    { id: 'equipe', label: 'Equipe', minwidth: 170 },
    { id: 'email', label: 'Email', minwidth: 170 },
  ];
  function createData(id, equipe, email) {
    return { id, equipe, email };
  }
  // Tabela de Dados
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // Tratamento da Dialog
  const handleClose = () => {
    setOpenPerfil(false);
  };


  const [usuario, setUsuario] = useState({})
  const [nome, setNome] = useState('')
  const [email, setEmail] = useState('')
  const [isPF, setIsPF] = useState(false)
  const [tpPessoa, setTpPessoa] = useState('PF')
  const [cpfcnpj, setCPFCNPJ] = useState('')
  const [nrCRECI, setNrCRECI] = useState('')
  const [ufCRECI, setUFCRECI] = useState('')
  const [telefone, setTelefone] = useState('')
  const [isWhatsApp, setIsWhatsApp] = useState(false)
  const [isSMS, setIsSMS] = useState(false)
  const [isTelVerified, setisTelVerified] = useState(false)
  const [nrCEP, setNrCEP] = useState('')
  const [nrImovel, setNrImovel] = useState('')
  const [complemento, setComplemento] = useState('')
  const [rua, setRua] = useState('')
  const [bairro, setBairro] = useState('')
  const [cidade, setCidade] = useState('')
  const [ufEnd, setUFEnd] = useState('')
  const [erroCEP, setErroCEP] = useState(false)
  const [loading, setLoading] = useState(false)




  async function loadPerfil() {
    setLoading(true)
    const _user = await getUserByEmail(user.email)
    if (_user) {
      if (_user.length > 0) {
        setUsuario(_user[0])
        setNome(_user[0].nome)
        setEmail(_user[0].email)
        setCPFCNPJ(_user[0].cpfcnpj ? _user[0].cpfcnpj : '')
        setTpPessoa(_user[0].tppfpj.toUpperCase())
        if (_user[0].tppfpj.toUpperCase() === 'PF') setIsPF(true)
        else setIsPF(false)
        setNrCRECI(_user[0].nrcreci)
        setUFCRECI(_user[0].ufcreci)
        setTelefone(_user[0].telefone ? _user[0].telefone : '')
        setIsWhatsApp(_user[0].iswhatsapp ? _user[0].iswhatsapp : false)
        setIsSMS(_user[0].issms ? _user[0].issms : false)
        setisTelVerified(_user[0].istelverified ? _user[0].istelverified : false)
        setNrCEP(_user[0].cep ? _user[0].cep : '')
        setRua(_user[0].logradouro ? _user[0].logradouro : '')
        setNrImovel(_user[0].nrimovel ? _user[0].nrimovel : '')
        setComplemento(_user[0].complemento ? _user[0].complemento : '')
        setBairro(_user[0].bairro ? _user[0].bairro : '')
        setUFEnd(_user[0].ufend ? _user[0].ufend : '')
        setCidade(_user[0].municipio ? _user[0].municipio : '')
      }
    }
    setLoading(false)
  }

  async function loadEquipes() {
    let cta = []
    let rst = await getAllUserContas(user.contas)
    if (rst.sucess) {
      if (rst.data.length > 0) {
        rst.data.forEach(element => {
          if (user.id !== element.id)
            cta.push(createData(element.id, element.equipe, element.email))
        });
        setRows(cta)
      }
    }
  }

  const [isloadingCEP, setIsLoadingCEP] = useState(false)
  async function buscaEndereco(event) {
    let _cep = event.target.value
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log(_cep)
    if (_cep.length >= 8) {
      setIsLoadingCEP(true)
      cep(_cep).then((_c) => {
        setRua(_c.street)
        setBairro(_c.neighborhood)
        setCidade(_c.city)
        setUFEnd(_c.state)
        setErroCEP(false)
        setIsLoadingCEP(false)
      }).catch((e) => {
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log(e)
        setRua('')
        setBairro('')
        setCidade('')
        setUFEnd('')
        setErroCEP(true)
        setIsLoadingCEP(false)
      })
    }
    else {
      setRua('')
      setBairro('')
      setCidade('')
      setUFEnd('')
      setErroCEP(true)
    }
  }

  const [selectConta, setSelectConta] = useState([])
  function handleSelectConta(_conta) {
    setSelectConta(_conta)
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('click', _conta, rows)
    setOpenExcluirTeam(true)
  }

  async function excluirTeam() {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Excluir', selectConta, user)
    if (selectConta.id !== user.conta) {
      const rst = await leaveTheTeam(selectConta.id, user.id)
      if (rst.sucess) {
        let _a = arrayDelete(rows, selectConta)
        if (_a) { setRows(_a) }
        let cts = user.contas
        let _b = arrayDelete(cts, selectConta.id)
        if (_b) {
          let _u = user
          _u.contas = _b
          setUser(_u)
        }
        setOpenExcluirTeam(false)
        setMsg(rst.msg)
        setSeverity('success')
        setOpenSnack(true)
      }
      else {
        setOpenSnack(true)
        setMsg(rst.msg)
        setSeverity('error')
      }
    }
    else {
      setMsg('Não é possivel sair da equipe estando logado nela!')
      setSeverity('error')
      setOpenSnack(true)
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Não é possivel excluir a equipe estando logado nela!')
    }
  }

  function handleCloseExc() {
    setOpenExcluirTeam(false)
    setOpenSnack(false)
    setMsg()
  }



  function handleSelectTpPessoa(event) {
    if (event.target.value === 'PF') {
      setIsPF(true)
      setTpPessoa('PF')
    }
    else {
      setIsPF(false)
      setTpPessoa('PJ')
    }
    setCPFCNPJ('')
  }

  const [hasErroNome, setHasErroNome] = useState(false)
  const [hasErroNrCreci, setHasErroNrCreci] = useState(false)

  function handleNome(e) {
    if (e.target.value.length < 5) setHasErroNome(true)
    else setHasErroNome(false)
    setNome(e.target.value)
  }

  const [hasErroUFCRECI, setHasErroUFCRECI] = useState(false)

  function handleNrCRECI(e) {
    if (e.target.value.length <= 5) setHasErroNrCreci(true)
    else setHasErroNrCreci(false)
    setNrCRECI(e.target.value.toUpperCase())
  }

  function handleUFCRECI(e) {
    if (e.target.value.length <= 1) setHasErroUFCRECI(true)
    else setHasErroUFCRECI(false)
    setUFCRECI(e.target.value)
  }

  const [isValidCPFCNPJ, setIsValidCPFCNPJ] = useState(true)
  function validarCPFCNPJ(e) {
    if (isPF) {
      if (validarCPF(e.target.value)) {
        setIsValidCPFCNPJ(true)
        setCPFCNPJ(e.target.value)
      }
      else {
        setIsValidCPFCNPJ(false)
      }
    }
    else {

      if (validarCNPJ(e.target.value)) {
        setIsValidCPFCNPJ(true)
        setCPFCNPJ(e.target.value)
      }
      else {
        setIsValidCPFCNPJ(false)
      }
    }
  }
  const nav = useNavigate()
  async function EncerrarConta() {

    const rst = await deleteConta(user)
    if (rst.sucess) {
      setOpenEncerrarConta(false)
      setOpenPerfil(false)
      nav('/singin', { replace: true })
    }
    else {
      setOpenEncerrarConta(false)
      showMySnackbar('Falha no encerramento da conta', 4000, 'error')
    }
  }

  async function gravarPerfil() {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('entrou em gravarPerfil')
    function validar() {
      result.sucess = true
      result.msg = 'Perfil validado...'
      result.data = null
      let erro = 0
      if (hasErroNome) erro++
      if (hasErroNrCreci) erro++
      if (hasErroUFCRECI) erro++
      if (!isValidCPFCNPJ) erro++
      if (erroCEP) erro++
      if (erro > 0) {
        result.sucess = false
        result.msg = 'Dados inválidos...'
      }
      return result
    }

    if (validar().sucess) {
      const _user = {
        // Não atualiza
        ativo: true,
        email: usuario.email,
        conta: usuario.conta,
        convite: usuario.convite,
        createdat: usuario.createdat,
        id: usuario.id,
        permissions: usuario.permissions,
        rules: usuario.rules,
        photourl: usuario.photourl,
        plano: usuario.plano,

        // Atualiza
        nome: nome,
        tppfpj: tpPessoa.toUpperCase(),
        nrcreci: nrCRECI,
        ufcreci: ufCRECI,

        // Novos campos
        cpfcnpj: cpfcnpj,
        telefone: telefone,
        iswhatsapp: isWhatsApp,
        issms: isSMS,
        cep: nrCEP,
        logradouro: rua,
        nrimovel: nrImovel,
        complemento: complemento,
        bairro: bairro,
        ufend: ufEnd,
        municipio: cidade,
        updatedat: serverTimestamp()
      }
      let rst = await gravarUser(_user)
      setMsg(rst.msg)
      setSeverity(rst.sucess ? 'success' : 'error')
      setOpenSnack(true)

      // Atualizar o usuário no CRM
      if (user.crm) {
        if (user.nome !== nome || user.telefone !== telefone) {
          const dados = {
            idconta: user.conta,
            idoperador: user.id,
            nome: nome,
            telefone: telefone,
          }
          const response = await updateOperador(dados)
          if (response.status === 200) {
            setHasOperadorCRM(true)
          }
          else {
            console.log(response)
            setMsg('Falha ao atualizar o Operador CRM')
            setSeverity('error')
            setOpenSnack(true)
          }
        }
      }
    }
    else {
      setMsg(result.msg)
      setSeverity('error')
      setOpenSnack(true)
    }
  }

  async function criarOperadorCRM() {
    const dados = {
      idconta: user.conta,
      idoperador: user.id,
      nome: nome,
      telefone: telefone,
      status: operadorStatus.Disponivel,
      email: user.email
    }
    if (!telefone) {
      setMsg("Informe um telefone com WHATSAPP para ativar o CRM")
      setSeverity('error')
      setOpenSnack(true)
    }
    else {
      // Inserir operador
      const response = await setOperador(dados)
      if (response.status === 200) {
        setHasOperadorCRM(true)
        setMsg('Operador CRM ativado com sucesso!')
        setSeverity('success')
        setOpenSnack(true)
        // Atualizar Nome e Telefone no firebase
        if (user.nome !== dados.nome || user.telefone !== dados.telefone) {
          user.nome = dados.nome
          user.telefone = dados.telefone
          gravarPerfil()
        }
        user.crm = true
      }
      else {
        console.log(response)
        setMsg('Falha ao ativar o Operador CRM')
        setSeverity('error')
        setOpenSnack(true)
      }
    }
  }

  async function consultaOperadorCRM() {
    const query = {
      where: {
        idconta: user.conta,
        idoperador: user.id
      }
    }
    try {
      const operador = await getOperador(query)
      if (operador.data.length > 0) {
        setHasOperadorCRM(true)
      } else {
        setHasOperadorCRM(false)
      }
    }
    catch (e) {
      setMsg('Falha ao consultar o Operador CRM')
      setSeverity('error')
      setOpenSnack(true)
    }
  }



  const [hasOperadorCRM, setHasOperadorCRM] = useState(false)

  useEffect(() => {
    loadPerfil()
    loadEquipes()
    consultaOperadorCRM()
  }, [])

  return (
    <>
      <Dialog
        open={openPerfil}
        onClose={handleClose}
        fullWidth
      >
        <DialogContent>
          <Grid container
            direction={'row'}
          >
            {/* Nome */}
            <TextField
              variant='standard'
              required
              fullWidth
              id="nome"
              label='Nome'
              name="nome"
              type='text'
              autoFocus
              value={nome}
              error={hasErroNome}
              onChange={handleNome}
              inputProps={{ maxLength: 70 }}
            />
          </Grid>
          <Grid container gap={2} mt={1}>
            {/* Tipo de Pessoa  */}
            <Select
              variant='standard'
              id="tppfpj"
              name='tppfpj'
              labelId="ilPFPJ"
              label="P.F ou P.J"
              onChange={handleSelectTpPessoa}
              value={tpPessoa}
            >
              <MenuItem key={1} value='PF'>P.F</MenuItem>
              <MenuItem key={2} value='PJ'>P.J</MenuItem>
            </Select>
            <TextFieldMask
              variant='standard'
              required
              id="cpfcnpj"
              name="cpfcnpj"
              label="CPF/CNPJ"
              mask={isPF ? 'cpf' : 'cnpj'}
              value={cpfcnpj}
              onBlur={validarCPFCNPJ}
              onChange={(e) => { setCPFCNPJ(e.target.value) }}
              error={!isValidCPFCNPJ}
              inputProps={{ maxLength: 80 }}
            />
            {(user.hascrm && !hasOperadorCRM) &&
              <Button
                variant='outlined'
                disabled={loading}
                onClick={criarOperadorCRM}
                sx={{ typography: 'caption', color: red[500] }}
              >Ativar CRM</Button>
            }
          </Grid>
          <Grid
            container
            direction={'row'}
            alignItems={'center'}
            mt={2}
          >
            {/* Email */}
            <TextField
              variant='standard'
              fullWidth
              id="email"
              label={'Email'}
              name="email"
              value={email}
              disabled
              sx={{ maxWidth: '290px' }}
              inputProps={{ maxLength: 80 }}
            />
            {/* Email Verificado */}
            <Checkbox disabled sx={{ mt: 1 }}
              checked={user.emailVerified}
            />
            <Typography mt={1} variant='body1'>
              Email verificado
            </Typography>
          </Grid>

          <Grid container
            direction={'row'}
            mt={2}
            gap={2}
          >
            {/* Nr CRECI */}
            <TextFieldMask
              variant='standard'
              required
              type="text"
              id="nrcreci"
              name="nrcreci"
              label="Nr CRECI"
              value={nrCRECI}
              error={hasErroNrCreci}
              onChange={handleNrCRECI}
              sx={{ maxWidth: '130px' }}
              mask={'nrcreci'}
            />
            {/* UFCRECI */}
            <Select
              variant='standard'
              labelId="ilUF"
              id="ufcreci"
              name='ufcreci'
              label="UF"
              value={ufCRECI}
              error={hasErroUFCRECI}
              onChange={handleUFCRECI}
            >
              <MenuItem key={'0'} value={''}>{''}</MenuItem>
              {UF.map((_uf) =>
                <MenuItem key={_uf.sigla} value={_uf.sigla}>{_uf.sigla}</MenuItem>
              )}
            </Select>
          </Grid>

          <Grid container
            gap={2}
            direction={'row'}
            columnSpacing={0}
            alignItems={'center'}
          >
            {/* Telefone */}
            <TextFieldMask
              variant='standard'
              id="telefone"
              label="Telefone"
              name="telefone"
              type='text'
              mask={'phone'}
              value={telefone}
              onChange={(e) => { setTelefone(e.target.value) }}
              sx={{ maxWidth: 130 }}
            />
            {/* WhatsApp */}
            <Checkbox sx={{ mt: 1 }} checked={isWhatsApp} onChange={(e) => { setIsWhatsApp(e.target.checked) }} />
            <Typography variant='body1' sx={{ mt: 1 }}>WhatsApp</Typography>
            {/* Recebe SMS */}
            <Checkbox checked={isSMS} sx={{ mt: 1 }} onChange={(e) => { setIsSMS(e.target.checked) }} />
            <Typography variant='body1' sx={{ mt: 1 }}>SMS</Typography>
            {/* Fone Verificado SMS */}
            <Checkbox disabled checked={isTelVerified} sx={{ mt: 1 }} />
            <Typography variant='body1' sx={{ mt: 1 }} >Telefone verificado</Typography>
          </Grid>
          <Grid container
            gap={2}
            direction={'row'}
            alignItems={'center'} >
            {/* CEP */}
            <Grid sx={{}}>
              <TextFieldMask
                variant='standard'
                required
                id="cep"
                label="CEP"
                name="cep"
                type='text'
                mask={'cep'}
                value={nrCEP}
                onChange={(e) => { setNrCEP(e.target.value) }}
                onInput={() => { setErroCEP(false) }}
                onBlur={buscaEndereco}
                sx={{ maxWidth: 130, backgroundColor: isloadingCEP ? yellow[300] : '' }}
                error={erroCEP}
              />
            </Grid>
            <Link href='https://buscacepinter.correios.com.br/app/endereco/index.php' target='_blank' variant="body2" mx={1} mt={1}>
              Consultar CEP
            </Link>
          </Grid>
          <Grid container
            gap={2}
            direction={'row'}
            mt={2}
          >
            {/* Logradouro */}
            <Grid item xs={8}>
              <TextField
                variant='standard'
                id="rua"
                name="rua"
                label={rua ? '' : 'Logradouro'}
                value={rua ? rua : ''}
                onChange={(e) => { setRua(e.target.value) }}
                disabled={!erroCEP}
                type='text'
                inputProps={{ maxLength: 150 }}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              {/* Nr logradouro */}
              <TextField
                variant='standard'
                fullWidth
                id="nrlogradouro"
                label="Nr"
                name="nrlogradouro"
                type='text'
                value={nrImovel}
                onChange={(e) => { setNrImovel(e.target.value) }}
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
          </Grid>
          <Grid container
            direction={'row'}
            alignContent={'center'}
          // mt={2}
          >
            {/* Complemento */}
            <TextField
              variant='standard'
              fullWidth
              id="complemento"
              label="Complemento"
              name="complemento"
              type='text'
              value={complemento}
              onChange={(e) => { setComplemento(e.target.value) }}
              inputProps={{ maxLength: 150 }}
            />
          </Grid>
          <Grid container
            direction={'row'}
            alignContent={'center'}
            gap={2}
          >
            {/* Bairro */}
            <TextField
              variant='standard'
              fullWidth
              id="bairro"
              name="bairro"
              type='text'
              disabled={!erroCEP}
              label={bairro ? '' : 'Bairro'}
              value={bairro ? bairro : ''}
              onChange={(e) => { setBairro(e.target.value) }}
              sx={{ maxWidth: 200 }}
              inputProps={{ maxLength: 150 }}
            />
            {/* UF ENDEREÇO */}
            <TextField
              variant='standard'
              id="estado"
              name="estado"
              type='text'
              label={ufEnd ? '' : 'UF'}
              value={ufEnd ? ufEnd : ''}
              disabled={!erroCEP}
              onChange={(e) => { setUFEnd(e.target.value) }}
              sx={{ maxWidth: 50 }}
              inputProps={{ maxLength: 2 }}
            />
            {/* Cidade */}
            <TextField
              variant='standard'
              fullWidth
              id="cidade"
              name="cidade"
              type='text'
              disabled={!erroCEP}
              label={cidade ? '' : 'Cidade'}
              value={cidade ? cidade : ''}
              onChange={(e) => { setCidade(e.target.value) }}
              sx={{ maxWidth: 200 }}
              inputProps={{ maxLength: 150 }}
            />
          </Grid>
          {/* TABELA COM AS EQUIPES */}
          {rows.length > 0 && (
            //{/* Tabela de Equipes  */}
            <Grid container
              mt={2}
              direction={'row'}>
              <Grid item style={{ maxHeight: '150px', width: '100%' }} sx={{ overflow: 'auto' }}>
                <TableContainer >
                  <Table
                    aria-label="Equipes" size='small'
                    sx={{ backgroundColor: yellow[100] }}
                  >
                    <TableHead>
                      <TableRow
                        sx={{ backgroundColor: blueGrey[100] }}
                      >
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            hidden={true}
                          >
                            <Typography variant='body1' >
                              {column.label}
                            </Typography>
                          </TableCell>
                        ))}
                        <TableCell>*</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox" sx={{ alignContent: 'center' }}
                              tabIndex={-1}
                              key={row.equipe}
                            >
                              {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    <Typography variant='subtitle1'>
                                      {column.format && typeof value === 'number'
                                        ? column.format(value)
                                        : value}
                                    </Typography>
                                  </TableCell>
                                );
                              })}
                              <TableCell>
                                <IconButton aria-label="delete"
                                  onClick={() => { handleSelectConta(row) }}
                                >
                                  <DeleteTwoToneIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Grid container direction={'row'} width={'100%'} justifyContent={'space-between'}>
            <Button onClick={() => { setOpenEncerrarConta(true) }}
              sx={{ typography: 'caption', color: red[500] }}
            >
              Encerrar Conta
            </Button>
            <Grid item>
              <Button
                onClick={gravarPerfil}
                disabled={isloadingCEP}
                sx={{ typography: 'caption', color: blue[500] }}
              >Gravar</Button>
              <Button
                onClick={handleClose}
                sx={{ typography: 'caption', color: blue[500] }}
              >Fechar</Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {/* Mensagem - Snackbar */}
      <MySnackBar
        msg={msg}
        open={openSnack}
        tmp={6000}
        severity={severity}
        setOpenSnack={setOpenSnack} />

      {/* Dialog Retirar Usuário da Equipe */}
      <Dialog
        open={openExcluirTeam}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Sair da equipe"}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant='body1' component={'span'} color={'error'}>
              {selectConta.equipe} ({selectConta?.email}) ?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={excluirTeam} autoFocus>Sim</Button>
          <Button onClick={handleCloseExc}>Não</Button>
        </DialogActions>
      </Dialog>
      {/* Dialog Encerrar Conta */}
      <Dialog
        open={openEncerrarConta}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Encerrar Conta"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant='body1' component={'span'} color={'error'}>
              Atenção!<br /><br /> Todos os dados vinculados a esta conta serão excluidos do aplicativo GIMOV.<br />
              Para a sua segurança NÃO será possível recuperar os dados apagados. <br /><br />
              Você confirma a exclusão de todos os dados do usuário do GIMOV?<br /><br /> Nome: {user.nome}<br /><br />Email: {user.email}<br />
            </Typography><br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={EncerrarConta} autoFocus>Sim</Button>
          <Button onClick={() => { setOpenEncerrarConta(false) }}>Não</Button>
        </DialogActions>
      </Dialog>

    </>

  )
}