import { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { Button, Checkbox, FormControlLabel, Grid, LinearProgress, Paper, Radio, RadioGroup, Slider, TextField, Typography, useTheme } from '@mui/material';
import List from '../../components/List/List';
import Map from '../../components/Map/Map';
import { places_example } from './Data';
import MenuAppBar from '../../components/Menu/Menu';
import { listAllPlaces } from '../../controllers/placeController';
import { useLocation } from 'react-router-dom';
import { Welcome } from './Welcome';
import Perfil from '../../components/Perfil/Perfil';
import { ConfirmationConvite } from '../../components/Convites/Convites';
import Equipe from '../../components/Equipe/Equipe';
import { Mensagem } from '../../components/Mensagem/Mensagem';
import { getContaMessage } from '../../controllers/contaController';
import { InfoPlace } from '../../components/Modal/CadPlaces/InfoPlace';
import { MySnackBar } from '../../components/snackbar/Snackbar';
import { blue } from '@mui/material/colors';

export function Home() {

  const theme = useTheme();

  const { state } = useLocation();
  const account = state?.account ? state.account : null

  const [user, setUser] = useState(account);

  const [childClicked, setChildClicked] = useState(null);
  const [coordinates, setCoordinates] = useState(state.coordinates ? state.coordinates : null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [bounds, setBounds] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [addMap, setAddMap] = useState(false)
  const [isExample, setIsExample] = useState(true)

  const [openModal, setOpenModal] = useState(false)
  const [newPosition, setNewPosition] = useState()
  const [posAtual, setPosAtual] = useState()
  const [isPageAjuda, setIsPageAjuda] = useState(false)

  const [openFilter, setOpenFilter] = useState(false)
  const [isFiltering, setIsFiltering] = useState(false)

  const [openPerfil, setOpenPerfil] = useState(false)

  const [openEquipe, setOpenEquipe] = useState(false)
  const [hasConvite, setHasConvite] = useState(user.hasinvite)
  const [openConvite, setOpenConvite] = useState(false)

  const [hasPlaceNew, setHasPlaceNew] = useState()
  const [newPlace, setNewPlace] = useState({})

  const [hasAvisos, setHasAvisos] = useState(false)
  const [openMessage, setOpenMessage] = useState(false)
  const [msgAdm, setMsgAdm] = useState()

  // Constantes do MySnackBar
  const [msg, setMsg] = useState('')
  const [openSnack, setOpenSnack] = useState(false)
  const [tmp, setTMP] = useState(6000)
  const [severity, setSeverity] = useState('info')

  function showMySnackbar(_msg, _tmp, _severity) {
    setMsg(_msg)
    setTMP(_tmp)
    setSeverity(_severity)
    setOpenSnack(true)
  }

  async function loadQuadroAvisos() {
    let rst = await getContaMessage(user.conta)
    if (rst.sucess) {
      if (rst.data.length > 0) {
        setHasAvisos(true)
        setMsgAdm(rst.data[0])
      }
      else setHasAvisos(false)
    }
    else setHasAvisos(false)
  }



  useEffect(() => {
    navigator.geolocation.getCurrentPosition(({ coords: { latitude, longitude } }) => {
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Carregando Coordinates:', latitude, longitude)
      setCoordinates({ lat: latitude, lng: longitude });
      setPosAtual({ lat: latitude, lng: longitude })
    });
  }, []);

  useEffect(() => {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Carregar Conta:', account, user)
    if (account) {
      setUser(account)
      loadQuadroAvisos()
      getAllPlaces()
    }
  }, [account])



  useEffect(() => {
    getAllPlaces()
  }, [hasPlaceNew])

  const [imoveis, setImoveis] = useState([])
  const [placesInBounds, setPlacesInBouds] = useState([])
  const [placesfiltered, setPlacesFiltered] = useState([])

  const [qtdTotImoveis, setQtdTotImoveis] = useState(0)
  const [mostraLista, setMostraLista] = useState(false)
  // Quantidade maxima de places sem ativar a geolocalização para visualização

  const qtdToGeo = 20

  useEffect(() => {
    let inBounds = []
    imoveis.map((place) => {
      if (place.position.lng > bounds.west
        && place.position.lng < bounds.east
        && place.position.lat > bounds.south
        && place.position.lat < bounds.north) {
        inBounds.push(place)
      }
    })
    setPlacesInBouds(inBounds)
    setActiveMarker(null)
    if (imoveis.length > 0) {
      if (imoveis.length <= qtdToGeo) setMostraLista(true)
      else {
        if (inBounds.length > 0) setMostraLista(true)
        else setMostraLista(true)
      }
    } else setMostraLista(false)
  }, [bounds, imoveis, qtdToGeo])

  const [isOu, setIsOu] = useState('e')

  const handleIsOu = (event) => {
    setIsOu(event.target.value);
  };

  async function getAllPlaces() {
    if (user) {
      setIsLoading(true)

      const listPlace = await listAllPlaces(user)
      setImoveis(listPlace)
      setQtdTotImoveis(listPlace.length)
      if (listPlace.length > 0) {
        setIsPageAjuda(false)
        setIsExample(false)
      }
      else {
        //const listExample = await loadListExample(user)
        setImoveis(places_example)
        setQtdTotImoveis(places_example.length)
      }
      setIsLoading(false)
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Listou os imóveis:', imoveis, imoveis.length)
    } else {
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Sem usuário para listar móveis:', imoveis, user)
    }
  }

  // Manutenção Convites
  const handleClose = () => {
    setOpenConvite(false);
  };

  // Tratamento do Filtro

  const [sacada, setSacada] = useState(false)
  const [elevador, setElevador] = useState(false)
  const [piscina, setPiscina] = useState(false)
  const [salafesta, setSalaFesta] = useState(false)
  const [churrasqueira, setChurrasqueira] = useState(false)
  const [quiosquechur, setQuiosquechur] = useState(false)
  const [playgroud, setPlaygroud] = useState(false)
  const [espacokids, setEspacokids] = useState(false)
  const [salajogos, setSalajogos] = useState(false)
  const [gourmet, setGourmet] = useState(false)
  const [vagafixa, setVagaFixa] = useState(false)
  const [vagarotativa, setVagaRotativa] = useState(false)
  const [gardem, setGardem] = useState(false)
  const [academia, setAcademia] = useState(false)
  const [qdesportiva, setQdEsportiva] = useState(false)
  const [qdtenis, setQdTenis] = useState(false)
  const [qdvolei, setQdVolei] = useState(false)
  const [esportebar, setEsporteBar] = useState(false)
  const [petcare, setPetCare] = useState(false)
  const [petplace, setPetPlace] = useState(false)
  const [salamult, setSalaMult] = useState(false)
  const [coworking, setCoworking] = useState(false)
  const [marketplace, setMarketplace] = useState(false)
  const [lavanderia, setLavanderia] = useState(false)
  const [sauna, setSauna] = useState(false)
  const [rooftop, setRooftop] = useState(false)
  const [cinema, setCinema] = useState(false)
  const [hasCheck, setHasCheck] = useState(false)
  const [nomeEmp, setNomeEmp] = useState('')
  const [hasNewLead, setHasNewLead] = useState(false)



  const handleInput = (event) => {
    if (event.target.checked) setHasCheck(true)
    if (event.target.id === 'sacada') { setSacada(Boolean(event.target.checked)) }
    if (event.target.id === 'elevador') { setElevador(Boolean(event.target.checked)) }
    if (event.target.id === 'piscina') { setPiscina(Boolean(event.target.checked)) }
    if (event.target.id === 'salafesta') { setSalaFesta(Boolean(event.target.checked)) }
    if (event.target.id === 'churrasqueira') { setChurrasqueira(Boolean(event.target.checked)) }
    if (event.target.id === 'quiosquechur') { setQuiosquechur(Boolean(event.target.checked)) }
    if (event.target.id === 'playgroud') { setPlaygroud(Boolean(event.target.checked)) }
    if (event.target.id === 'espacokids') { setEspacokids(Boolean(event.target.checked)) }
    if (event.target.id === 'salajogos') { setSalajogos(Boolean(event.target.checked)) }
    if (event.target.id === 'gourmet') { setGourmet(Boolean(event.target.checked)) }
    if (event.target.id === 'vagafixa') { setVagaFixa(Boolean(event.target.checked)) }
    if (event.target.id === 'vagarotativa') { setVagaRotativa(Boolean(event.target.checked)) }
    if (event.target.id === 'gardem') { setGardem(Boolean(event.target.checked)) }
    if (event.target.id === 'academia') { setAcademia(Boolean(event.target.checked)) }
    if (event.target.id === 'qdesportiva') { setQdEsportiva(Boolean(event.target.checked)) }
    if (event.target.id === 'qdtenis') { setQdTenis(Boolean(event.target.checked)) }
    if (event.target.id === 'qdvolei') { setQdVolei(Boolean(event.target.checked)) }
    if (event.target.id === 'esportebar') { setEsporteBar(Boolean(event.target.checked)) }
    if (event.target.id === 'petcare') { setPetCare(Boolean(event.target.checked)) }
    if (event.target.id === 'petplace') { setPetPlace(Boolean(event.target.checked)) }
    if (event.target.id === 'salamult') { setSalaMult(Boolean(event.target.checked)) }
    if (event.target.id === 'coworking') { setCoworking(Boolean(event.target.checked)) }
    if (event.target.id === 'marketplace') { setMarketplace(Boolean(event.target.checked)) }
    if (event.target.id === 'lavanderia') { setLavanderia(Boolean(event.target.checked)) }
    if (event.target.id === 'sauna') { setSauna(Boolean(event.target.checked)) }
    if (event.target.id === 'rooftop') { setRooftop(Boolean(event.target.checked)) }
    if (event.target.id === 'cinema') { setCinema(Boolean(event.target.checked)) }
  }

  function closeFilter() {
    setOpenFilter(false)
    setActiveMarker(null)
  }

  function filtrarPlaces() {
    setPlacesFiltered([])
    let _filtro = []
    if (isOu === 'ou') {
      imoveis.map((imv) => {
        if (
          (hasCheck) && (
            (imv.sacada ? sacada : false) ||
            (imv.elevador ? elevador : false) ||
            (imv.piscina ? piscina : false) ||
            (imv.salafesta ? salafesta : false) ||
            (imv.churrasqueira ? churrasqueira : false) ||
            (imv.quiosquechur ? quiosquechur : false) ||
            (imv.playgroud ? playgroud : false) ||
            (imv.espacokids ? espacokids : false) ||
            (imv.salajogos ? salajogos : false) ||
            (imv.gourmet ? gourmet : false) ||
            (imv.vagafixa ? vagafixa : false) ||
            (imv.vagarotativa ? vagarotativa : false) ||
            (imv.gardem ? gardem : false) ||
            (imv.academia ? academia : false) ||
            (imv.qdesportiva ? qdesportiva : false) ||
            (imv.qdtenis ? qdtenis : false) ||
            (imv.qdvolei ? qdvolei : false) ||
            (imv.esportebar ? esportebar : false) ||
            (imv.petcare ? petcare : false) ||
            (imv.petplace ? petplace : false) ||
            (imv.salamult ? salamult : false) ||
            (imv.coworking ? coworking : false) ||
            (imv.marketplace ? marketplace : false) ||
            (imv.lavanderia ? lavanderia : false) ||
            (imv.sauna ? sauna : false) ||
            (imv.rooftop ? rooftop : false) ||
            (imv.cinema ? cinema : false)
          )
        ) { // Deu match, validar valores
          let vlrNChk = 0
          if (chkRenda) {
            if ((parseFloat(imv.renda.replace('R$', '')) * 1000) > fltRenda) { vlrNChk++ }
          }
          if (chkVlrInv) {
            if ((parseFloat(imv.vlrinicial.replace('R$', '')) * 1000) > fltVlrInv) { vlrNChk++ }
          }
          if (nomeEmp.length > 0) {
            if (!imv.empreendimento.toUpperCase().includes(nomeEmp.toUpperCase())) {
              vlrNChk++
            }
          }
          if (vlrNChk === 0) {
            _filtro.push(imv)
          }
        }
        else { // Não deu match 
          let vlrChk = 0
          if (nomeEmp.length > 0) {
            if (imv.empreendimento.toUpperCase().includes(nomeEmp.toUpperCase())) { vlrChk++ }
          }
          if (chkRenda || chkVlrInv) {
            if (chkRenda) {
              if ((parseFloat(imv.renda.replace('R$', '')) * 1000) <= fltRenda) { vlrChk++ }
            }
            if (chkVlrInv) {
              if ((parseFloat(imv.vlrinicial.replace('R$', '')) * 1000) <= fltVlrInv) { vlrChk++ }
            }
          }

          if (vlrChk > 0) {
            _filtro.push(imv)
          }
        }
      })
    }
    else { //isOu === 'e'
      imoveis.map((imv) => {
        let hasItem = 0
        let qtdChecked = 0

        if (chkRenda) {
          qtdChecked++
          if ((parseFloat(imv.renda.replace('R$', '')) * 1000) <= fltRenda) {
            hasItem++
          }
        }
        if (chkVlrInv) {
          qtdChecked++
          if ((parseFloat(imv.vlrinicial.replace('R$', '')) * 1000) <= fltVlrInv) {
            hasItem++
          }
        }

        if (sacada) {
          qtdChecked++
          if (imv.sacada) { hasItem++ }
        }
        if (elevador) {
          qtdChecked++
          if (imv.elevador) { hasItem++ }
        }
        if (piscina) {
          qtdChecked++
          if (imv.piscina) { hasItem++ }
        }
        if (salafesta) {
          qtdChecked++
          if (imv.salafesta) { hasItem++ }
        }
        if (churrasqueira) {
          qtdChecked++
          if (imv.churrasqueira) { hasItem++ }
        }
        if (quiosquechur) {
          qtdChecked++
          if (imv.quiosquechur) { hasItem++ }
        }
        if (playgroud) {
          qtdChecked++
          if (imv.playgroud) { hasItem++ }
        }
        if (espacokids) {
          qtdChecked++
          if (imv.espacokids) { hasItem++ }
        }
        if (salajogos) {
          qtdChecked++
          if (imv.salajogos) { hasItem++ }
        }
        if (gourmet) {
          qtdChecked++
          if (imv.gourmet) { hasItem++ }
        }
        if (vagafixa) {
          qtdChecked++
          if (imv.vagafixa) { hasItem++ }
        }
        if (vagarotativa) {
          qtdChecked++
          if (imv.vagarotativa) { hasItem++ }
        }
        if (gardem) {
          qtdChecked++
          if (imv.gardem) { hasItem++ }
        }
        if (academia) {
          qtdChecked++
          if (imv.academia) { hasItem++ }
        }
        if (qdesportiva) {
          qtdChecked++
          if (imv.qdesportiva) { hasItem++ }
        }
        if (qdtenis) {
          qtdChecked++
          if (imv.qdtenis) { hasItem++ }
        }
        if (qdvolei) {
          qtdChecked++
          if (imv.qdvolei) { hasItem++ }
        }
        if (esportebar) {
          qtdChecked++
          if (imv.esportebar) { hasItem++ }
        }
        if (petcare) {
          qtdChecked++
          if (imv.petcare) { hasItem++ }
        }
        if (petplace) {
          qtdChecked++
          if (imv.petplace) { hasItem++ }
        }
        if (salamult) {
          qtdChecked++
          if (imv.salamult) { hasItem++ }
        }
        if (coworking) {
          qtdChecked++
          if (imv.coworking) { hasItem++ }
        }
        if (marketplace) {
          qtdChecked++
          if (imv.marketplace) { hasItem++ }
        }
        if (lavanderia) {
          qtdChecked++
          if (imv.lavanderia) { hasItem++ }
        }
        if (sauna) {
          qtdChecked++
          if (imv.sauna) { hasItem++ }
        }
        if (rooftop) {
          qtdChecked++
          if (imv.rooftop) { hasItem++ }
        }
        if (cinema) {
          qtdChecked++
          if (imv.cinema) { hasItem++ }
        }
        if ((hasItem > 0) && (hasItem === qtdChecked)) {
          if (nomeEmp.length > 0) {
            if (imv.empreendimento.toUpperCase().includes(nomeEmp.toUpperCase())) { _filtro.push(imv) }
          }
          else _filtro.push(imv)
        }
        else {
          if (qtdChecked === 0 && nomeEmp.length > 0) {
            if (imv.empreendimento.toUpperCase().includes(nomeEmp.toUpperCase())) { _filtro.push(imv) }
          }
        }
      })
    }
    setPlacesFiltered(_filtro)
  }

  const [chkVlrInv, setChkVlrInv] = useState(false)
  const [fltVlrInv, setFltVlrInf] = useState(0)

  function handleVlrInv(event) {
    setFltVlrInf(event.target.value)
  }
  const [chkRenda, setChkRenda] = useState(false)
  const [fltRenda, setFltRenda] = useState(0)
  function handleRenda(event) {
    setFltRenda(event.target.value)
  }

  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou Home', user)
  return (
    <Grid container>
      {/* Menu Superior */}
      <MenuAppBar
        setAddMap={setAddMap}
        addMap={addMap}
        setActiveMarker={setActiveMarker}
        user={user}
        setCoordinates={setCoordinates}
        coordinates={coordinates}
        setIsPageAjuda={setIsPageAjuda}
        isPageAjuda={isPageAjuda}
        openPerfil={openPerfil}
        setOpenPerfil={setOpenPerfil}
        openEquipe={openEquipe}
        setOpenEquipe={setOpenEquipe}
        hasConvite={hasConvite}
        setOpenConvite={setOpenConvite}
        setPosAtual={setPosAtual}
        hasMessage={hasAvisos}
        setOpenMessage={setOpenMessage}
        setOpenFilter={setOpenFilter}
        isFiltering={isFiltering}
        setIsFiltering={setIsFiltering}
        isExample={isExample}
        hasNewLead={hasNewLead}
        setHasNewLead={setHasNewLead}
      />

      <Grid container>
        {/* --> Mostra Loading <--  */}
        {(isLoading) && (
          <Grid container
            width='20%'
            height='93vh'
            //border={1}
            sx={{
              justifyContent: 'center',
              p: '50px',
              [theme.breakpoints.down('sm')]: {
                height: '50%',
                width: '100vw',
              },
              [theme.breakpoints.between('sm', 'lg')]: {
                height: '100%',
                width: '30vw',
              },
              [theme.breakpoints.up('lg')]: {
                height: '100%',
                width: '25vw',
              }
            }}
          >
            <LinearProgress color="secondary" sx={{ width: '80%' }} />
            <Typography marginTop={'50px'} variant='h5' align='center'>Informação certa, no lugar certo e no momento oportuno.</Typography>
          </Grid>
        )}
        {/* --> Mostra WELCOME E TUTORIAL <--  */}
        {(isPageAjuda && !isLoading) && <Welcome isExample={isExample} />}
        {/* --> Mostra Não há imóveis na região <--  */}
        {(!isPageAjuda && !isLoading && !mostraLista) && (
          <Grid
            container
            height={'100%'}
            width={'25vw'}
            alignContent={'center'}
            justifyContent={'center'}
            p={2}
            // sx={{ backgroundColor: 'primary.main' }}
            sx={{
              backgroundColor: 'primary.main',
              [theme.breakpoints.down('sm')]: {
                height: '50%',
                width: '100vw',
              },
              [theme.breakpoints.between('sm', 'lg')]: {
                height: '100%',
                width: '30vw',
              },
              [theme.breakpoints.up('lg')]: {
                height: '100%',
                width: '25vw',
              }
            }}
          >
            <Grid
              container
              alignContent={'center'}
              justifyContent={'center'}
              height={'100%'}
              sx={{
                backgroundPosition: 'bottom',
                backgroundImage: 'url(./logo2.svg)',
                //backgroundImage: 'url(./images/gimov_hand.png)',
                backgroundRepeat: 'no-repeat',
                color: 'white',
                p: 1
              }}
            >
              <Typography variant='body1'>Não há imóveis cadastrados nesta região...</Typography>
            </Grid>
          </Grid>
        )}
        {/* --> Mostra Mensagem do Administrador <--  */}
        {openMessage && (
          <Mensagem openMessage={openMessage} setOpenMessage={setOpenMessage} msgAdm={msgAdm} />
        )}
        {/*Mostra Convites Recebidos */}
        {openConvite && (
          <ConfirmationConvite
            id="convites"
            keepMounted
            onClose={handleClose}
            open={openConvite}
            user={user}
          />
        )}
        {/*Mostra formulário de Manutenção de Equipe */}
        {openEquipe && (
          <Equipe user={user} openEquipe={openEquipe} setOpenEquipe={setOpenEquipe}
            setHasMessage={setHasAvisos} setMsgAdm={setMsgAdm} msgAdm={msgAdm} />
        )}
        {/*Mostra formulário de Manutenção de Perfil */}
        {(openPerfil) && (
          <Perfil
            user={user}
            openPerfil={openPerfil}
            setOpenPerfil={setOpenPerfil}
            setUser={setUser}
            showMySnackbar={showMySnackbar}
          />
        )}
        {/*Mostra Modal de Manutenção de Imoveis */}
        {(addMap && openModal) && (
          <InfoPlace
            addMap={addMap}
            setAddMap={setAddMap}
            setOpenModal={setOpenModal}
            newPosition={newPosition}
            setNewPlace={setNewPlace}
            newPlace={newPlace}
            setHasPlaceNew={setHasPlaceNew}
            user={user}
            showMySnackbar={showMySnackbar}
          />
        )}
        {/* --> Mostra Listagem Detalhe dos Imóveis <--  */}
        {(!isPageAjuda && !isLoading && mostraLista) && (
          <Grid
            container
            height={{ xs: '35vh', sm: '35vh', md: '92vh' }}
            width={{ sm: '100vw', md: '30vw', lg: '25vw' }}
            overflow={'auto'}
          >
            < List
              user={user}
              places={(imoveis.length <= qtdToGeo) ? ((isFiltering) ? placesfiltered : imoveis) : isFiltering ? placesfiltered : placesInBounds}
              childClicked={childClicked}
              isLoading={isLoading}
              setChildClicked={setChildClicked}
              setCoordinates={setCoordinates}
              setActiveMarker={setActiveMarker}
              setAddMap={setAddMap}
              posAtual={posAtual}
              showMySnackbar={showMySnackbar}
            />
          </Grid>
        )}
        {/* --> Mostra MAPA <--  */}
        <Grid
          container
          height={{ xs: '51vh',  md: '92vh' }}
          width={{ sm: '100vw', md: '68vw', lg: '74vw' }}
        >
          <Map
            setCoordinates={setCoordinates}
            setBounds={setBounds}
            coordinates={coordinates}
            places={(imoveis.length <= qtdToGeo) ? ((isFiltering) ? placesfiltered : imoveis) : isFiltering ? placesfiltered : placesInBounds}
            setChildClicked={setChildClicked}
            setActiveMarker={setActiveMarker}
            setOpenModal={setOpenModal}
            activeMarker={activeMarker}
            isAddMap={addMap}
            setNewPosition={setNewPosition}
            setNewPlace={setNewPlace}
            totImoveis={qtdTotImoveis}
            isFiltering={isFiltering}
            showMySnackbar={showMySnackbar}
          />
        </Grid>
      </Grid >
      {/* Mensagens do Sistemas */}
      <MySnackBar open={openSnack} msg={msg} tmp={tmp} severity={severity} setOpenSnack={setOpenSnack} />
      {/* Mostra Filtro */}
      <Drawer
        anchor='left'
        open={openFilter}
        onClose={closeFilter}
      >
        <Grid
          container
          backgroundColor={theme.palette.primary.main}
          p={'5px'}
          sx={{
            overflow: 'auto',
            height: '100%',
            [theme.breakpoints.down('sm')]: {
              width: '100vw',
            },
            [theme.breakpoints.between('sm', 'lg')]: {
              width: '30vw',
            },
            [theme.breakpoints.up('lg')]: {
              width: '25vw',
            }
          }}
        >
          <Paper elevation={5}
          // sx={{ p: '5px' }}
          >
            <Grid container direction={'row'} height={'93%'} >
              <Grid
                container
                direction={'row'}
                px={2}
                maxHeight={'60px'}
                backgroundColor={blue[50]}
                alignItems={'center'}
                justifyContent={'flex-end'}
              >
                <Grid container direction={'row'} alignItems={'center'} justifyContent={'space-between'}
                >
                  <Grid item>
                    Qtd: {placesfiltered.length}
                  </Grid>
                  <Grid item>
                    <RadioGroup
                      row
                      aria-labelledby="demo-form-control-label-placement"
                      name="position"
                      defaultValue='ou'
                    >
                      <FormControlLabel
                        control={<Radio />}
                        checked={isOu === 'e'}
                        onChange={handleIsOu}
                        value='e'
                        name="radio-buttons"
                        label="E"
                        labelPlacement="end" />
                      <FormControlLabel
                        control={<Radio />}
                        checked={isOu === 'ou'}
                        onChange={handleIsOu}
                        value='ou'
                        name="radio-buttonse"
                        label="Ou"
                        labelPlacement="end" />
                    </RadioGroup>
                  </Grid>
                  <Grid item pl={'10px'} >
                    <Button variant="outlined" onClick={filtrarPlaces}>Filtrar</Button>
                  </Grid>
                </Grid>

              </Grid>
              <Grid container overflow={'auto'} height={'78vh'}>
                <Grid container direction={'row'} alignItems={'center'} px={2} justifyContent={'space-between'} >
                  <Grid container direction={'row'} alignItems={'center'}>
                    <TextField
                      sx={{ mb: '10px' }}
                      size='small'
                      variant='standard'
                      fullWidth
                      value={nomeEmp}
                      id="empreendimento"
                      name='empreendimento'
                      label="Empreendimento"
                      onChange={(event) => { setNomeEmp(event.target.value) }}
                      inputProps={{ maxLength: 60 }}
                    />
                    <FormControlLabel
                      control={<Checkbox
                        onChange={() => { setChkRenda(!chkRenda) }}
                        checked={chkRenda}
                        id='fltRenda'
                        name='fltRenda' />}
                      label="Renda"
                      sx={{ width: '70px' }}
                    />
                    <Slider
                      disabled={!chkRenda}
                      marks={true}
                      max={10000}
                      min={1000}
                      size="small"
                      step={500}
                      value={fltRenda}
                      valueLabelDisplay="auto"
                      sx={{ width: '60%' }}
                      onChange={handleRenda}
                    />
                  </Grid>
                  <Grid container direction={'row'} alignItems={'center'}>
                    <FormControlLabel
                      control={<Checkbox
                        onChange={() => { setChkVlrInv(!chkVlrInv) }}
                        id='fltVlrImovel'
                        name='flrVlrImovel'
                        checked={chkVlrInv}
                      />}
                      label="Invest.:"
                      sx={{ width: '75px' }}
                    />

                    <Slider
                      disabled={!chkVlrInv}
                      marks={true}
                      max={1000000}
                      min={100000}
                      value={fltVlrInv}
                      size="small"
                      step={10000}
                      // getAriaValueText='auto'
                      valueLabelDisplay="auto"
                      sx={{ width: '60%' }}
                      onChange={handleVlrInv}
                    />
                  </Grid>
                </Grid>
                <Grid container width={'100%'} justifyItems={'center'} >
                  <Grid container px={2} width={'50%'} justifyItems={'center'}  >
                    {/* <FormGroup> */}
                    <FormControlLabel sx={{ width: '100%' }} control={<Checkbox onChange={handleInput} checked={academia} id='academia' name='academia' />} label="Academia" />
                    <FormControlLabel sx={{ width: '100%' }} control={<Checkbox onChange={handleInput} checked={churrasqueira} id='churrasqueira' name='churrasqueira' />} label="Churrasqueira Apto" />
                    <FormControlLabel sx={{ width: '100%' }} control={<Checkbox onChange={handleInput} checked={cinema} id='cinema' name='cinema' />} label="Cinema" />
                    <FormControlLabel sx={{ width: '100%' }} control={<Checkbox onChange={handleInput} checked={coworking} id='coworking' name='coworking' />} label="Coworking" />
                    <FormControlLabel sx={{ width: '100%' }} control={<Checkbox onChange={handleInput} checked={elevador} id='elevador' name='elevador' />} label="Elevador" />
                    <FormControlLabel sx={{ width: '100%' }} control={<Checkbox onChange={handleInput} checked={gourmet} id='gourmet' name='gourmet' />} label="Espaço Gourmet" />
                    <FormControlLabel sx={{ width: '100%' }} control={<Checkbox onChange={handleInput} checked={espacokids} id='espacokids' name='espacokids' />} label="Espaço Kids" />
                    <FormControlLabel sx={{ width: '100%' }} control={<Checkbox onChange={handleInput} checked={esportebar} id='esportebar' name='esportebar' />} label="Esporte Bar" />
                    <FormControlLabel sx={{ width: '100%' }} control={<Checkbox onChange={handleInput} checked={gardem} id='gardem' name='gardem' />} label="Garden" />
                    <FormControlLabel sx={{ width: '100%' }} control={<Checkbox onChange={handleInput} checked={lavanderia} id='lavanderia' name='lavanderia' />} label="Lavanderia" />
                    <FormControlLabel sx={{ width: '100%' }} control={<Checkbox onChange={handleInput} checked={marketplace} id='marketplace' name='marketplace' />} label="Market Place" />
                    <FormControlLabel sx={{ width: '100%' }} control={<Checkbox onChange={handleInput} checked={petcare} id='petcare' name='petcare' />} label="Pet Care" />
                    <FormControlLabel sx={{ width: '100%' }} control={<Checkbox onChange={handleInput} checked={petplace} id='petplace' name='petplace' />} label="Pet Place" />
                    <FormControlLabel sx={{ width: '100%' }} control={<Checkbox onChange={handleInput} checked={piscina} id='piscina' name='piscina' />} label="Piscina" />
                  </Grid>
                  <Grid container px={0} width={'50%'} justifyItems={'center'}  >
                    <FormControlLabel sx={{ width: '100%' }} control={<Checkbox onChange={handleInput} checked={playgroud} id='playgroud' name='playgroud' />} label="Playgroud" />
                    <FormControlLabel sx={{ width: '100%' }} control={<Checkbox onChange={handleInput} checked={qdtenis} id='qdtenis' name='qdtenis' />} label="Quadra de Tênis" />
                    <FormControlLabel sx={{ width: '100%' }} control={<Checkbox onChange={handleInput} checked={qdvolei} id='qdvolei' name='qdvolei' />} label="Quadra de Vôlei" />
                    <FormControlLabel sx={{ width: '100%' }} control={<Checkbox onChange={handleInput} checked={qdesportiva} id='qdesportiva' name='qdesportiva' />} label="Quadra Esportiva" />
                    <FormControlLabel sx={{ width: '100%' }} control={<Checkbox onChange={handleInput} checked={quiosquechur} id='quiosquechur' name='quiosquechur' />} label="Quiosque c/ Churrasqueira" />
                    <FormControlLabel sx={{ width: '100%' }} control={<Checkbox onChange={handleInput} checked={rooftop} id='rooftop' name='rooftop' />} label="Rooftop" />
                    <FormControlLabel sx={{ width: '100%' }} control={<Checkbox onChange={handleInput} checked={sacada} id='sacada' name='sacada' />} label="Sacada" />
                    <FormControlLabel sx={{ width: '100%' }} control={<Checkbox onChange={handleInput} checked={salajogos} id='salajogos' name='salajogos' />} label="Sala de Jogos" />
                    <FormControlLabel sx={{ width: '100%' }} control={<Checkbox onChange={handleInput} checked={salamult} id='salamult' name='salamult' />} label="Sala Multiuso" />
                    <FormControlLabel sx={{ width: '100%' }} control={<Checkbox onChange={handleInput} checked={salafesta} id='salafesta' name='salafesta' />} label="Salão de Festa" />
                    <FormControlLabel sx={{ width: '100%' }} control={<Checkbox onChange={handleInput} checked={sauna} id='sauna' name='sauna' />} label="Sauna" />
                    <FormControlLabel sx={{ width: '100%' }} control={<Checkbox onChange={handleInput} checked={vagafixa} id='vagafixa' name='vagafixa' />} label="Vaga Fixa" />
                    <FormControlLabel sx={{ width: '100%' }} control={<Checkbox onChange={handleInput} checked={vagarotativa} id='vagarotativa' name='vagarotativa' />} label="Vaga Rotativa" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent={'space-between'} width={'100%'} alignItems={'center'} px={1}>
              <Grid item>
                Qtd selecionada: ({placesfiltered.length})
              </Grid>
              <Button variant="outlined" onClick={() => setOpenFilter(false)} >Fechar</Button>
            </Grid>
          </Paper>
        </Grid>
      </Drawer>
    </Grid>
  )

}