import { Button, Container, Grid, IconButton, Typography } from '@mui/material';
import { TextFieldMask } from '../../../utils/TextfieldMask/TextfieldMask';
import { listarCampanhas } from '../../../controllers/crm/campanhaController';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import CampanhaDetails from './CampanhaDetails';
import { green } from '@mui/material/colors';
import ReplayTwoToneIcon from '@mui/icons-material/ReplayTwoTone';
import AddIcon from '@mui/icons-material/Add';

export function CampanhaSelect({ user, setSnackbar, setLoading }) {

  // console.log('user', user)
  const [filteredCampanhas, setFilteredCampanhas] = useState([])
  const [campanhas, setCampanhas] = useState([])
  const [select, setSelect] = useState({})
  const [filter, setFilter] = useState('')
  const [showCampanhaDetails, setShowCampanhaDetails] = useState(false)

  async function loadMyCampanhas() {
    setLoading(true)
    const params = { conta: user.conta, id: user.id }
    const result = await listarCampanhas(params)
    if (result) {
      setCampanhas(result.data)
      setFilteredCampanhas(result.data)
    }
    else {
      setSnackbar({ open: true, message: 'Erro ao carregar campanhas', severity: 'error' })
    }
    setLoading(false)
  }

  // useEffect(() => {
  //   setFilter(select.nome)
  // }, [select])

  useEffect(() => {
    loadMyCampanhas()
  }, [])

  useEffect(() => {
    setFilteredCampanhas(campanhas)
  }, [campanhas])

  // função para filtrar as campanhas
  useEffect(() => {
    if (filter === '') {
      setFilteredCampanhas(campanhas)
    } else {
      const result = campanhas.filter(campanha => campanha.nome.toLowerCase().includes(filter.toLowerCase()))
      setFilteredCampanhas(result)
    }
  }, [filter])

  function insertCampanha() {
    const campanha = {
      idconta: user.conta,
      idoperador: user.id,
      idusuario: user.id,
      origem: '',
      nome: '',
      qtd: 0,
    }
    setSelect(campanha)
    setShowCampanhaDetails(!showCampanhaDetails)
  }

  return (
    <Container>
      <Grid
        container
        bgcolor={'#f5f5f5'}
        height={'85vh'}
      >
        <Grid item xs={12} mx={2} textAlign={'center'} p={2} >
          <Typography variant='h2'>Cadastro de Campanha</Typography>
        </Grid>
        <Grid container mx={2} >
          <Grid item xs={9} >
            <TextFieldMask
              fullWidth
              variant='standard'
              size='small'
              id="campanha"
              name='campanha'
              label="Campanha"
              value={filter ? filter : ''}
              onChange={(e) => setFilter(e.target.value)}
              mask={'text'}
            />
          </Grid>
          <Grid item xs={3} textAlign={'center'} display={{ xs: 'none', sm: 'block' }} >
            <Button variant='contained' onClick={insertCampanha}>Nova</Button>
            <Button sx={{ mx: 1, height: '40px' }} variant="contained" onClick={loadMyCampanhas}><ReplayTwoToneIcon /></Button>
          </Grid>
          <Grid item xs={3} textAlign={'center'} display={{ xs: 'block', sm: 'none' }} >
            <IconButton aria-label="delete" onClick={insertCampanha}><AddIcon fontSize='medium' /></IconButton>
            <IconButton aria-label="delete" onClick={loadMyCampanhas}><ReplayTwoToneIcon fontSize='medium' /></IconButton>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          mx={2}
          height={'60vh'}
          overflow={'auto'}
        >
          {filteredCampanhas.map((campanha) => (
            <Grid container
              bgcolor={'white'}
              mb={1}
              p={1}
              border={'1px solid #ccc'}
              borderRadius={0}
              key={campanha.idcampanha}
              onClick={() => { setSelect(campanha); setShowCampanhaDetails(!showCampanhaDetails) }}
              sx={{
                cursor: 'pointer',
                "&:hover": {
                  bgcolor: green[300],
                },
              }}
            >
              <Grid container>
                <Grid container>
                  <Typography>{`Campanha: ${campanha.nome}`}</Typography>
                </Grid>
                <Grid container direction={'row'} gap={2} >
                  <Typography>{`Inicio: ${dayjs(campanha.dtinicio).format('DD/MM/YYYY')}`}</Typography>
                  <Typography>{`Fim: ${dayjs(campanha.dtfim).format('DD/MM/YYYY')}`}</Typography>
                  <Typography>{`Leads: ${campanha.qtd}`}</Typography>
                </Grid>
                <Grid item overflow={'auto'}>
                  <Typography >{`${process.env.REACT_APP_LEADS_URL}/${campanha.link}`}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      {showCampanhaDetails && (
        <CampanhaDetails
          showCampanhaDetails={showCampanhaDetails}
          setShowCampanhaDetails={setShowCampanhaDetails}
          user={user}
          campanha={select}
          campanhas={campanhas}
          setCampanhas={setCampanhas}
          setSnackbar={setSnackbar}
        />
      )}
    </Container>
  )
}