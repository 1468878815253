import { Alert, Box, Button, Checkbox, CircularProgress, Container, Grid, Snackbar, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { createLead } from '../../controllers/crm/leadController';
import { yellow } from '@mui/material/colors';
import { TextFieldMask } from '../../utils/TextfieldMask/TextfieldMask';
import { Copyright } from '../Copyright';
import { Termos } from '../termos/termos';
import { leadProcessoStatus } from '../../constants/crm';
// import { setAlerta } from '../../controllers/messageriaController';



export default function Leads() {
  let params = useParams();
  // let id1 = params.id1;
  // let id2 = params.id2;
  // let id3 = params.id3;
  // let origem = params.id4;
  let processo = leadProcessoStatus.Automatico

  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [interesse, setInteresse] = useState('');
  const [termos, setTermos] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [wasSend, setWasSend] = useState(false);
  const [hasWhatsapp, setHasWhatsapp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState('');


  function handleChangeTermo(event) {
    setTermos(event.target.checked);
  }

  function handleClose(event, reason) {
    setHasError(false);
  }

  function validarForm() {
    if (nome === '' || telefone === '') {
      setMsgError('Preencha os campos Nome e Telefone!');
      setHasError(true);
      return false;
    }
    if (!termos) {
      setMsgError('Aceite os termos para prosseguir!');
      setHasError(true);
      return false;
    }
    return true;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    if (!validarForm()) {
      return;
    }
    let data = {
      id1: params.id1,
      id2: params.id2,
      id3: params.id3,
      id4: params.id4,
      nome: nome,
      telefone: telefone,
      email: email,
      interesse: interesse,
      processo: processo,
      whatsapp: hasWhatsapp
    };
    setLoading(true);
    const result = await createLead(data);
    // console.log(result);
    if (result.status === 200) {
      setWasSend(true);
      setDisabled(true);
      setLoading(false);
      // setAlerta(data.id2, { id: data.id2, data: new Date() })
    } else if (result.status === 223) {
      setLoading(false);
      setMsgError('Erro de chaves inválidas!');
      setHasError(true);
      setDisabled(false);
    } else if (result.status === 250) {
      setLoading(false);
      setWasSend(true);
      setDisabled(true);
    }
    else {
      setLoading(false);
      setMsgError('Erro ao enviar os dados!');
      setHasError(true);
    }
  }


  return (
    <Container>
      {(loading) && ((
        <div style={{ position: 'fixed', top: '50vh', left: '50vw' }}>
          <CircularProgress />
        </div>
      ))}
      <Grid container
        bgcolor={'#f5f5f5'}
        // height={'100vh'}
        justifyContent={'center'}
      >
        <Grid item
          // bgcolor={'#fff'}
          px={2}
        >
          <h3>🏠 Bem-vindo ao nosso mundo de Imóveis!</h3>
          <h4>Você está prestes a dar um passo importante na realização dos seus sonhos: encontrar o lar perfeito para você e sua família.</h4>
          <p>Nossos Corretores de Imóvel, irão tornar essa jornada o mais tranquila e eficiente possível.</p>

          <h4>Como podemos prosseguir?</h4>
          <p>Para que possamos começar a busca pelo seu novo lar, pedimos que preencha os campos abaixo:</p>
          <Box
            bgcolor={'#f5f5f5'}
            px={1}
            component={'form'}
            noValidate
            autoComplete='false'
            maxWidth={'sm'}
          >
            <TextField
              id="nome"
              label="Nome"
              variant='standard'
              fullWidth
              onChange={(event) => setNome(event.target.value)}
              disabled={disabled}
            />
            <Box component={'div'} marginTop={'10px'} alignContent='center'>
              <TextFieldMask
                // sx={{ marginTop: 1 }}
                // fullWidth
                placeholder={'(99) 99999-9999'}
                variant='standard'
                size='small'
                id="telefone"
                name='telefone'
                label="Telefone"
                value={telefone}
                onChange={(event) => setTelefone(event.target.value)}
                // helperText="Telefone para contato"
                mask={'phone'}
                disabled={disabled}
              />

              <Checkbox
                required
                checked={hasWhatsapp}
                onChange={() => setHasWhatsapp(!hasWhatsapp)}
                disabled={disabled}
              />Whatsapp</Box>
            <TextFieldMask
              sx={{ marginTop: 1 }}
              fullWidth
              // placeholder={'(99) 99999-9999'}
              variant='standard'
              size='small'
              id="email"
              name='email'
              label="Email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              // helperText="Email para contato"
              mask={'email'}
              disabled={disabled}
            />

            <TextField
              style={{ marginTop: 10, marginBottom: 10 }}
              placeholder={'ex: apartamento em bairro X na cidade Y, com 2 quartos e garagem, com parcelas de até R$ $$$ e entrada de aproximadamente R$ $$$$'}
              id="interesse"
              label="Tipos de Imóveis de Interesse"
              variant='standard'
              fullWidth
              multiline
              rows={4}
              onChange={(event) => setInteresse(event.target.value)}
              disabled={disabled}
            />

            <Termos disabled={disabled} handleAgree={handleChangeTermo} />
            <Button
              type='submit'
              variant='contained'
              color='primary'
              fullWidth
              disabled={!termos || disabled}
              style={{ marginTop: 10 }}
              onClick={handleSubmit}
            >
              <span style={{ fontSize: '12px' }}> Enviar Dados para o Corretor de Imóveis</span>
            </Button>
          </Box>
          {wasSend && (
            <div style={{ textAlign: 'center', backgroundColor: yellow[100], padding: 1, marginTop: '20px' }}>
              <p><b>Agradecemos por escolher nossa equipe!</b></p>
              <p><b>Em breve, um de nossos corretores entrará em contato com você para darmos início à busca pelo imóvel perfeito. 🏠✨</b></p>
            </div>
          )}
          {!wasSend && <p>Assim que recebermos essas informações, entraremos em contato. Juntos, vamos encontrar o imóvel perfeito para você!</p>}

          <h4>O que oferecemos?</h4>
          <p><b>Experiência e Conhecimento:</b> Com anos de atuação no mercado imobiliário, conhecemos cada detalhe desse setor. Desde apartamentos a casas, terrenos a coberturas, estamos preparado para encontrar o imóvel que se encaixa perfeitamente nas suas necessidades.</p>
          <p><b>Transparência e Honestidade:</b> Acreditamos que a base de qualquer relacionamento é a confiança. Por isso, estamos comprometidos em ser transparente em todas as etapas do processo. Sem letras miúdas, sem surpresas desagradáveis.</p>
          <p><b>Atendimento Personalizado:</b> Você não é apenas mais um cliente para nós. Cada pessoa tem suas particularidades, desejos e orçamento. Vamos ouvir você atentamente para entender suas preferências e encontrar opções que realmente façam sentido para o seu estilo de vida.</p>
          <h4>Conte conosco para tornar essa jornada emocionante e sem complicações. Estamos ansioso para trabalhar ao seu lado. 😊</h4>
          <Box component={'div'} bgcolor={'#f5f5f5'} p={0} textAlign={'center'}>
            <Copyright />
          </Box>
          <Snackbar
            open={hasError}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          // sx={{ width: '90%' }}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              variant="filled"
            >
              {msgError}
            </Alert>
          </Snackbar>
        </Grid>
      </Grid>
    </Container>
  );
}