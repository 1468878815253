import dayjs from 'dayjs'
import { taskStatus } from '../../constants/crm'
import { apiGate } from '../../services/api_gate'


export async function setLeadTask(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do setLeadTask') }
  const api = await apiGate.post('/crm/lead/task/save', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}

export async function getLeadTasks(dados) {
  try {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do getLeadTasks') }
    const api = await apiGate.post('/crm/lead/task/list', dados)
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api)
    return api
  }
  catch (error) {
    console.log('error', error)
    throw error
    // return error
  }
}

export async function deleteLeadTask(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do deleteLeadTask') }
  const api = await apiGate.post('/crm/lead/task/delete', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}
