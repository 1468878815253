import { Alert, AppBar, Box, Button, CircularProgress, Container, CssBaseline, Divider, Drawer, FormControl, Grid, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemText, MenuItem, Select, Toolbar, Typography } from '@mui/material';
import { estatistica, liberarLead, listFasesEtapas, listLeadsDistribuidos } from '../../../controllers/crm/leadController';
import { useEffect, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { blueGrey, deepOrange, green, grey, orange, red, yellow } from '@mui/material/colors';

import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import AssignmentReturnedTwoToneIcon from '@mui/icons-material/AssignmentReturnedTwoTone';
import SlideshowTwoToneIcon from '@mui/icons-material/SlideshowTwoTone';
import UpdateTwoToneIcon from '@mui/icons-material/UpdateTwoTone';

import { diffDate } from '../../../utils/crm/getDiffDate';
import AlertDialogGate from '../../AlertDialog/AlertDialogGate';
import ContactPhoneTwoToneIcon from '@mui/icons-material/ContactPhoneTwoTone';
import PhoneTwoToneIcon from '@mui/icons-material/PhoneTwoTone';
import dayjs, { Dayjs } from 'dayjs';
import { gFases, gFasesetapas } from '../../../constants/fases';
import { set } from 'firebase/database';
import { getProcessFasesDescription, processFase, processFases } from '../../../constants/crm';
import { getColorFaseByOrdem } from '../../../utils/crm/getColors';
import { Form } from 'react-router-dom';



export default function LeadsControle({ idconta, idusuario, leadsAll, setLeadsAll, update, setUpdate,
  setAtivarTmp, ativarTmp, temporizador, setTemporizador, loadAllLeads }) {




  const corVerde = green[200]
  const corVermelho = red[200]
  const corAmarelo = yellow[400]
  const corAguardando = red[400]
  const corContatoInicial = red[100]
  const corInformativo = red[200]
  const corAquisitivo = red[300]
  const corFechamento = green[400]
  const corFundo = grey[200]
  const corFundo2 = grey[400]

  // Constante de Estado

  // const [leads, setLeads] = useState([])
  const [lead, setLead] = useState(null)
  const [disabled, setDisabled] = useState(false)
  const [msgDist, setMsgDist] = useState(null)
  const [faseEtapas, setFaseEtapas] = useState([])
  const [fases, setFases] = useState(null)
  const [etapa, setEtapa] = useState(null)
  const [fase, setFase] = useState(null)
  const [etapas, setEtapas] = useState(null)
  const [showEtapa, setShowEtapa] = useState(false)

  const [open, setOpen] = useState(false)
  const [response, setResponse] = useState(false)
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')



  async function openDialog(lead) {
    setLead(lead)
    setOpen(true)
  }

  useEffect(() => {
    if (response) {
      disponibilizarLead(lead)
    }
  }, [response])





  const [leadsOk, setLeadsOk] = useState([])
  const [leadsNok, setLeadsNok] = useState([])
  const [leadsWait, setLeadsWait] = useState([])

  const prazo_horas_distribuicao = 4
  const prazo_minutos_redistribuicao = 1

  const prazo_dias_movimento = 5
  const prazo_minutos_movimento = 2

  const prazo_minutos_contato = 10


  async function organizarLeads(_leads) {
    let redistribuicao_Automatica = true
    let waitLead = []
    let okLead = []
    let nokLead = []
    // let diff = null
    _leads.map((_lead) => {
      if (_lead.ordem_f === 1 && _lead.ordem_e === 1) { // Aguardando Designação
        waitLead.push(_lead)
      } else if (_lead.ordem_f === 1 && _lead.ordem_e === 2) { // Aguardando Contato
        if (dayjs().diff(_lead.dtdesignacao, 'minute', true) < prazo_minutos_contato) { okLead.push(_lead) }
        else { nokLead.push(_lead) } // Abandono por falta de contato inicial
      } else { // Em Atendimento
        if (dayjs().diff(_lead.updatedat, 'day', true) < prazo_dias_movimento) { okLead.push(_lead) }
        else { nokLead.push(_lead) } // Abandono por falta de movimentação
      }
      setLeadsWait(waitLead)
      setLeadsOk(okLead)
      setLeadsNok(nokLead)
    })
    if (redistribuicao_Automatica) {
      if (nokLead.length > 0) {
        // console.log('Redistribuindo Leads NOK', nokLead)
        await disponibilizarLead(nokLead)

      }
    }
    setDados(estatistica(_leads))
  }

  async function disponibilizarLead(_nokLeads) {
    // console.log('Disponibilizando Lead', _nokLeads)
    setDisabled(true)
    try {
      setAtivarTmp(false)
      // clearInterval(temporizador)
      const api = await liberarLead(_nokLeads)
      // Excluir os leads que foram redistribuidos
      let leads = leadsAll.filter((_lead) => {
        let _index = _nokLeads.findIndex((nok) => nok.idlead === _lead.idlead)
        if (_index === -1) return _lead
      })
      setLeadsAll(leads)
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Retorno da API', api)
      if (api.status === 200) {
        setUpdate(true)
      }
      else if (api.status === 210) { // Retorno de acesso concorrente
        console.log('Acesso concorrente', api.data)
        setUpdate(true)
      }
    } catch (e) {
      console.log('Erro ao disponibilizar lead', e)
    }
    finally {
      setDisabled(false)
      setAtivarTmp(true)
    }
  }


  useEffect(() => {
    if (leadsAll) organizarLeads(leadsAll)
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Leads All', leadsAll)
  }, [leadsAll])

  useEffect(() => {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('ativarTmp', ativarTmp)
    if (ativarTmp) {
      // organizarLeads a cadas 10 segundos
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Ativando Temporizador')
      setTemporizador(setInterval(() => {
        organizarLeads(leadsAll)
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('tmp...' + temporizador, dayjs().format('HH:mm:ss'))
      }, 10000))
    }
    else {
      // console.log('Desativando Temporizador')
      clearInterval(temporizador)
    }
  }, [ativarTmp])

  const [dados, setDados] = useState(null)

  useEffect(() => {
    leadsAll && setDados(estatistica(leadsAll))
    leadsAll && setAtivarTmp(true)
  }, [])

  return (
    <Container>

      <Grid container
        height={'85vh'}
        overflow={'auto'}
        direction="row"
        alignContent="flex-start"
        display={'flex'}
      >
        <Grid container  >
          <Grid item xs={12}>
            {(disabled) && ((
              <div style={{ position: 'fixed', top: '50vh', left: '50vw' }}>
                <CircularProgress />
              </div>
            ))}
          </Grid>
        </Grid>
        <Grid container
          gap={1}
          p={1}
        >
          <Grid
            container
            mt={1}
          >
            {/* Prazos e Dados */}
            <Grid
              container
              direction={'row'}
              gap={1}
              justifyContent={'space-between'}
              bgcolor={corFundo2}
            >
              {/* Prazos */}
              <Grid item
                xs={12}
                sm={3}
                md={2}
                minWidth={'250px'}
              >
                <Grid
                  container
                  bgcolor={'white'}
                  direction={'row'}
                  height={'150px'}
                  alignContent={'flex-start'}
                >
                  <Grid
                    container
                    p={1}
                    justifyContent={'center'}
                    bgcolor={red[200]}
                  >
                    <Typography variant="body1" fontSize={'12px'} color={'black'} >
                      Prazos
                    </Typography>
                  </Grid>
                  <Grid item p={1} xs={12}>
                    <Typography variant="body1" fontSize={'12px'} color={'black'} >
                      Distribuição: {prazo_horas_distribuicao} horas
                    </Typography>
                  </Grid>
                  <Grid item p={1} xs={12}>
                    <Typography variant="body1" fontSize={'12px'} color={'black'} >
                      Contato Inicial: {prazo_minutos_contato} minutos
                    </Typography>
                  </Grid>
                  <Grid item p={1} xs={12}>
                    <Typography variant="body1" fontSize={'12px'} color={'black'} >
                      Movimentação Dias: {prazo_dias_movimento} dias
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* Dados */}
              {leadsAll && leadsAll.length > 0 && (
                <Grid item
                  xs={12}
                  sm={3}
                  md={2}
                  minWidth={'250px'}
                >
                  <Grid
                    container
                    direction={'column'}
                    p={0}
                    bgcolor={'White'}
                    height={'200px'}
                  >
                    <Grid
                      item
                      p={1}
                      textAlign={'center'}
                      bgcolor={red[200]}
                      mb={1}
                    >
                      <Typography variant="body1" fontSize={'12px'} color={'black'} >
                        {dados?.total} Leads
                      </Typography>
                    </Grid>
                    {dados && dados.map((p, i) => (
                      <div key={i} style={{
                        flex: 1,
                        width: `${(p.total / dados.total * 100).toFixed(2)}%`,
                        background: getColorFaseByOrdem(p.ordem)
                      }}>
                        <Grid container
                          width={'250px'}
                          py={.3}
                          px={1}
                          direction={'row'}
                        >
                          <Typography variant="body1" component="div" fontSize={'12px'} pr={1}>
                            {`${p.fase}:`}
                          </Typography>
                          <Typography variant="body1" component="div" fontSize={'12px'} pr={.5} color={red[500]}>
                            {`${(p.total / dados.total * 100).toFixed(2)}% `}
                          </Typography>
                          <Typography variant="body1" component="div" fontSize={'12px'}>
                            {`(${p.total}) `}
                          </Typography>
                        </Grid>
                      </div>
                    ))}
                  </Grid>
                </Grid>
              )}
              {/* Operadores */}
              <Grid item
                xs={12}
                sm={3}
                md={2}
                minWidth={'250px'}
              >
                <Grid
                  container
                  direction={'column'}
                  p={0}
                  bgcolor={'White'}
                  height={'200px'}
                >
                  <Grid item p={1}
                    textAlign={'center'}
                    bgcolor={red[200]}
                    mb={1}
                  >
                    <Typography variant="body1" fontSize={'12px'} color={'black'} >
                      {dados?.oper.length} Operadores
                    </Typography>
                  </Grid>
                  {dados?.oper && dados?.oper.map((p, i) => (
                    <div key={i} style={{
                      width: `${(p.qtd / dados.total * 100).toFixed(2)}%`,
                      background: yellow[300]
                    }}>
                      <Grid container
                        width={'250px'}
                        py={.3}
                        px={1}
                        direction={'row'}
                      >
                        <Typography variant="body1" component="div" fontSize={'12px'} pr={1}>
                          {`${p.nome ? p.nome : 'Aguardando...'} : ${p.qtd} (${(p.qtd / dados.total * 100).toFixed(2)}%)`}
                        </Typography>
                      </Grid>
                    </div>
                  ))}
                </Grid>
              </Grid>
            </Grid>

          </Grid>
          {/* Leads */}
          {leadsAll && leadsAll.length > 0 && (
            <Grid item xs={12}>
              <Grid container
                gap={1}
                direction={'row'}
                overflow={'auto'}
                justifyContent={'flex-start'}
                p={1}
                maxHeight={'57vh'}
                bgcolor={corFundo2}
              >
                {/* Leads Esperando Designação - LED */}
                {false && (
                  <Grid container>
                    <Grid item xs={12} bgcolor={'white'} mb={1}>
                      <Box p={1}>Leads Esperando Designação - LED ({leadsWait.length})</Box>
                    </Grid>
                    {
                      leadsWait.map((_lead, i) => (
                        <Grid item key={i} >
                          <Box key={i}
                            maxWidth={'250px'}
                            textAlign={'left'}
                            bgcolor={getColorFaseByOrdem(leadsWait.ordem_f)}
                            p={1}
                            sx={{ borderStyle: 'inset' }}
                          >
                            {/* <Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'} >Nr:{i + 1}</Typography> */}
                            <Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'}>{new Date(_lead.createdat).toLocaleDateString() + ' ' + new Date(_lead.createdat).toLocaleTimeString().substring(0, 5)}</Typography>
                            {/* <Typography variant='body1' fontSize={'12px'}>Nome:</Typography> */}

                            {_lead.nome && (
                              <>
                                < Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'}>{_lead.nome.substring(0, 18) + '...'}</Typography>
                                <Grid container >
                                  <PhoneTwoToneIcon fontSize='small' />
                                  <Typography variant='body1' sx={{ fontWeight: '900', px: '5px' }} fontSize={'12px'}>{_lead.telefone}</Typography>
                                </Grid>
                              </>
                            )}

                            {/* <Typography variant='body1' fontSize={'12px'}>Email:</Typography>
                      <Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'}>{_lead.email}</Typography> */}
                            {/* <Typography variant='body1' fontSize={'12px'}>Interesse:</Typography>
                    <Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'}>{lead.interesse}</Typography> */}
                            {/* <Typography variant='body1' fontSize={'12px'}>Origem:</Typography> */}
                            <Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'}>{_lead.origem}</Typography>
                            {/* <Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'}>{'TT) '+diffDate(lead.createdat,new Date().toISOString())}</Typography>
                    <Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'}>{'TD) '+diffDate(lead.dtdesignacao,new Date().toISOString())}</Typography> */}
                            {<Grid container>
                              <AssignmentReturnedTwoToneIcon fontSize='small' />
                              <Typography variant='body1' sx={{ fontWeight: '900', px: '5px' }} fontSize={'12px'}>
                                {diffDate(_lead.createdat, new Date().toISOString()).diff}
                              </Typography>
                            </Grid>}
                            <Grid container >
                              <UpdateTwoToneIcon fontSize='small' />
                              <Typography variant='body1' sx={{ fontWeight: '900', px: '5px' }} fontSize={'12px'}>
                                {dayjs(dayjs()).diff(_lead.createdat, 'h')} hs
                              </Typography>
                            </Grid>
                          </Box>
                        </Grid>
                      ))
                    }
                  </Grid>
                )}
                {/* Leads em Situação de Abandono - LSA */}
                {false && (
                  <Grid container
                  // justifyContent={'center'} 
                  >
                    <Grid item xs={12}
                      bgcolor={'white'}
                      mb={1}>
                      <Box p={1}>Leads em Situação de Abandono - LSA ({leadsNok.length})</Box>
                    </Grid>
                    {
                      leadsNok.map((_leadNok, b) => (
                        <Grid item key={b}  >
                          <Box key={b}
                            maxWidth={'250px'}
                            textAlign={'left'}
                            bgcolor={getColorFaseByOrdem(_leadNok.ordem_f)}
                            p={1}
                            sx={{ borderStyle: 'inset' }}
                          >
                            {/* <Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'} >Nr:{i + 1}</Typography> */}
                            <Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'}>{new Date(_leadNok.createdat).toLocaleDateString() + ' ' + new Date(_leadNok.createdat).toLocaleTimeString().substring(0, 5)}</Typography>
                            <Typography variant='body1' fontSize={'12px'}>{`${_leadNok.etapa} `}</Typography>
                            {_leadNok.nome && (
                              <Grid item
                                bgcolor={'yellow'}
                                p={0}>
                                < Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'}>{_leadNok.nome.substring(0, 18) + '...'}</Typography>
                                <Grid container >
                                  <PhoneTwoToneIcon fontSize='small' />
                                  <Typography variant='body1' sx={{ fontWeight: '900', px: '5px' }} fontSize={'12px'}>{_leadNok.telefone}</Typography>
                                </Grid>
                              </Grid>
                            )}

                            {/* <Typography variant='body1' fontSize={'12px'}>Email:</Typography>
                      <Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'}>{_leadNok.email}</Typography> */}
                            {/* <Typography variant='body1' fontSize={'12px'}>Interesse:</Typography>
                    <Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'}>{lead.interesse}</Typography> */}
                            {/* <Typography variant='body1' fontSize={'12px'}>Origem:</Typography> */}
                            <Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'}>{_leadNok.origem}</Typography>
                            {/* <Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'}>{'TT) '+diffDate(lead.createdat,new Date().toISOString())}</Typography>
                    <Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'}>{'TD) '+diffDate(lead.dtdesignacao,new Date().toISOString())}</Typography> */}
                            {<Grid container ><AssignmentReturnedTwoToneIcon fontSize='small' /><Typography variant='body1' sx={{ fontWeight: '900', px: '5px' }} fontSize={'12px'}>{diffDate(_leadNok.createdat, new Date().toISOString()).diff}</Typography></Grid>}
                            {(_leadNok.ordem_f === 1 && _leadNok.ordem_e === 2) && <Grid container ><AssignmentIndTwoToneIcon fontSize='small' /> <Typography variant='body1' sx={{ fontWeight: '900', px: '5px' }} fontSize={'12px'}>{diffDate(_leadNok.dtdesignacao, new Date().toISOString()).diff}</Typography></Grid>}
                            {((_leadNok.ordem_f >= 1 && _leadNok.ordem_e > 2) || (_leadNok.ordem_f > 1)) && <Grid container ><SlideshowTwoToneIcon fontSize='small' /> <Typography variant='body1' sx={{ fontWeight: '900', px: '5px' }} fontSize={'12px'}>{diffDate(_leadNok.updatedat, new Date().toISOString()).diff}</Typography></Grid>}
                            <Grid container >
                              <UpdateTwoToneIcon fontSize='small' />
                              <Typography variant='body1' sx={{ fontWeight: '900', px: '5px' }} fontSize={'12px'}>
                                {dayjs(dayjs()).diff(_leadNok.updatedat, 'm', true).toFixed(2)} min
                              </Typography>
                            </Grid>

                            {/* {!_leadNok.prazo && ( */}
                            <Grid container >
                              <Grid item xs={12} textAlign={'center'} alignContent={'center'} justifyContent={'center'} mt={1}>
                                <Button
                                  key={'b' + b}
                                  variant="contained"
                                  color="error"
                                  size='small'
                                  sx={
                                    {
                                      height: '100%',
                                      width: '100%',
                                      borderRadius: '5',
                                    }
                                  }
                                  onClick={(e) => openDialog(_leadNok)}
                                >
                                  Redistribuir
                                </Button>
                              </Grid>
                            </Grid>
                            {/* )} */}
                          </Box>
                        </Grid>
                      ))
                    }
                  </Grid>
                )}
                {/* Leads em Atendimento - LA */}
                {true && (
                  <Grid container>
                    <Grid item xs={12} bgcolor={'white'} mb={1}>
                      <Box p={1}>Leads em Atendimento - LA ({leadsOk.length})</Box>
                    </Grid>
                    {
                      leadsOk.map((_leadOk, a) => (
                        <Grid item key={a}  >
                          <Box key={a}
                            maxWidth={'250px'}
                            textAlign={'left'}
                            bgcolor={getColorFaseByOrdem(_leadOk.ordem_f)}
                            p={1}
                            sx={{ borderStyle: 'inset' }}
                          >
                            {/* <Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'} >Nr:{i + 1}</Typography> */}
                            <Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'}>{new Date(_leadOk.createdat).toLocaleDateString() + ' ' + new Date(_leadOk.createdat).toLocaleTimeString().substring(0, 5)}</Typography>
                            {/* <Typography variant='body1' fontSize={'12px'}>Nome:</Typography> */}
                            <Typography variant='body1' fontSize={'12px'}>{`${_leadOk.etapa.substring(0, 18) + '...'} `}</Typography>
                            {_leadOk.nome && (
                              <Grid item
                                // bgcolor={green[300]} 
                                p={0}>
                                < Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'}>{_leadOk.nome.substring(0, 18) + '...'}</Typography>
                                <Grid container >
                                  <PhoneTwoToneIcon fontSize='small' />
                                  <Typography variant='body1' sx={{ fontWeight: '900', px: '5px' }} fontSize={'12px'}>{_leadOk.telefone}</Typography>
                                </Grid>
                              </Grid>
                            )}

                            {/* <Typography variant='body1' fontSize={'12px'}>Email:</Typography>
                      <Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'}>{_leadOk.email}</Typography> */}
                            {/* <Typography variant='body1' fontSize={'12px'}>Interesse:</Typography>
                    <Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'}>{_leadOk.interesse}</Typography> */}
                            {/* <Typography variant='body1' fontSize={'12px'}>Origem:</Typography> */}
                            <Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'}>{_leadOk.origem}</Typography>
                            {/* <Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'}>{'TT) '+diffDate(lead.createdat,new Date().toISOString())}</Typography>
                    <Typography variant='body1' sx={{ fontWeight: '900' }} fontSize={'12px'}>{'TD) '+diffDate(lead.dtdesignacao,new Date().toISOString())}</Typography> */}
                            {<Grid container >
                              <AssignmentReturnedTwoToneIcon fontSize='small' />
                              <Typography variant='body1' sx={{ fontWeight: '900', px: '5px' }} fontSize={'12px'}>
                                {diffDate(_leadOk.createdat, new Date().toISOString()).diff}
                              </Typography>
                            </Grid>}
                            {(_leadOk.ordem_f === 1 && _leadOk.ordem_e === 2) &&
                              <>
                                <Grid container ><AssignmentIndTwoToneIcon fontSize='small' />
                                  <Typography variant='body1' sx={{ fontWeight: '900', px: '5px' }} fontSize={'12px'}>
                                    {diffDate(_leadOk.dtdesignacao, new Date().toISOString()).diff}
                                  </Typography>
                                </Grid>
                                <Grid container >
                                  <UpdateTwoToneIcon fontSize='small' />
                                  <Typography variant='body1' sx={{ fontWeight: '900', px: '5px' }} fontSize={'12px'}>
                                    {`Prazo: ${dayjs(_leadOk.updatedat).add(prazo_minutos_contato, 'minute').diff(dayjs(), 'm', true).toFixed(0)} m`}
                                  </Typography>
                                </Grid>
                              </>
                            }
                            {((_leadOk.ordem_f >= 1 && _leadOk.ordem_e > 2) || (_leadOk.ordem_f > 1)) &&
                              <>
                                <Grid container >
                                  <SlideshowTwoToneIcon fontSize='small' />
                                  <Typography variant='body1' sx={{ fontWeight: '900', px: '5px' }} fontSize={'12px'}>
                                    {diffDate(_leadOk.updatedat, new Date().toISOString()).diff}
                                  </Typography>
                                </Grid>
                                <Grid container >
                                  <UpdateTwoToneIcon fontSize='small' />
                                  <Typography variant='body1' sx={{ fontWeight: '900', px: '5px' }} fontSize={'12px'}>
                                    {`Prazo: ${dayjs(_leadOk.updatedat).add(prazo_dias_movimento, 'day').diff(dayjs(), 'd', true).toFixed(0)} d`}
                                  </Typography>
                                </Grid>
                              </>
                            }
                          </Box>
                        </Grid>
                      ))
                    }
                  </Grid>
                )}
              </Grid>
              {/* Confirmação */}
              {open && (
                <AlertDialogGate
                  open={open}
                  setOpen={setOpen}
                  title='CONFIRMAÇÃO DE REDISTRIBUIÇÃO DE LEAD'
                  content='Deseja realmente encaminhar o Lead para a Redistribuição?'
                  value={lead}
                  funcao={disponibilizarLead}
                />
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}