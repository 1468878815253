import { Button, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { useState } from 'react'
import { ModalApp } from '../../components/Modal/ModalApp'


export function Termos({ disabled, handleAgree }) {
  const [openModal, setOpenModal] = useState(false)
  const [tpModal, setTpModal] = useState('T')
  const openModalView = (open, tp) => {
    setOpenModal(open)
    setTpModal(tp)
  }

  const lbPolitica = (
    <Typography variant='body1' fontSize={'12px'} >Eu li e concordei com os&nbsp;
      <Button onClick={() => { openModalView(true, 'T') }}>
        <Typography variant='body1' fontSize={'12px'} style={{ textDecoration: 'underline' }}>
          Termos de Uso
        </Typography>
      </Button>
      &nbsp; e com a&nbsp;
      <Button onClick={() => { openModalView(true, 'P') }}>
        <Typography variant='body1' fontSize={'12px'} style={{ textDecoration: 'underline' }}>
          Política de Privacidade
        </Typography>
      </Button>
    </Typography>
  )

  return (
    <>
      <FormControlLabel
        id='cbTermo'
        name='cbTermo'
        control={<Checkbox />}
        disabled={disabled}
        label={lbPolitica}
        onChange={handleAgree}
      />
      {openModal && (
        <ModalApp
          tpModal={tpModal}
          openModal={openModal}
          setOpenModal={setOpenModal}
          height={'70vh'}
          width={'70vw'}
        />
      )}
    </>
  )
}