import { Button, Grid, Typography } from '@mui/material'
import { useState } from 'react'
import { ModalApp } from '../../components/Modal/ModalApp'
import { red } from '@mui/material/colors'

export function TermosPoliticas(props) {
  const [openModal, setOpenModal] = useState(false)
  const [tpModal, setTpModal] = useState('T')

  const openModalView = (open, tp) => {
    setOpenModal(open)
    setTpModal(tp)
  }

  return (
    <Grid container
      width={'100%'}
      justifyContent={'space-between'}
    >
      <Button onClick={() => { openModalView(true, 'T') }}>
        <Typography variant='body1' fontSize={'8px'}
          // style={{ textDecoration: 'underline' }}
          {...props}>
          Termos de Uso
        </Typography>
      </Button>
      <Button onClick={() => { openModalView(true, 'P') }}>
        <Typography variant='body1' fontSize={'8px'}
          // style={{ textDecoration: 'underline' }} 
          {...props}>
          Política de Privacidade
        </Typography>
      </Button>
      {openModal && (
        <ModalApp
          tpModal={tpModal}
          openModal={openModal}
          setOpenModal={setOpenModal}
          height={'70vh'}
          width={'70vw'}
        />
      )}
    </Grid>
  )
}