import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, Input, InputLabel, MenuItem, Select, TextField, Typography, useTheme } from '@mui/material';
import { taskPriority, taskStatus, taskType } from '../../../constants/crm';
import { useEffect, useState } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { deleteLeadTask, setLeadTask } from '../../../controllers/crm/leadTaskController';
import { gAcoes, gFasesetapas } from '../../../constants/fases';

// Avaliar o uso do dayjs que está sendo importado com erro de locale

import dayjs from 'dayjs';
import 'dayjs/locale/pt-br.js';
dayjs.locale('pt-br')

export default function LeadTaskDetails({
  account, lead, task, taskList, taskListFiltered, filtro, filterTasks,
  setShowTask, showTask, setSnackbar, setUpdate, update,
  setSemana, organizeTasks,
}) {

  const [status, setStatus] = useState(taskStatus.Description[taskStatus.Pendente])
  const [priority, setPriority] = useState(taskPriority.Description[taskPriority.Normal])
  const [type, setType] = useState(taskType.Description[filtro ? filtro : taskType.Tarefa])
  const [description, setDescription] = useState('')
  const [action, setAction] = useState(gFasesetapas[0]?.acao ? gFasesetapas[0].acao : '')
  const [idFase, setIdFase] = useState(searchIdFase(gFasesetapas[0].acao).idfase)
  const [idEtapa, setIdEtapa] = useState(searchIdFase(gFasesetapas[0].acao).idetapa)
  const [showExcluir, setShowExcluir] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [datahora, setDataHora] = useState(dayjs('09:00', 'HH:mm'))
  const [loading, setLoading] = useState(false)


  const theme = useTheme();

  // console.log('lead', account)
  // console.log('hasUpdateTask', setUpdate)
  // const Transition = forwardRef(function Transition(props, ref) {
  //   return <Slide direction="up" ref={ref} {...props} />;
  // });

  // console.log('task', task)
  function handleClose() {
    setShowTask(false)
  }

  function validar() {
    if (type === '' || type === null) {
      setSnackbar({ open: true, message: 'Tipo não informado', severity: 'error' })
      return false
    }
    if (action === '' || action === null) {
      setSnackbar({ open: true, message: 'Ação não informada', severity: 'error' })
      return false
    }
    if (description === '' || description === null) {
      setSnackbar({ open: true, message: 'Descrição não informada', severity: 'error' })
      return false
    }
    return true
  }

  function handleShowExcluir() {
    setShowExcluir(true)
  }

  async function handleDelete() {
    try {
      setLoading(true)
      if (!task.idacao) return
      setDisabled(true)
      const response = await deleteLeadTask({ idacao: task.idacao, version: task.version })
      if (response.status === 200) {
        setUpdate(true)
        taskList.splice(taskList.indexOf(task), 1)
        taskListFiltered?.splice(taskListFiltered.indexOf(task), 1)
        setSnackbar({ open: true, message: response.data.message, severity: 'success' })
        setShowTask(false)
        setDisabled(false)
        if (setSemana) {
          setSemana(organizeTasks())
        }
      }
      else {
        if (response.status === 210) {
          setSnackbar({ open: true, message: 'Erro de acesso concorrente, recarregue novamente o registro.', severity: 'error' })
        }
        else {
          setSnackbar({ open: true, message: 'Erro ao excluir tarefa', severity: 'error' })
        }
        setDisabled(false)
      }
    } catch (error) {
      setSnackbar({ open: true, message: 'Erro ao excluir tarefa', severity: 'error' })
      setDisabled(false)
    }
    finally {
      setShowExcluir(false)
      setLoading(false)
    }
  }

  async function handleSave() {
    try {
      setLoading(true)
      if (!validar()) return
      setDisabled(true)
      const dados = {
        idacao: task.idacao ? task.idacao : null,
        version: task.version ? task.version : null,
        versionLead: lead.version,
        idoperador: account.id,
        idconta: account.conta,
        idlead: lead.idlead,
        idfase: idFase,
        idetapa: idEtapa,
        tipo: type,
        prioridade: priority,
        ord: taskPriority.Ordem[taskPriority[priority]],
        titulo: action,
        descricao: description,
        situacao: status,
        dtacao: dayjs(datahora.format('YYYY-MM-DD HH:mm')).toISOString(),
      }
      // console.log('dados', dados)
      const response = await setLeadTask(dados)
      if (response.status === 200) {
        setLoading(false)
        setDisabled(false)
        setUpdate(true)
        lead.idfase = idFase
        lead.idetapa = idEtapa
        lead.version = response.data.lead.version
        task.idacao = response.data.idacao
        task.prioridade = response.data.prioridade
        task.tipo = response.data.tipo
        task.titulo = response.data.titulo
        task.descricao = response.data.descricao
        task.situacao = response.data.situacao
        task.dtacao = response.data.dtacao
        task.version = response.data.version
        if (dados.idacao === null) {
          taskList.push(task)
          taskListFiltered?.push(task)
        }
        else {
          // localizar o item na lista e atualizar
          if (taskList) taskList[taskList.findIndex((item) => item.idacao === task.idacao)] = task
          if (taskListFiltered) taskListFiltered[taskListFiltered.findIndex((item) => item.idacao === task.idacao)] = task
        }
        if (setSemana) {
          setSemana(organizeTasks())
        }
        setSnackbar({ open: true, message: response.data.message, severity: 'success' })
        filterTasks && filterTasks()
      }
      else {
        if (response.status === 210) {
          setUpdate(true)
          setShowTask(false)
          setSnackbar({ open: true, message: 'Erro de acesso concorrente, recarregue novamente o registro.', severity: 'error' })
        }
        else if (response.status === 400) {
          setSnackbar({ open: true, message: 'Erro ao gravar tarefa', severity: 'error' })
        }
        setDisabled(false)
      }
    }
    catch (error) {
      setSnackbar({ open: true, message: 'Erro ao gravar tarefa', severity: 'error' })
      setDisabled(false)
    }
    finally {
      setLoading(false)
    }
  }

  function searchIdFase(_acao) {
    let id = gFasesetapas.find((item) => {
      if (item.acao === _acao) {
        return item;
      }
      return null; // Add a return statement for cases where the condition is not met.
    });
    return id
  }

  function handleAcao(event, _acao) {
    setAction(_acao)
    setIdFase(searchIdFase(_acao)?.idfase)
    setIdEtapa(searchIdFase(_acao)?.idetapa)
  }

  useEffect(() => {
    if (task.idacao) {
      setStatus(task.situacao)
      setPriority(task.prioridade)
      setType(task.tipo)
      setDescription(task.descricao)
      setAction(task.titulo)
      setDataHora(dayjs(task.dtacao).locale('pt-br'))
      setIdFase(searchIdFase(task.titulo).idfase)
      setIdEtapa(searchIdFase(task.titulo).idetapa)
    }
  }, [task])

  // console.log('lead', lead)

  //  console.log('gFasesetapas', gFasesetapas)
  return (
    <Dialog
      open={showTask}
      onClose={() => { }}
      fullWidth
    >
      <DialogTitle>
        <Grid container p={0} sx={{ backgroundColor: 'white' }} >
          {(disabled) && ((
            <div style={{ position: 'fixed', top: '50vh', left: '50vw' }}>
              <CircularProgress />
            </div>
          ))}
          <Grid container
            bgcolor={theme.palette.primary.main}
            p={1}
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Typography variant="h5" component="h5" color={'white'} >ATIVIDADE</Typography>
            <Button size='small'
              variant='contained'
              disabled={update || disabled ? true : false}
              sx={{ justifyItems: 'end' }}
              onClick={handleClose}
            >Fechar</Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText component={'div'} >
          <Grid container gap={2} justifyContent={'center'}>

            <Grid container
              justifyContent={'center'}
              maxWidth={'500px'}
              p={1}
              border={1}
              direction={'column'}
            >
              <Typography component={'div'} variant="body1">{`Nome: ${lead.nome}`}</Typography>
              <Typography component={'div'} variant="body1">{`Email: ${lead.email}`}</Typography>
              <Typography component={'div'} variant="body1">{`Telefone: ${lead.telefone}`}</Typography>
            </Grid>

            <Grid container
              maxWidth={'500px'}
              p={2}
              gap={0.5}
              direction={'row'}
              border={1}
            >
              <Grid container
                justifyContent={'space-between'}
              >
                <FormControl variant="standard" sx={{ mb: 1, minWidth: 120 }}>
                  <InputLabel id="idLabelType">Tipo</InputLabel>
                  <Select
                    labelId="idLabelType"
                    id="selectType"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    {taskType
                      ? Object.keys(taskType).map((key) => (
                        (key !== 'Description') &&
                        <MenuItem key={key} value={key}>
                          {key}
                        </MenuItem>
                      ))
                      : null
                    }
                  </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ mb: 1, minWidth: 120 }}>
                  <InputLabel id="idLabelPriority">Prioridade</InputLabel>
                  <Select
                    labelId="idLabelPriority"
                    id="selectPriority"
                    value={priority}
                    onChange={(e) => setPriority(e.target.value)}
                  >
                    {taskPriority
                      ? Object.keys(taskPriority).map((key) => (
                        (key !== 'Description' & key !== 'Ordem' & key !== 'Ord') &&
                        <MenuItem key={key} value={key}>
                          {key}
                        </MenuItem>
                      ))
                      : null
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} >
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={gAcoes}
                  autoSelect
                  sx={{ mb: 1 }}
                  value={action}
                  onChange={(event, acao) => handleAcao(event, acao)}
                  renderInput={(params) => <TextField {...params} label="Ação" variant='standard' />}
                />
              </Grid>
              <Grid item xs={12} >
                <Typography component={'div'} variant="body1">Descrição</Typography>
                <Input
                  fullWidth
                  sx={{ mb: 1 }}
                  rows={4}
                  multiline
                  variant='outlined' size='small'
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Grid>
              <Grid container direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}

              >
                <DateTimePicker
                  label="Data e hora da ação"
                  value={datahora}
                  format='DD/MM/YYYY HH:mm'
                  localeText={{ today: 'Hoje', now: 'Agora', am: 'AM', pm: 'PM' }}
                  onChange={(newValue) => setDataHora(newValue)}
                  sx={{ mt: '10px', width: '190px' }}
                />
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="idLabelStatus">Situação</InputLabel>
                  <Select
                    labelId="idLabelStatus"
                    id="selectStatus"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    {taskStatus
                      ? Object.keys(taskStatus).map((key) => (
                        key !== 'Description' &&
                        <MenuItem key={key} value={key}>
                          {key}
                        </MenuItem>
                      ))
                      : null
                    }
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container
              width={'500px'}
              p={2}
              gap={0.5}
              direction={'row'}
              border={1}
              justifyContent={'end'}
            >
              {showExcluir && (
                <>
                  <Typography mr={1} component={'div'} variant="body1" >Confirma a exclusão?</Typography>
                  <Button variant='contained' disabled={disabled} size='small' sx={{ background: 'red' }} onClick={handleDelete} >Sim</Button>
                  <Button variant='contained' disabled={disabled} size='small' onClick={() => setShowExcluir(false)} >Não</Button>
                </>
              )}
              {!showExcluir && (
                <>
                  <Button variant='contained' disabled={disabled} size='small' onClick={handleShowExcluir}>Excluir</Button>
                  <Button variant='contained' disabled={disabled} size='small' onClick={handleSave} >Salvar</Button>
                  <Button variant='contained' disabled={disabled} size='small' onClick={handleClose}>Fechar</Button>
                </>
              )}
            </Grid>
          </Grid>
        </DialogContentText>
        <DialogActions>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
