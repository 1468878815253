import { deleteDoc, doc, setDoc } from 'firebase/firestore';
import { db  } from '../firebase';




// Função para gravar alerta no banco de dados firestore
export async function setAlerta(_id, _alerta) {
  try {
    await setDoc(doc(db, 'alerts', _id), _alerta)
    return true
  }
  catch (e) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Erro em setAlerta', e) }
    return false
  }
}

//Função para excluir alerta no banco de dados firestore
export async function delAlerta(_id) {
  try {
    await deleteDoc(doc(db, 'alerts', _id))
    return true
  }
  catch (e) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Erro em delAlerta', e) }
    return false
  }
}

