import { collection, deleteDoc, query, getDocs, doc, addDoc, getDoc, setDoc, updateDoc, runTransaction, where, serverTimestamp, Query } from 'firebase/firestore';
import { db } from '../firebase';
import { placeConverter } from '../models/placeModel'
import { result } from '../utils/result';

// let id = ''
// let token = null
// let conta = ''

const verifyToken = (user) => {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em veryfyToken', user)
  //token = localStorage.getItem('token')
  if (user != null) {
    //token = JSON.parse(localStorage.getItem('token'))
    // conta = user.conta
    // id = user.id
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('hasToken placeControler', user)
    return true
  }
  else setTimeout(verifyToken, 1000)
}


export async function deletePlace(place, user) {
  if (verifyToken(user)) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em DeletePlace')
    try {
      const placeDocRef = doc(db, 'contas/' + user.conta + '/places/' + place.id)
      await deleteDoc(placeDocRef)
      result.sucess = true
      result.msg = 'Registro excluído com sucesso.'
      result.data = null
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de deletePlace')
    }
    catch (e) {
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro em deletePlace',e)
      result.sucess = false
      result.msg = 'Falha na exclução do registro.'
      result.data = e
    }
    finally
    {
      return result
    }
  }
  else{
    result.sucess=false
    result.msg='Usuário sem privilégio para a operação...'
    result.data=null
    return result
  }
}

export async function loadListExample(user){
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em loadListExample', user)
  if (verifyToken(user)) {
    let list = []
    try{
      result.sucess=true
      result.msg='Imóveis carregados com sucesso'
      const placeRef = doc(db, 'contas/8ryG2pTmV3dq9yoz1QVs/places','4MuW5XR4FAHuCCDPeipO').withConverter(placeConverter)
      const docSnap = await getDoc(placeRef);
      if (docSnap.exists)
      {
        list.push(docSnap.data());
      }
      result.data=list
    }
    catch(e){
      result.sucess=false
      result.msg='Falha na carga dos imóveis'
      result.data = e
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Falha em loadListExample', e)
    }
    finally
    {
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de loadListExample', result)
      return result
    }
  } else verifyToken(user)
}

export async function listAllPlaces(user) {
  if (verifyToken(user)) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em carregar places:', user)
    let list = []
    const placeColRef = collection(db, 'contas/' + user.conta + "/places").withConverter(placeConverter)
    const querySnapshot = await getDocs(placeColRef);
    querySnapshot.forEach((doc) => {
      const place = doc.data()
      place.id = doc.id
      list.push(place);
    });
    return list
  } else verifyToken(user)
}
// Função auxiliar para remover atributos nulos
const removeNullAttributes = (obj) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
};

export async function savePlace(place, user) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em savePlace', place, user)
  if (place.position === undefined) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em falta de position', place.position)
    result.sucess = false
    result.msg = '- Falha por falta de latlng'
    result.data = place
    return result
  }
  let msg = '';
  function validar() {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Objeto de entrada na validação:', place)
    let e = 0;
    if (place.empreendimento === undefined || place.empreendimento === '') { e++; msg += '- Nome' }
    if (place.construtora === undefined || place.construtora === '') { e++; msg += '- Construtora' }
    if (place.endereco === undefined || place.endereco === '') { e++; msg += '- Endereço' }
    if (e > 0) {
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Falha na validação')
      result.sucess = false
      result.msg = msg
      result.data = place
      return false
    }
    else {
      return true
    }
  }

  if (validar()) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em Gravação', place, user)
    try {
      let placeDocRef = undefined
      let docRef = undefined
      if (place.id === undefined) {
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em Insert')
        place.idcad = user.id
        place.createdat = serverTimestamp()
        placeDocRef = collection(db, 'contas/' + user.conta + "/places/")
        const cleanedPlace = removeNullAttributes(place)
        docRef = await addDoc(placeDocRef, cleanedPlace)
        place.id = docRef.id
      }
      else {
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em Update')
        placeDocRef = doc(db, 'contas/' + user.conta + "/places/" + place.id)
        const cleanedPlace = removeNullAttributes(place)
        docRef = await setDoc(placeDocRef, cleanedPlace, { merge: true })
      }
      result.sucess = true
      result.msg = 'Registro gravado com sucesso.'
      result.data = place.id
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de savePlace', result)
    }
    catch (e) {
      result.sucess = false
      result.msg = 'Falha na gravação do registro.'
      result.data = e
    }
    finally {
      return result
    }
  }
  else {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em Saida de Erro por Validação')
    result.sucess = false
    return result
  }
}