import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Fragment  } from 'react';

export default function AlertDialogGate({ open, setOpen, title, content, value, funcao}) {

  console.log(value);
  const handleClose = () => {
    setOpen(false);
  };

  const handleYes = () => {
    funcao(value)
    setOpen(false);
  };

  const handleNo = () => {
    setOpen(false);
  }


  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYes}>Sim</Button>
          <Button onClick={handleNo} autoFocus>Não</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}