import { IconButton, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { getLeadTasks } from '../../../controllers/crm/leadTaskController';
import { taskType } from '../../../constants/crm';
import LeadTaskDetails from './LeadTaskDetails';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { getColorPrioridade } from '../../../utils/crm/getColors';

export function MyReminders({ account, setSnackbar, setShowReminders,
  reminders, setReminders, setUpdate, update, setShowMenu }) {

  const [loading, setLoading] = useState(false);
  const [reminder, setReminder] = useState(null);
  const [showReminder, setShowReminder] = useState(false)

  async function loadReminders() {
    try {
      setLoading(true)
      const query = {}
      const where = {
        idconta: account.conta,
        idoperador: account.id,
        tipo: taskType.Description[taskType.Lembrete],
        isactive: true
      }
      const orderBy = { 'ord': 'desc' }
      query.include = { 'lead': true }
      query.where = where
      query.orderBy = orderBy
      const _reminders = await (await getLeadTasks(query)).data
      setReminders(_reminders)
      setUpdate(false)      
    } catch (error) {
      setSnackbar({ open: true, message: 'Erro ao carregar lembretes...', severity: 'error' })      
      console.error(error)
    }
    finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (reminders.length === 0) loadReminders()
  }, [])

  return (
    <Grid container>
      {(loading) && ((
        <div style={{ position: 'fixed', top: '50vh', left: '50vw' }}>
          <CircularProgress />
        </div>
      ))}
      <Grid container
        gap={2}
        justifyContent='center'
        alignContent={'center'}
        p={2}
        height={'85vh'}
        overflow={'auto'}
      >
        {reminders.filter((r) => r.tipo === taskType.Description[taskType.Lembrete]).map((_reminder, i) =>
        (
          <Grid
            item
            key={i}
          >
            <Card key={i} >
              <CardContent >
                <Typography variant='h4' align='left' mb={1}
                  bgcolor={getColorPrioridade(_reminder.prioridade)}
                  border={1}
                  p={.5}
                >
                  {_reminder.lead.nome}
                </Typography>
                <Grid container
                  height={'160px'}
                  overflow={'auto'}
                  border={1}
                  width={{ xs: '300px', sm: '440px' }}
                >
                  <Grid item
                    textAlign={'start'}
                    height={'120px'}
                    overflow={'auto'}
                  >
                    <Typography variant='body1' align='left' mb={1}
                      p={1}
                    > {_reminder.descricao} </Typography>
                  </Grid>
                  <Grid item xs={12} textAlign={'right'}>
                    <IconButton aria-label="open"
                      sx={{ color: 'blue', mr: 0 }}
                      onClick={() => {
                        setReminder(_reminder)
                        setShowReminder(true)
                      }}>
                      <EditTwoToneIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )
        )}
        {reminder && (
          <LeadTaskDetails
            account={account}
            lead={reminder.lead}
            task={reminder}
            taskList={reminders}
            taskListFiltered={null}
            filtro={null}
            setSnackbar={setSnackbar}
            setUpdate={setUpdate}
            setShowTask={setShowReminder}
            showTask={showReminder}
          />
        )}
      </Grid>
    </Grid>
  )
}