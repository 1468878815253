import { apiGate } from '../../services/api_gate'

export async function setOperador(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do setOperador') }
  const api = await apiGate.post('/crm/operador/create', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}

export async function getOperador(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do getOperador',dados) }
  const api = await apiGate.post('/crm/operador/list', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}

export async function deleteOperador(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do excluirOperador') }
  const api = await apiGate.post('/crm/operador/delete', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}

export async function updateOperador(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do updateOperador') }
  const api = await apiGate.post('/crm/operador/update', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}