import { rules } from './rules'

const planFree = Object.freeze({
  title: 'Free',
  id: 'free',
  rule: rules.ruleAdm
})

const planAdm = Object.freeze({
  title: 'Adm',
  id: 'adm',
  rule: rules.ruleAdm
})

const planUser = Object.freeze({
  title: 'User',
  id: 'use',
  rule: rules.ruleViewer
})

const planBroker = Object.freeze({
  title: 'Broker',
  id: 'bro',
  rule: rules.ruleOperator
})

const planAdvanced = Object.freeze({
  title: 'Advanced',
  id: 'adv',
  rule: rules.ruleAdm
})

const planBusiness = Object.freeze({
  title: 'Business',
  id: 'bus',
  rule: rules.ruleAdm
})

const planCRM = Object.freeze({
  title: 'CRM',
  id: 'crm',
  rule: rules.ruleAdm
})

const tpLicenca = Object.freeze({
  USER: 'USER',
  BROKER: 'BROKER',
  ADVANCED: 'ADVANCED',
  BUSINESS: 'BUSINESS',
  CRM: 'CRM'
})

export const plans = Object.freeze({
  planCRM,
  planAdm,
  planUser,
  planBroker,
  planAdvanced,
  planBusiness,
  planFree,
  tpLicenca
})