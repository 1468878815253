import { apiGate } from '../../services/api_gate'

export async function listarCampanhas(dados) {
    try {
        // console.log('Entrou do listarCampanhas',dados)
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do listarCampanhas') }
        const api = await apiGate.post('/crm/campanha/list', dados)
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
        return api
    }
    catch (e) {
        return null
    }
}

// Função para salvar as campanhas
export async function salvarCampanha(dados) {
    try {
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do salvarCampanha') }
        const api = await apiGate.post('/crm/campanha/save', dados)
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
        return api
    }
    catch (e) {
        return null
    }
}

// função para deletar campanha
export async function deletarCampanha(dados) {
    try {
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do deletarCampanha') }
        const api = await apiGate.post('/crm/campanha/delete', dados)
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
        return api
    }
    catch (e) {
        return null
    }
}