import { forwardRef } from 'react';
import { Fade, Grid, Snackbar, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

export function MySnackBar({ open, msg, tmp, severity, setOpenSnack }) {

  // function SlideTransition(props) {
  //   return <Slide {...props} direction="up" />;
  // }

  // function GrowTransition(props) {
  //   return <Grow {...props} />;
  // }

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        TransitionComponent={Fade}
        open={open} autoHideDuration={tmp} onClose={handleClose}
        // sx={{ width: '50vw' }}
      >
        <Alert onClose={handleClose} severity={severity}  >
          <Grid container 
          justifyContent={'center'} 
          // sx={{width:'40vw'}} 
          >
          <Typography variant='body1'  >
            {msg}
          </Typography>
          </Grid>
        </Alert>
      </Snackbar>
      {/* <Alert severity="error">This is an error message!</Alert>
      <Alert severity="warning">This is a warning message!</Alert>
      <Alert severity="info">This is an information message!</Alert>
      <Alert severity="success">This is a success message!</Alert> */}

    </div>
  );
}