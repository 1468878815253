import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import dayjs from 'dayjs';
import { TextFieldMask } from '../../../utils/TextfieldMask/TextfieldMask';
import { Box, CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { leadOrigemStatus } from '../../../constants/crm';
import { DatePicker } from '@mui/x-date-pickers';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import { deletarCampanha, salvarCampanha } from '../../../controllers/crm/campanhaController';

import 'dayjs/locale/pt-br.js';
dayjs.locale('pt-br');

export default function CampanhaDetails({ user, setShowCampanhaDetails, showCampanhaDetails,
  campanha, campanhas, setCampanhas, setSnackbar }) {

  const handleClose = () => {
    setShowCampanhaDetails(false);
  };
// console.log('campanha', campanha)
  // const [filter, setFilter] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [nome, setNome] = React.useState(campanha.nome)
  const [dtinicio, setDtInicio] = React.useState(campanha.dtinicio ? dayjs(campanha.dtinicio) : "")
  const [dtfim, setDtFim] = React.useState(campanha.dtfim ? dayjs(campanha.dtfim) : "")
  const [origem, setOrigem] = React.useState(campanha.origem)
  const [link, setLink] = React.useState(campanha.link)
  const [showDelete, setShowDelete] = React.useState(false)

  // função para copiar o link para a memória
  function copyToClipboard() {
    navigator.clipboard.writeText(`${process.env.REACT_APP_LEADS_URL}/${link}`)
    setSnackbar({ open: true, message: 'Link copiado para a memória', severity: 'success' })
  }

  function handleChangeOrigem(e) {
    e.preventDefault();
    setOrigem(e.target.value)
    if (link) {
      const linkArray = link.split('/')
      linkArray[linkArray.length - 1] = e.target.value
      setLink(linkArray.join('/'))
    }
  }

  // Função para validar os campos
  function validateCampanha() {
    // console.log('nome', nome)
    if (nome === '' || nome === null || nome === undefined) {
      setSnackbar({ open: true, message: 'Nome da campanha não pode ser vazio', severity: 'error' })
      return false
    }
    if (dtinicio === '') {
      setSnackbar({ open: true, message: 'Data de início não pode ser vazio', severity: 'error' })
      return false
    }
    if (dtfim === '') {
      setSnackbar({ open: true, message: 'Data de fim não pode ser vazio', severity: 'error' })
      return false
    }

    if (origem === '' || origem === null || origem === undefined) {
      setSnackbar({ open: true, message: 'Plataforma não pode ser vazio', severity: 'error' })
      return false
    }
    return true
  }

  // Fução para salvar as alterações
  async function saveCampanha() {
    if (!validateCampanha()) return
    setLoading(true)
    const idcampanha = campanha?.idcampanha ? campanha.idcampanha : null
    const campanhaData = {
      idcampanha: idcampanha,
      nome: nome,
      idoperador: user.id,
      idconta: user.conta,
      dtinicio: dayjs(dtinicio).toISOString(),
      dtfim: dayjs(dtfim).toISOString(),
      origem: origem,
      link: link,
      idusuario: user.id,
      version: campanha?.version ? campanha.version : 0
    }
    // console.log('campanhaData', campanhaData)
    const result = await salvarCampanha(campanhaData)
    // console.log('result', result)
    if (result.status === 200) {
      campanha.idcampanha = result.data.idcampanha
      campanha.nome = result.data.nome
      campanha.dtinicio = result.data.dtinicio
      campanha.dtfim = result.data.dtfim
      campanha.origem = result.data.origem
      campanha.link = result.data.link
      campanha.version = result.data.version
      campanha.updatedat = result.data.updatedat
      if (idcampanha === null) { // Insert
        campanhas.push(campanha)
      }
      setLink(result.data.link)
      setSnackbar({ open: true, message: 'Campanha salva com sucesso', severity: 'success' })
    } else if (result.status === 210) {
      setSnackbar({ open: true, message: 'Acesso concorrente, recarregue os dados.', severity: 'error' })
    }
    else {
      setSnackbar({ open: true, message: 'Erro ao salvar a campanha', severity: 'error' })
    }
    setLoading(false)
  }

  // Deletar campanha
  async function deleteCampanha() {
    // console.log('deleteCampanha', campanha.idcampanha)
    setLoading(true)
    const result = await deletarCampanha({ idcampanha: campanha.idcampanha, version: campanha.version })
    if (result.status === 200) {
      setSnackbar({ open: true, message: 'Campanha excluída com sucesso', severity: 'success' })
      const newCampanhas = campanhas.filter(camp => camp.idcampanha !== campanha.idcampanha)
      setCampanhas(newCampanhas)
      setShowCampanhaDetails(false)
    }
    else if (result.status === 210) {
      setSnackbar({ open: true, message: 'Acesso concorrente, recarregue os dados.', severity: 'error' })
    }
    else if (result.status === 220) {
      setSnackbar({ open: true, message: 'Campanha não pode ser excluída, pois possui leads associados.', severity: 'error' })
    }
    else {
      setSnackbar({ open: true, message: 'Erro ao salvar a campanha', severity: 'error' })
    }
    setLoading(false)
  }

  return (
    <React.Fragment>
      <Dialog
        open={showCampanhaDetails}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        aria-modal='true'
        aria-hidden='false'
      >

        <DialogTitle id="alert-dialog-title">
          DETALHE DA CAMPANHA
        </DialogTitle>
        <DialogContent>
          {(loading) && ((
            <div style={{ position: 'fixed', top: '50vh', left: '50vw' }}>
              <CircularProgress />
            </div>
          ))}
          <Grid container
            maxWidth={'400px'}
            direction={'column'} gap={2}
          >
            <TextFieldMask
              fullWidth
              variant='standard'
              size='small'
              id="nome"
              name='nome'
              label="Campanha"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              mask={'text'}
            />
            <Grid container justifyContent={'space-between'} gap={2}>
              <DatePicker
                label="Dt Inicio"
                // minDate={dayjs()}
                value={dtinicio}
                onChange={(date) => setDtInicio(date)}
                format='DD/MM/YYYY'
                sx={{ width: '160px' }}
                size='small'

              />
              <DatePicker
                label="Dt Fim"
                // minDate={dayjs()}
                value={dtfim}
                onChange={(date) => setDtFim(date)}
                format='DD/MM/YYYY'
                sx={{ width: '160px' }}
              />
            </Grid>

            <FormControl variant="standard"
              sx={{ maxWidth: 150 }}
            >
              <InputLabel id="lbOrigem">Plataforma</InputLabel>
              <Select
                labelId="lbOrigem"
                id="origem"
                value={origem}
                onChange={handleChangeOrigem}
                label="Plataforma"
                sx={{ width: '150px' }}
              >
                <MenuItem value=""><em>None</em></MenuItem>
                {leadOrigemStatus ? Object.keys(leadOrigemStatus).map((key) => (
                  key !== 'Description' &&
                  <MenuItem key={key} value={key}>
                    {key}
                  </MenuItem>
                ))
                  : null
                }
              </Select>
            </FormControl>
            {campanha.link && (
              <Grid container direction={'row'} alignItems={'center'}>
                <Grid item xs={12} >
                  <Typography variant='h5'>Link</Typography>
                </Grid>
                <Grid item xs={1} >
                  <IconButton onClick={copyToClipboard}>
                    <ContentCopyTwoToneIcon />
                  </IconButton>
                </Grid>
                <Grid item
                  xs={11}
                  overflow={'auto'}
                >
                  <Typography variant='h5' ml={1}>
                    {`${process.env.REACT_APP_LEADS_URL}/${link}`}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Typography variant='h5'>Leads: {campanha.qtd}</Typography>
            <Typography variant='h5'>Cadastrado em: {dayjs(campanha.createdat).format('DD/MM/YYYY')}</Typography>
            {campanha.updatedat && <Typography variant='h5'>Atualizado em: {dayjs(campanha.updatedat).format('DD/MM/YYYY')}</Typography>}
          </Grid>
        </DialogContent>
        <DialogActions>
          {!showDelete && (<>
            <Button disabled={loading} onClick={() => setShowDelete(true)}>Excluir</Button>
            <Button disabled={loading} onClick={saveCampanha}>Gravar</Button>
            <Button disabled={loading} onClick={handleClose}>Fechar</Button>
          </>
          )}
          {showDelete && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 2 }}>
              <Typography variant='h4' color={'error'} >Confirma a exclusão?</Typography>
              <Button disabled={loading} onClick={deleteCampanha}>Sim</Button>
              <Button disabled={loading} onClick={() => setShowDelete(false)}>Não</Button>
            </Box>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
