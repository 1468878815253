import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, } from '@mui/material'
import { useEffect, useState } from 'react'
import { TextFieldMask } from '../../../utils/TextfieldMask/TextfieldMask'


export const Identificacao = ({
  setPlaceEdit, placeEdit,
  setNewPlace, newPlace,
  newPosition,
  openExcluirDialog,
  setOpenExcluirDialog,
  handleClickExcluir }) => {

  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou no form Identificação', newPlace)

  const [vEmpreendimento, setvEmpreendimento] = useState(false)
  const [vConstrutora, setvConstrutora] = useState(false)
  const [vEndereco, setvEndereco] = useState(false)

  const [identificacao, setIdentificacao] = useState({ ...placeEdit, position: newPosition })

  useEffect(() => {
    setPlaceEdit(identificacao)
  }, [identificacao])

  const hasError = (event) => {
    let res = false
    if (event.target.value.length <= 3) res = true
    if (event.target.id === 'construtora') setvConstrutora(res)
    if (event.target.id === 'empreendimento') setvEmpreendimento(res)
    if (event.target.id === 'endereco') setvEndereco(res)
    return res
  }


  return (
    <FormControl sx={{ width: '100%' }}>
      <Grid container direction={'row'} gap={1} mb={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant='standard'
            id='empreendimento'
            required
            label="Empreendimento"
            value={identificacao?.empreendimento ? identificacao.empreendimento : ''}
            onBlur={hasError}
            onChange={(e) => setIdentificacao({ ...identificacao, empreendimento: e.target.value })}
            // helperText="Nome do empreendimento"
            error={vEmpreendimento}
            inputProps={{ maxLength: 150 }}
          // sx={{ width: '350px' }}
          />
        </Grid>
        <Grid item xs={5}>
          <TextFieldMask
            id='telefone'
            fullWidth
            variant='standard'
            label="Telefone"
            value={identificacao?.telefone ? identificacao.telefone : ''}
            onChange={(e) => setIdentificacao({ ...identificacao, telefone: e.target.value })}
            // helperText="Telefone para contato"
            mask={'phone'}
          />
        </Grid>
      </Grid>
      <Grid container direction={'row'} gap={1} mb={2}>
        <Grid item xs={6}>
          <TextField
            variant='standard'
            required
            id='construtora'
            label="Construtora"
            value={identificacao?.construtora ? identificacao.construtora : ''}
            onChange={(e) => setIdentificacao({ ...identificacao, construtora: e.target.value })}
            onBlur={hasError}
            // helperText="Construtora/Incorporadora"
            error={vConstrutora}
            inputProps={{ maxLength: 150 }}
            fullWidth
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            variant='standard'
            id='site'
            label="Site"
            value={identificacao?.site ? identificacao.site : ''}
            onChange={(e) => setIdentificacao({ ...identificacao, site: e.target.value })}
            // helperText="Site do empreendimento"
            inputProps={{ maxLength: 150 }}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container direction={'row'} gap={1} mb={2}>
        <TextField
          fullWidth
          variant='standard'
          required
          id="endereco"
          label="Endereço"
          value={identificacao?.endereco ? identificacao.endereco : ''}
          onChange={(e) => setIdentificacao({ ...identificacao, endereco: e.target.value })}
          onBlur={hasError}
          // helperText="Endereço do empreendimento"
          error={vEndereco}
          inputProps={{ maxLength: 100 }}
        />
      </Grid>
      <Grid container direction={'row'} gap={1} mb={'20px'}>
        <TextField
          variant='standard'
          id="entrega"
          label="Entrega"
          value={identificacao?.entrega ? identificacao.entrega : ''}
          onChange={(e) => setIdentificacao({ ...identificacao, entrega: e.target.value })}
          // helperText="Prazos de Entrega"
          inputProps={{ maxLength: 150 }}
          sx={{ width: '350px' }}
        />
        <FormControl sx={{ mr: 1, minWidth: 200 }}>
          <InputLabel id="lbSituacao" >Situação</InputLabel>
          <Select
            variant='standard'
            label='Situação'
            name='situacao'
            id="situacao"
            labelId="lbSituacao"
            value={identificacao?.situacao ? identificacao.situacao : 'LA'}
            onChange={(e) => setIdentificacao({ ...identificacao, situacao: e.target.value })}

          >
            <MenuItem value=""><em>None</em></MenuItem>
            <MenuItem value={'LA'}>Lançamento</MenuItem>
            <MenuItem value={'EO'}>Em Obra</MenuItem>
            <MenuItem value={'OE'}>Em Obra/Entregue</MenuItem>
            <MenuItem value={'E'}>Entregue</MenuItem>
            <MenuItem value={'V'}>100% Vendido</MenuItem>
          </Select>
          {/* <FormHelperText component={'span'}>Situação do empreendimento</FormHelperText> */}
        </FormControl>

      </Grid>
      <Grid item>
        <TextField
          fullWidth
          id="desc"
          name='desc'
          label="Descrição"
          multiline
          rows={7}
          value={identificacao?.descricao ? identificacao.descricao : ''}
          onChange={(e) => setIdentificacao({ ...identificacao, descricao: e.target.value })}
          inputProps={{ maxLength: 1000 }}
        />
      </Grid>
    </FormControl>
  )
}