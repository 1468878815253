import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { AppTheme } from './theme/ThemeApp';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './App.css';

import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
dayjs.locale("pt-br");

require('./firebase')

function App() {
  
  global.faseetapa = null
  global.swMessage = null

  const themeApp = AppTheme();
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale="pt-br"      
    >
      <BrowserRouter>
        <ThemeProvider theme={themeApp}>
          <CssBaseline />
          <AppRoutes />
        </ThemeProvider>
      </BrowserRouter>
    </LocalizationProvider>
  )
}

export default App;
