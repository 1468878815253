import { apiGate } from '../../services/api_gate'


//Ordenar os operadores da escala
export async function ordenarOperadores(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do setCorretor') }
  const api = await apiGate.post('/crm/escala/ordenar', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}

//Atualiza os dados do operador da escala
export async function updateCorretor(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do updateCorretor') }
  const api = await apiGate.post('/crm/escala/operatorupdate', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}

// Carrega os operadores da escala
export async function listCorretores(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do carregarCorretores') }
  const api = await apiGate.post('/crm/escala/operatorlist', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}

// Carrega os operadores da escala para Distribuição
export async function listCorretoresDistribuicao(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do listCorretoresDistribuicao') }
  const api = await apiGate.post('/crm/escala/listoperatordist', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}

export async function createEscalas(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do createEscalaMany') }
  const api = await apiGate.post('/crm/escala/createmany', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}

export async function getEscala(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do getEscala') }
  const api = await apiGate.post('/crm/escala/list', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}

export async function deleteEscala(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do excluirEscala') }
  const api = await apiGate.post('/crm/escala/excluir', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}

export async function updateEscala(dados) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do updateEscala') }
  const api = await apiGate.post('/crm/escala/update', dados)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
  return api
}


