import { Box } from '@mui/material';
import { red } from '@mui/material/colors';

export function Logotipo(props) {
  var url_atual = window.location.href;
  var _url = new URL(url_atual).origin
  var _img = new URL(_url)+'/logo2.svg';
  return (
    <Box
      {...props}
      sx={{
        display: props.display,
        backgroundImage: `url(${_img})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    />
  )
}