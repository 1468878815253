import { getFasesEtapas } from '../controllers/crm/baseController'


export const gFases = []

export const gFasesetapas = []

export const gAcoes = []

export async function getLeadFasesEtapasG(dados) {

  if (gFasesetapas.length > 0) { return } // Se já carregou não carrega de novo

  const result = await getFasesEtapas(dados)
  // console.log('Carregou Fases e Etapas', result.data)
  if (result.status === 200) {
    if (result.data.length > 0) {

      gFasesetapas.length = 0
      gFases.length = 0
      gAcoes.length = 0
      let _flt = []

      // gFasesetapas.push(result.data)

      result.data.forEach(f => {
        if (!_flt.includes(f.idfase)) {
          _flt.push(f.idfase)
          gFases.push({ fase: f.fase, idfase: f.idfase, ordem:f.ordemf, total:0 })
        }
        gFasesetapas.push(f)
        // gEtapas.push({ label: f.acao, idfase: f.idfase})
        gAcoes.push( f.acao )
      });
    }
  }
}