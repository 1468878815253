import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendEmailVerification, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../../firebase';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme, Card, CardContent, Alert, AlertTitle, CircularProgress, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { Copyright } from '../Copyright';
import { getAllUserContas, getUserAccount } from '../../controllers/userController';
import { yellow } from '@mui/material/colors';
import { SingOut } from './SingOut';




export default function Login() {

  const theme = useTheme();

  let navigate = useNavigate()

  const [account, setAccount] = useState(null)
  const [isError, setIsError] = useState(null)
  const [hasContas, setHasContas] = useState(false)
  const [contas, setContas] = useState(null)
  const [selConta, setSelConta] = useState('')
  const [contratar, setContratar] = useState(false)
  const [plano, setPlano] = useState('Free')
  const [hasSendEmail, setHasSendEmail] = useState(false)
  const [credential, setCredential] = useState()

  useEffect(() => {
    if (account) {
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Saindo do Login', account) }
      localStorage.setItem('token', JSON.stringify(account.token))
      navigate('/home', { replace: true, state: { account: account } })
    }
  }, [account, navigate])

  function goToForget() {
    navigate('/forget', { replace: true })
  }

  function goToSingUp() {
    navigate('/singup', { replace: true })
  }

  // Envia email de verificação
  async function EmailVerification() {
    setLoading(true)
    console.log('EmailVerification', credential)
    await sendEmailVerification(credential).then(() => {
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Email de verificação enviado com sucesso...')
      setIsError('Email enviado com sucesso. Em breve você deverá receber o email de verificação encaminhado para ' + credential.email)
      setHasSendEmail(false)
    })
    setLoading(false)
  }

  //let resContas = []
  async function getAccount(user) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou no getAccount', user) }
    const result = await getUserAccount(user, selConta)
    if (result.sucess) {
      if (result.code > 0) {
        setContratar(true)
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('result', result)
        setIsError(result.msg2 ? result.msg2 + ' (C' + result.code + ')' : result.msg)
        setTimeout(() => { setAccount(result.data) }, 4000)
      }
      else {
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Usuario encontrado', result) }
        setContratar(false)
        setAccount(result.data)
        setLoading(false)
      }
    }
    else {
      const user = result.data
      if (result.msg === 'cta') {
        let resContas = await getAllUserContas(result.data)
        if (resContas.sucess) {
          setContas(resContas.data)
          setHasContas(true)
          setIsError('Selecione uma equipe.')
        }
        else setIsError(resContas.msg)
      }
      else {
        if (result.code === 4) {  // Periodo de degustação encerrado
          setContratar(true)
          setIsError(result.msg2)
        }
        else if (result.code === 6) {
          setHasSendEmail(true)
          setContratar(false)
          setIsError(result.msg)
        }
        else {
          setContratar(false)
          setIsError(result.code === 5 ? result.msg2 : result.msg) // code 5 -> Falha de Rede na API
        }
      }
      setLoading(false)
    }
  }


  const [loading, setLoading] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let email = data.get('email').toLocaleLowerCase()
    let password = data.get('password')
    if (email.length > 0 && password.length > 0) {
      setLoading(true)
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Iniciou Autenticação Google Auth') }
      await signInWithEmailAndPassword(auth, email.trim(), password.trim())
        .then(async (userCredential) => {
          // Signed in
          setCredential(userCredential.user)
          if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Autenticação realizada', userCredential) }
          setIsError(null)
          await getAccount(userCredential.user)
          if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Retorno da Autenticação ', userCredential.user.accessToken) }
        })
        .catch((error) => {
          if (error.message.includes('network-request-failed')) {
            setIsError('Falha na conexão de rede...')
          }
          else
            setIsError('Email ou senha inválidos...')
          setContratar(false)
          setLoading(false)
          if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log(error.message, auth) }
        })
    }
    else {
      setIsError('Informe os dados de autenticação...')
    }
  };

  function handleSelectConta(event) {
    setSelConta(event.target.value)
  }

  function handleCancelar() {
    setHasSendEmail(false)
    setHasContas(false)
    setContas(null)
    setSelConta('')
    setIsError(null)
  }

  return (

    <Grid container component="main" sx={{
      height: { xs: '94vh', md: '100vh' },
    }}>

      <Grid item container
        xs={12}
        md={7}
        justifyContent='center'
        sx={{
          backgroundImage: 'url(./logo.svg)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: theme.palette.primary.main,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
        }}
      />
      <Grid item
        xs={12}
        md={5}

        container
        alignContent='center'
      >
        <Grid
          item
          container
          justifyContent='center'
        >
          <Card sx={{
            width: '75%',
            [theme.breakpoints.down('md')]: {
              width: '90%',
            }
          }}>

            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h4">
                  Seja Bem-vindo!
                </Typography>

                <Grid component={'form'} container flexDirection={'row'} onSubmit={handleSubmit} >

                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    disabled={hasSendEmail}
                    inputProps={!hasContas ? { maxLength: 50 } : { maxLength: 50, readOnly: true }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Senha"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    disabled={hasSendEmail}
                    inputProps={!hasContas ? { maxLength: 15 } : { maxLength: 15, readOnly: true }}
                  />
                  {hasContas && (
                    <Grid container width={'100%'} mt={'15px'}>
                      <FormControl sx={{ minWidth: 120, width: '100%' }}>
                        <InputLabel id="label">Equipe :</InputLabel>
                        <Select
                          labelId="label"
                          id="selectEquipe"
                          value={selConta}
                          onChange={handleSelectConta}
                          fullWidth
                          color='error'
                        >
                          {contas.map((cta) => {
                            return <MenuItem key={cta.id} value={cta.id}>{cta.equipe}</MenuItem>
                          })}

                        </Select>
                        <FormHelperText sx={{ color: 'red' }}>Selecione uma equipe...</FormHelperText>
                      </FormControl>
                    </Grid>
                  )

                  }
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Lembrar a senha"
                    id='checkBox'
                    name='checkBox'
                  />
                  <Grid container flexDirection={'row'} width={'100%'} justifyContent={'flex-end'}>
                    {hasContas && (
                      <Button
                        id='butCancel'
                        name='butCancel'
                        type="reset"
                        onClick={handleCancelar}
                        variant="contained"
                        sx={{ my: 2, mr: 2, width: '40%' }}
                      >
                        Cancelar
                      </Button>
                    )}
                    {!hasSendEmail && (
                      <Button
                        id='button'
                        name='button'
                        type="submit"
                        variant="contained"
                        sx={{ my: 2, width: '40%' }}
                        disabled={loading}
                      >
                        Enviar
                        {loading && (
                          <CircularProgress
                            size={24}
                            sx={{
                              color: theme.palette.warning.dark,
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginTop: '-12px',
                              marginLeft: '-12px',
                            }}
                          />
                        )}
                      </Button>
                    )}
                  </Grid>
                  <Grid container>
                    <Grid item xs>
                      <Link onClick={goToForget}>Esqueceu a senha?</Link>

                    </Grid>

                    <Grid item>
                      <Link onClick={goToSingUp}>Não tem conta? Cadastre-se.</Link>
                    </Grid>
                  </Grid>

                </Grid>
                {/* </Box> */}
                {isError && (
                  <Box sx={{ mt: '10px', width: '100%' }}>
                    <Alert severity="error">
                      <Typography component={'span'} variant='body1'>
                        <AlertTitle>Atenção!</AlertTitle>
                        <strong>{isError}</strong>
                      </Typography>
                    </Alert>
                    {hasSendEmail && (
                      <Grid
                        container
                        justifyContent={'flex-end'}
                        alignContent={'center'}>
                        <Button
                          id='butCancel'
                          name='butCancel'
                          type="reset"
                          onClick={handleCancelar}
                          variant="contained"
                          sx={{ my: 2, mr: 1 }}
                        >
                          Cancelar
                        </Button>
                        <Button
                          id='butEmail'
                          name='butEmail'
                          type="submit"
                          variant="contained"
                          sx={{ my: 2 }}
                          disabled={loading}
                          onClick={EmailVerification}
                        >
                          Reenviar email de validação
                          {loading && (
                            <CircularProgress
                              size={24}
                              sx={{
                                color: theme.palette.warning.dark,
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                              }}
                            />
                          )}
                        </Button>
                      </Grid>
                    )}
                    {contratar && (
                      <Grid
                        container
                        justifyContent={'center'}
                        alignContent={'center'}
                        sx={{ backgroundColor: yellow[200], height: '40px' }}
                      >
                        <Link
                          href="http://gimov.gate51.com.br"
                          variant="body1"
                          sx={{ color: 'red', fontWeight: 'bold' }} >
                          Contratar Plano de Assinatura.
                        </Link>
                      </Grid>
                    )}

                  </Box>
                )}
                <Copyright sx={{ mt: 2 }} />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid >

  );
}