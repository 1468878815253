import dayjs from 'dayjs'
import { escalaStatus } from '../../constants/crm'

export function getEscalaStatus(_tipo) {
  switch (_tipo) {
    case escalaStatus.Prevista:
      return 'Prevista'
    case escalaStatus.Programada:
      return 'Programada'
    case escalaStatus.Ordenada:
      return 'Ordenada'
    case escalaStatus.Executando:
      return 'Em execução'
    case escalaStatus.Suspensa:
      return 'Suspensa'
    case escalaStatus.Desativada:
      return 'Desativada'
    default:
      return 'Tipo inválido'
  }
}

export function getEscalaSituacao(status, diamesano) {
  const hoje = dayjs(dayjs().format('YYYY-MM-DD')).toISOString()
  if (status === escalaStatus.Programada) return 'Programada'
  else if (status === escalaStatus.Prevista) return 'Prevista'
  else if (status === escalaStatus.Ordenada) return 'Ordenada'
  else if (status === escalaStatus.Executando) {
    if (diamesano === hoje) return 'Executando'
    else if (diamesano < hoje) {
      return '...'
    }
    else return 'Programada'
  }
  else if (status === escalaStatus.Suspensa) return 'Suspensa'
  else return 'Desconhecida'
}