// Retorna o tipo de distribuição usado no CRM

import { distribuicaoTipo } from '../../constants/crm'

export function getDistribuicao(_distribuicao) {
  switch (_distribuicao) {
    case distribuicaoTipo.Randomica:
      return 'Randômica'
    case distribuicaoTipo.Cadastro:
      return 'Cadastro'
    default:
      return 'Tipo inválido'
  }
}
