import { useTheme } from '@emotion/react';
import { Button, CircularProgress, Dialog, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { carregarCliente, carregarPorWhere, salvarCarteira } from '../../../controllers/crm/carteiraController';
import { TextFieldMask } from '../../../utils/TextfieldMask/TextfieldMask';
import { green, red } from '@mui/material/colors';
import dayjs from 'dayjs';
import { where } from 'firebase/firestore';



export default function ClinteDetalhe({ account, lead, showClient, setShowClient, setShowTaskList, setSnackbar }) {
  const [loading, setLoading] = useState(false);
  // const [cliente, setCliente] = useState({});
  const [cliente, setCliente] = useState({
    idcliente: lead.idcliente ? lead.idcliente : null,
    idconta: account.conta,
    idlead: lead.idlead,
    idusuario: account.id,
    nome: lead.nome ? lead.nome : '',
    telefone: lead.telefone ? lead.telefone : '',
    email: lead.email ? lead.email : ''
  })
  const [nome, setNome] = useState(lead.nome ? lead.nome : '');
  const [telefone, setTelefone] = useState(lead.telefone ? lead.telefone : '');
  const [email, setEmail] = useState(lead.email ? lead.email : '');
  // const [cpf, setCpf] = useState('');
  const [nascimento, setNascimento] = useState(cliente?.dtnascimento ? dayjs(cliente.dtnascimento).format('DD/MM/YYYY').toString() : '');  // dayjs(cliente.dtnascimento).format('DD/MM/YYYY').toString()
  const [observacao, setObservacao] = useState(cliente?.observacao ? cliente.observacao : '');
  const [recProposta, setRecProposta] = useState(cliente?.recproposta ? cliente.recproposta : true);
  const [msgErro, setMsgErro] = useState([]);
  const [cpfCnpjExistente, setCpfCnpjExistente] = useState(false);

  const [clienteExistente, setClienteExistente] = useState({});
  const [showLoadClientExistente, setShowLoadClientExistente] = useState(false);
  const [filterExistente, setFilterExistente] = useState('');
  const [showClienteExistente, setShowClienteExistente] = useState(false);
  const [clienteVinculado, setClienteVinculado] = useState(false);


  const theme = useTheme();

  console.log(account, lead);

  function substituirCliente() {
    const _cliente = clienteExistente
    setCliente(_cliente)
    setNome(_cliente.nome)
    setTelefone(_cliente.telefone)
    setEmail(_cliente.email)
    // setCpf(_cliente.cpfcnpj)
    setNascimento(_cliente.dtnascimento ? dayjs(_cliente.dtnascimento).format('DD/MM/YYYY').toString() : '')
    setObservacao(_cliente.observacao)
    setRecProposta(_cliente.recproposta)
    setShowLoadClientExistente(false)
  }


  async function loadExistClient() {
    setLoading(true);
    console.log('loadExistClient', filterExistente)
    console.log('cliente', cliente)
    const filtro = filterExistente.split(' ')[0]
    let query = {
      where: {
        idconta: account.conta
      },
      include: {
        operador: {
          select: {
            nome: true,
            telefone: true
          }
        }
      }
    }

    if (filtro === 'email') {
      query.where.email = email
    }
    if (filtro === 'telefone') {
      query.where.telefone = telefone
    }
    console.log('filtro', filtro, query)
    setShowLoadClientExistente(true)
    const dados = await carregarPorWhere(query)
    if (dados.status === 200) {
      if (dados.data.data.length === 0) {
        setSnackbar({ open: true, message: 'Cliente não encontrado', severity: 'error' })
        setLoading(false);
        return
      }
      console.log('Cliente existente', dados.data.data[0])
      dados.data.data[0].dtnascimento = dados.data.data[0].dtnascimento ? dayjs(dados.data.data[0].dtnascimento).format('DD/MM/YYYY').toString() : null
      setClienteExistente(dados.data.data[0])
      setShowClienteExistente(true)
      setLoading(false);
      return
    }
    else {
      console.log('Erro ao carregar cliente existente', dados)
      setSnackbar({ open: true, message: 'Erro ao carregar cliente existente', severity: 'error' })
      setLoading(false);
      return
    }
  }

  async function loadClient() {
    setLoading(true);
    try {
      const retorno = await carregarCliente({ idcliente: lead.idcliente, idconta: account.conta })
      console.log('loadClient', retorno)  
      if (retorno.status === 200) {
        const _cliente = retorno.data.data
        _cliente.dtnascimento = _cliente.dtnascimento ? dayjs(_cliente.dtnascimento).format('DD/MM/YYYY').toString() : null
        // console.log('Cliente carregado com sucesso', retorno.data.data)
        setCliente(_cliente)
        setNome(_cliente.nome)
        setTelefone(_cliente.telefone)
        setEmail(_cliente.email)
        // setCpf(_cliente.cpfcnpj)
        setNascimento(_cliente.dtnascimento)
        setObservacao(_cliente.observacao)
        setRecProposta(_cliente.recproposta)
      }
      else {
        setSnackbar({ open: true, message: 'Erro ao carregar cliente', severity: 'error' })
        // console.log('Erro ao carregar cliente', retorno)
      }
    }
    catch (error) {
      // console.log('Erro de transporte carregar cliente', error)
      setSnackbar({ open: true, message: 'Erro ao carregar cliente', severity: 'error' })
    }
    finally {
      setLoading(false);
    }
  }

  function handleClose() {
    setShowClient(false);
    setShowTaskList(true);
  }

  function validar(_dados) {
    msgErro.length = 0
    if (!_dados.nome) {
      msgErro.push('Nome inválido, favor preencher')
    }
    if (!_dados.telefone) {
      msgErro.push('Telefone inválido, favor preencher')
    }
    if (_dados.dtnascimento) {
      console.log('Data de nascimento', _dados.dtnascimento)
      if (!dayjs(_dados.dtnascimento, 'DD/MM/YYYY', true).isValid()) {
        msgErro.push('Data de nascimento inválida, favor preencher')
      }
      else _dados.dtnascimento = dayjs(_dados.dtnascimento, 'DD/MM/YYYY').toISOString()
    }
    // if (_dados.cpfcnpj) {
    //   if (_dados.cpfcnpj.length < 14) {
    //     msgErro.push('CPF/CNPJ inválido, favor preencher')
    //     setCpf('')
    //   }
    // }
    if (msgErro.length > 0) {
      return false
    }
    else return true
  }
  async function handleSave() {
    setLoading(true);
    cliente.idoperador = account.id
    if (!cliente.idlead) cliente.idlead = lead.idlead
    try {
      // console.log('Dados a salvar', dados, cliente)
      if (!validar(cliente)) {
        let msg = ''
        msgErro.forEach((item) => {
          msg += item + ';\n'
        })
        setSnackbar({ open: true, message: msg, severity: 'error' })
        return
      }
      const retorno = await salvarCarteira(cliente)
      if (retorno.status === 200 && retorno.data.success) {
        console.log('Cliente salvo com sucesso', retorno)
        retorno.data.data.dtnascimento = retorno.data.data.dtnascimento ? dayjs(retorno.data.data.dtnascimento).format('DD/MM/YYYY').toString() : null
        setCliente(retorno.data.data)
        lead.idcliente = retorno.data.data.idcliente
        setSnackbar({ open: true, message: retorno.data.message, severity: 'success' })
        // console.log('Cliente salvo com sucesso', retorno, retorno.data.data.idcliente)
      }
      else {
        console.log('Erro ao salvar cliente', retorno)
        if (retorno.data.message.includes('existente')) {
          setShowLoadClientExistente(true)
          setFilterExistente(retorno.data.message)
        }
        setSnackbar({ open: true, message: retorno.data.message, severity: 'error' })
        // console.log('Erro ao salvar cliente', retorno)
      }
    }
    catch (error) {
      // console.log('Erro de transporte salvar cliente', error)
      setSnackbar({ open: true, message: 'Erro ao salvar cliente', severity: 'error' })
    }
    finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (lead.idcliente) {
      loadClient();
    }
  }, []);

  return (
    <>
      {(loading) && ((
        <div style={{ position: 'fixed', top: '50vh', left: '50vw' }}>
          <CircularProgress />
        </div>
      ))}
      <Grid container
        width={{
          xs: '100%', // largura máxima em telas pequenas
          md: '500px' // largura de 500px em telas grandes
        }}
        height={'60vh'}
        direction={'row'}
        p={1}
        bgcolor={'white'}
        border={'1px solid #ccc'}
        justifyContent="space-between"

      >
        <Grid item>
          <Grid container
            justifyContent={'left'}
            alignContent={'center'}
            bgcolor={cliente?.idcliente ? green[200] : red[100]}
            p={1} mb={0}
          >
            <Typography variant="h4">{cliente?.idcliente ? 'CLIENTE EM CARTEIRA' : 'CLIENTE NÃO ESTÁ NA CARTEIRA'}</Typography>
          </Grid>
          <Grid container
            bgcolor={theme.palette.grey[200]}
            mt={1}
            mb={1}
            px={1}
            height={'47vh'}
            overflow={'auto'}
          >
            <Grid container
              direction={'row'}
              mt={1}
            >
              <Grid item xs={8} pr={1}>
                <TextFieldMask
                  fullWidth
                  variant='standard'
                  size='small'
                  id="nome"
                  name='nome'
                  label="Nome"
                  value={nome ? nome : ''}
                  onChange={(e) => { setNome(e.target.value); setCliente({ ...cliente, nome: e.target.value }) }}
                  mask={'nome'}
                />
              </Grid>
              <Grid item xs={4} >
                <TextFieldMask
                  variant='standard'
                  size='small'
                  id="telefone"
                  name='telefone'
                  label="Telefone"
                  value={telefone ? telefone : ''}
                  onChange={(e) => { setTelefone(e.target.value); setCliente({ ...cliente, telefone: e.target.value }) }}
                  mask={'phone'}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} mt={1}>
              <TextFieldMask
                fullWidth
                variant='standard'
                size='small'
                id="email"
                name='email'
                label="Email"
                value={email ? email : ''}
                onChange={(e) => { setEmail(e.target.value); setCliente({ ...cliente, email: e.target.value }) }}
                mask={'email'}
              />

            </Grid>
            <Grid container direction={'row'} mt={1}>
              {/* <Grid item xs={6} pr={1}>
              <TextFieldMask
                fullWidth
                variant='standard'
                size='small'
                id="cpf"
                name='cpf'
                label="CPF"
                value={cpf ? cpf : ''}
                onChange={(e) => setCpf(e.target.value)}
                onBlur={(e) => consultarCPF(e.target.value)}
                mask={'cpf'}
              />
            </Grid> */}
              <Grid item xs={12}>
                <TextFieldMask
                  fullWidth
                  variant='standard'
                  size='small'
                  id="nascimento"
                  name='nascimento'
                  label="Data de Nascimento"
                  placeholder='dd/mm/aaaa'
                  value={nascimento ? nascimento : ''}
                  onChange={(e) => { setNascimento(e.target.value); setCliente({ ...cliente, dtnascimento: e.target.value ? e.target.value : null }) }}
                  mask={'date'}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} mt={1}>
              <TextFieldMask
                fullWidth
                rows={3}
                multiline
                variant='standard'
                size='small'
                id="observacao"
                name='observacao'
                label="Observação"
                value={observacao ? observacao : ''}
                onChange={(e) => { setObservacao(e.target.value); setCliente({ ...cliente, observacao: e.target.value }) }}
                mask={'text'}
              />
            </Grid>
            <Grid item xs={12} mt={0}>
              <FormControl
                size='small'
              >
                <FormLabel id="propostas" >
                  <Typography variant="body1">
                    Receber novas propostas:
                  </Typography>
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="propostas"
                  name="rbPropostas"
                  value={recProposta}
                  onChange={(e) => { setRecProposta(e.target.value); setCliente({ ...cliente, recproposta: Boolean(e.target.value) }) }}

                >
                  <FormControlLabel value={true} control={<Radio size='small' />} label="Sim" />
                  <FormControlLabel value={false} control={<Radio size='small' />} label="Não" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Última atualização: {cliente.updatedat ? dayjs(cliente.updatedat).format('DD/MM/YYYY HH:mm').toString() : ''}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent={'space-between'} gap={2}  >
          {!showLoadClientExistente && (
            <>
              <Button sx={{ height: '28px' }} variant='contained' size='small' disabled={loading} onClick={handleSave}>Salvar</Button>
              <Button sx={{ height: '28px' }} variant='contained' size='small' disabled={loading} onClick={handleClose}>Fechar</Button>
            </>
          )}
          {(showLoadClientExistente) && (
            <>
              <Button sx={{ height: '28px' }} variant='contained' size='small' disabled={loading} onClick={loadExistClient}>Ver existente</Button>
              <Button sx={{ height: '28px' }} variant='contained' size='small' disabled={loading} onClick={() => setShowLoadClientExistente(false)}>Cancelar</Button>
            </>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={showClienteExistente}
        onClose={() => setShowClienteExistente(false)}
      >
        <Grid container
          width={{
            xs: '100%', // largura máxima em telas pequenas
            md: '500px' // largura de 500px em telas grandes
          }}
          // height={'45vh'}
          direction={'row'}
          p={1}
          bgcolor={'white'}
          border={'1px solid #ccc'}
          justifyContent="space-between"
        >
          {/* <Grid item> */}
          <Grid container
            justifyContent={'left'}
            alignContent={'center'}
            bgcolor={clienteExistente?.idcliente ? green[200] : red[100]}
            p={1} mb={0}
          >
            <Typography variant="h4">CLIENTE EXISTENTE</Typography>
          </Grid>
          <Grid container
            bgcolor={theme.palette.grey[200]}
            mt={1}
            mb={1}
            px={1}
            height={'30vh'}
            overflow={'auto'}
          >
            <Grid container
              direction={'row'}
              overflow={'auto'}
            >
              <Grid item xs={12} >
                <Typography variant="body1">Nome: {clienteExistente.nome}</Typography>
                <Typography variant="body1">Telefone: {clienteExistente.telefone}</Typography>
                <Typography variant="body1">Email: {clienteExistente.email}</Typography>
                <Typography variant="body1">Data de Nascimento: {clienteExistente?.dtnascimento ? dayjs(clienteExistente.dtnascimento).format('DD/MM/YYYY').toString() : null}</Typography>
                <Typography variant="body1">Observação: {clienteExistente.observacao}</Typography>
                <Typography variant="body1">Receber novas propostas: {clienteExistente.recproposta ? 'Sim' : 'Não'}</Typography>
                <Typography variant="body1">Corretor: {clienteExistente.operador?.nome}</Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* </Grid> */}
          <Grid container justifyContent={'space-between'} gap={2}  >
            <Button sx={{ height: '28px' }} variant='contained' size='small' disabled={loading} onClick={() => { substituirCliente(); setShowClienteExistente(false) }}>Carregar</Button>
            <Button sx={{ height: '28px' }} variant='contained' size='small' disabled={loading} onClick={() => { setShowClienteExistente(false); setShowLoadClientExistente(false) }}>Cancelar</Button>

          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
