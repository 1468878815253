import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, CardActions, Typography, Grid, Container, CssBaseline, Alert, CircularProgress, Input } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import MenuIcon from '@mui/icons-material/Menu';
import Snackbar from '@mui/material/Snackbar';
// import { blue, grey, red, yellow, green } from '@mui/material/colors';
// import ReplayTwoToneIcon from '@mui/icons-material/ReplayTwoTone';
// import { getEscala, updateEscala } from '../../../controllers/crm/escalaController';
// import { getDiaSemana } from '../../../utils/crm/getDiaSemana';
// import { getDistribuicao } from '../../../utils/crm/getDistribuicao';
// import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
// import KeyboardArrowRightTwoToneIcon from '@mui/icons-material/KeyboardArrowRightTwoTone';
import ReplayTwoToneIcon from '@mui/icons-material/ReplayTwoTone';

import { leadStatus, processFases, taskType } from '../../../constants/crm';
import { getLeads } from '../../../controllers/crm/leadController';
import { useTheme } from '@emotion/react';
import LeadDetails from './LeadDetails';
import { gFases, gFasesetapas } from '../../../constants/fases';
import { push, set, update } from 'firebase/database';
import { MyTasks } from './MyTasks';
import { MyReminders } from './MyReminders';
import { green, teal } from '@mui/material/colors';
import { getColorFaseByOrdem } from '../../../utils/crm/getColors';

import dayjs from 'dayjs';
import 'dayjs/locale/pt-br.js';
dayjs.locale('pt-br');


export default function MyLeads({ account, showMyLeads, setShowMyLeads, setShowMenu,
  update, setUpdate, setSnackbar,
  leadsList, setLeadsList, loadMyLeads,
  filteredLeads, setFilteredLeads
}) {
  const theme = useTheme();
  // Constantes Recebidas
  const idconta = account.conta
  const idusuario = account.id
  const nome = account.nome

  // Constantes de Estado
  const [showLeadDetails, setShowLeadDetails] = useState(false)

  const [loading, setLoading] = useState(false)
  const [selectLead, setSelectLead] = useState(null)
  const [showOption, setShowOption] = useState(false)
  const [option, setOption] = useState(null)


  function procurarLeads(event) {
    const value = event.target.value;
    const filtered = leadsList.filter((lead) => {
      if (
        (lead.nome.toLowerCase().includes(value.toLowerCase())
          || lead.email.toLowerCase().includes(value.toLowerCase())
          || lead.telefone.toLowerCase().includes(value.toLowerCase()))
        // && lead.idfase.includes(selectIdFase)
      ) {
        return lead;
      }
    });
    setFilteredLeads(filtered);
  }


  function estatistica() {
    let cont = []
    let dados = []
    let total = 0
    const leads = leadsList.length
    for (let i = 0; i < gFases.length; i++) {
      let _fase = gFases[i]
      cont[i] = 0
      for (let j = 0; j < leads; j++) {
        if (leadsList[j].idfase === _fase.idfase) {
          cont[i]++
        }
      }
      _fase.total = cont[i]
      total += cont[i]
      dados.push(_fase)
    }
    dados.total = total
    return dados
  }

  const dados = estatistica()
  // console.log('Dados', dados)


  function handleOpenLead(lead) {
    setSelectLead(lead)
    setShowLeadDetails(true)
  }
  return (
    <Container>
      <Grid container >
        {(loading) && ((
          <div style={{ position: 'fixed', top: '50vh', left: '50vw' }}>
            <CircularProgress />
          </div>
        ))}
        {!showLeadDetails && (
          <Grid container >
            <Grid container
              p={1}
              mt={1}
              bgcolor={'white'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Typography variant="h3" alignContent={'center'} >LEADS RECEBIDOS</Typography>
              <Button
              variant="contained"
              onClick={loadMyLeads}
            >
              <ReplayTwoToneIcon />
            </Button>

            </Grid>
            <Grid container
              bgcolor={'white'}
              px={{ xs: 1, md: 2 }}
              mt={1}
              alignContent={'center'}
            >
              <Grid item xs={10}>
                <Input id='pesquisar' fullWidth
                  onChange={(e) => procurarLeads(e)}
                  placeholder="Pesquisar" />
              </Grid>
              <Grid item xs={2}
                alignContent={'center'}
              >
                <Typography variant="h4" component="div"
                  textAlign={'right'}
                >
                  {`(${leadsList.length})`}
                </Typography>
              </Grid>
            </Grid>
            <Grid item
              xs={12}
              // mt={1}
              height={'75vh'}
              overflow={'auto'}
              bgcolor={'#f5f5f5'}
              px={{ xs: 1, md: 2 }}
              py={2}
            >
              {filteredLeads.map((_lead, i) => (
                <Grid container
                  border={1}
                  key={i}
                  justifyContent={'left'}
                  gap={0}
                  bgcolor={getColorFaseByOrdem(_lead.fase.ordem)}
                  p={1}
                  onClick={() => handleOpenLead(_lead)}
                  sx={{
                    cursor: 'pointer',
                    "&:hover": {
                      bgcolor: green[300],
                    },
                  }}
                >
                  <Grid item
                    width={'300px'}
                    px={1} textAlign={'left'}>
                    <Typography variant="body1" component="div" fontSize={'14px'}>
                      {_lead.nome}
                    </Typography>
                  </Grid>
                  <Grid item
                    width={'200px'}
                    px={1}
                    textAlign={'left'}>
                    <Typography variant="body1" component="div" fontSize={'14px'}>
                      {_lead.fase.nome}
                    </Typography>
                  </Grid>
                  <Grid item
                    width={'200px'}
                    px={1}
                    textAlign={'left'}>
                    <Typography variant="body1" component="div" fontSize={'14px'}>
                      {_lead.etapa.nome}
                    </Typography>
                  </Grid>
                  <Grid item
                    px={1}
                    textAlign={'center'}>
                    <Typography variant="body1" component="div" fontSize={'14px'} >
                      {_lead.telefone}
                    </Typography>
                  </Grid>
                  <Grid item
                    px={1}
                    textAlign={'left'}>
                    <Typography variant="body1" component="div" fontSize={'14px'}>
                      {_lead.email}
                    </Typography>
                  </Grid>
                  <Grid item
                    px={1}
                    textAlign={'center'}>
                    <Typography variant="body1" component="div" fontSize={'14px'}>
                      {`${new Date(_lead.createdat).toLocaleDateString()} ${new Date(_lead.createdat).toLocaleTimeString()}`}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        {showLeadDetails && (
          <LeadDetails
            account={account}
            lead={selectLead}
            setShowMyLeads={setShowMyLeads}
            setShowMenu={setShowMenu}
            setShowLeadDetails={setShowLeadDetails}
            showLeadDetails={showLeadDetails}
            setOption={setOption}
            setShowOption={setShowOption}
            setSnackbar={setSnackbar}
            setUpdate={setUpdate}
            update={update}
            setLoading={setLoading}
            loading={loading}
          />
        )}
      </Grid>
    </Container>
  )
}



