import { Box, FormControl, IconButton, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { loadURlImgEmpreendimentos } from '../../../api/storage'
import { PhotoCamera } from '@mui/icons-material'
import { isUrl } from '../../../utils/validURL'

export const Nuvem = ({
  setPlaceEdit, placeEdit,
  setNewPlace, newPlace, setFileImg, fileImg }) => {

  let _place = placeEdit
  const [urlimagem, setUrlImagem] = useState(_place.hasOwnProperty('urlimagem') ? _place.urlimagem : newPlace.urlimagem)
  const [drive, setDrive] = useState(_place.hasOwnProperty('drive') ? _place.drive : newPlace.drive)
  const [tbvendas, setTbVendas] = useState(_place.hasOwnProperty('tbvendas') ? _place.tbvendas : newPlace.tbvendas)
  const [plantas, setPlantas] = useState(_place.hasOwnProperty('plantas') ? _place.plantas : newPlace.plantas)
  const [memorial, setMemorial] = useState(_place.hasOwnProperty('memorial') ? _place.memorial : newPlace.memorial)
  const [implantacao, setImplantacao] = useState(_place.hasOwnProperty('implantacao') ? _place.implantacao : newPlace.implantacao)
  const [divulgacao, setDivulgacao] = useState(_place.hasOwnProperty('divulgacao') ? _place.divulgacao : newPlace.divulgacao)
  const [selectedImage, setSelectedImage] = useState(_place.hasOwnProperty('file') ? _place.file : null)

  const [nuvem, setNuvem] = useState({ ...placeEdit })

  function handleInput(event) {

    if (event.target.id === 'urlimagem') {
      console.log('Edit url')
      setUrlImagem(event.target.value)
    }
    if (event.target.id === 'drive') { setDrive(event.target.value) }
    if (event.target.id === 'tbvendas') { setTbVendas(event.target.value) }
    if (event.target.id === 'plantas') { setPlantas(event.target.value) }
    if (event.target.id === 'memorial') { setMemorial(event.target.value) }
    if (event.target.id === 'implantacao') { setImplantacao(event.target.value) }
    if (event.target.id === 'divulgacao') { setDivulgacao(event.target.value) }
  }

  function setPlace() {
    _place.urlimagem = urlimagem ? urlimagem : ''
    _place.drive = drive ? drive : ''
    _place.tbvendas = tbvendas ? tbvendas : ''
    _place.plantas = plantas ? plantas : ''
    _place.memorial = memorial ? memorial : ''
    _place.implantacao = implantacao ? implantacao : ''
    _place.divulgacao = divulgacao ? divulgacao : ''
  }

  const style = {
    my: '5px',
    mr: '10px'
  }


  async function delImgEmpreeendimento() {
    const urlImg = document.getElementById('urlimagem')
    urlImg.value = ''
    const img = document.getElementById('imgEmp')
    if (img) img.setAttribute('src', 'assets/images/gimov.png')
    setNuvem({ ...nuvem, urlimagem: '' })
  }

  useEffect(() => {
    setPlaceEdit(nuvem)
    // console.log('Nuvem', nuvem)
  }, [nuvem])

  async function loadImgEmpreendimento() {
    // console.log('loadImgEmpreendimento', nuvem.urlimagem)
    let _url = ''
    if (nuvem.urlimagem) {
      if (!isUrl(nuvem.urlimagem)) {
        console.log('Não É URL', nuvem.urlimagem)
        _url = URL.createObjectURL(nuvem.file)
      }
      else {
        // console.log('É URL', nuvem.urlimagem)
        if (nuvem.urlimagem.includes('firebasestorage.googleapis.com')) _url = await loadURlImgEmpreendimentos(newPlace.id)
        else _url = nuvem.urlimagem
      }
      setNuvem({ ...nuvem, urlimagem: _url })
      if (_url) {
        const img = document.getElementById('imgEmp')
        if (img) img.setAttribute('src', _url)
      }
    }
  }

  function handleLoadImage(event) {
    var file = event.target.files[0];
    const url = URL.createObjectURL(file)
    const img = document.getElementById('imgEmp')
    img.setAttribute('src', url)
    setNuvem({ ...nuvem, file: file, urlimagem: file.name  })
  }

  useEffect(() => {
    loadImgEmpreendimento()
  }, [nuvem.urlimagem])

  return (

    <FormControl sx={{ width: '100%' }} >
      <Grid2 container spacing={2}>
        <Grid2 container xs={12} >
          <Grid2 container xs={8} >
            <TextField
              variant='standard'
              size='small'
              autoFocus
              fullWidth
              id="urlimagem"
              label="URL da Foto do empreendimento"
              value={nuvem.urlimagem ? nuvem.urlimagem : ''}
              onChange={(event) => { setNuvem({ ...nuvem, urlimagem: event.target.value }) }}
              onBlur={(event) => { setNuvem({ ...nuvem, urlimagem: event.target.value }) }}
              helperText="Link da foto do empreendimento do site da construtora"
            />
            <TextField
              variant='standard'
              fullWidth
              id="drive"
              label="Drive"
              value={nuvem.drive ? nuvem.drive : ''}
              onChange={(event) => { setNuvem({ ...nuvem, drive: event.target.value }) }}
              helperText="Link do Drive da construtora"
            />
            <TextField sx={style}
              variant='standard'
              fullWidth
              id="tbvendas"
              label="Tabela de Vendas"
              value={nuvem.tbvendas ? nuvem.tbvendas : ''}
              onChange={(event) => { setNuvem({ ...nuvem, tbvendas: event.target.value }) }}
              helperText="Pasta TABELA DE VENDAS do drive da construtora"
            />
          </Grid2>
          <Grid2 container xs={4} sx={{
            borderStyle: 'dotted',
            borderRadius: '10px',
            padding: '5px',
          }}>
            <Box height={'100%'} width={'100%'} display={'flex'} flexDirection={'column'}  >
              <Box>
                <img
                  id='imgEmp'
                  alt="..."
                  width={'100%'}
                  style={{ display: nuvem.urlimagem ? 'block' : 'none' }}
                />
              </Box>
              <Box display={'flex'} justifyContent={'flex-end'}>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  onClick={delImgEmpreeendimento}
                >-<PhotoCamera />
                </IconButton>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                >
                  <input hidden accept="image/*" type="file" onChange={handleLoadImage} />
                  +
                  <PhotoCamera />
                </IconButton>
              </Box>
            </Box>
          </Grid2>
        </Grid2>
        <Grid2 xs={12}>
          <TextField
            variant='standard'
            fullWidth
            id="plantas"
            label="Plantas"
            value={nuvem.plantas ? nuvem.plantas : ''}
            onChange={(event) => { setNuvem({ ...nuvem, plantas: event.target.value }) }}
            helperText="Pasta PLANTAS do drive da construtora"
          />
          <TextField
            variant='standard'
            fullWidth
            id="memorial"
            label="Memorial Descritivo"
            value={nuvem.memorial ? nuvem.memorial : ''}
            onChange={(event) => { setNuvem({ ...nuvem, memorial: event.target.value }) }}
            helperText="Pasta MEMORIAL DESCRITIVO do drive da construtora"
          />
          <TextField
            variant='standard'
            fullWidth
            id="implantacao"
            label="Implantação"
            value={nuvem.implantacao ? nuvem.implantacao : ''}
            onChange={(event) => { setNuvem({ ...nuvem, implantacao: event.target.value }) }}
            helperText="Pasta IMPLANTAÇÃO do drive da construtora"
          />
          <TextField
            variant='standard'
            fullWidth
            id="divulgacao"
            label="Divulgação"
            value={nuvem.divulgacao ? nuvem.divulgacao : ''}
            onChange={(event) => { setNuvem({ ...nuvem, divulgacao: event.target.value }) }}
            helperText="Pasta DIVULGAÇÃO do drive da construtora"
          />
        </Grid2>
      </Grid2>
    </FormControl>
  )
}