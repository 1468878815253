import { AppBar, Box, Grid, IconButton, Toolbar } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getLeadFasesEtapasG } from '../../constants/fases';
import { Home } from './Home';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
import { delAlerta } from '../../controllers/messageriaController';

export function Crm() {

  let navigate = useNavigate()
  const { state } = useLocation();
  const { account } = state

  const [hasNewLead, setHasNewLead] = useState(false)
  const [coordinates, setCoordinates] = useState(state.coordinates)

  const [listen, setListen] = useState(null)
  useEffect( () => {
    const listenLeads = onSnapshot(doc(db, "alerts", account.id), async (snapshot) => {
      const alert = snapshot.data()
      setListen(alert)
      if (alert) new Audio('/assets/sounds/notification_01.mp3').play()
      
    })
    return () => listenLeads();
  }, [])

  function disableAlertLead() {
    try {
      delAlerta(account.id)
      setHasNewLead(false)
    } catch (e) {
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro', e)
    }
  }
  const handleToHome = () => {
    navigate('/home', { replace: true, state: { account: account, coordinates: coordinates } })
  }

  useEffect(() => {
    let token = localStorage.getItem('token')
    getLeadFasesEtapasG(account) // Carrega as Fases e Etapas
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log(account, token);
  }, []);

  return (
    <Grid
      container
      direction={'row'}
      sx={{ backgroundColor: 'yellow' }}
    >
      {/* Menu Superior */}
      <Grid 
      container 
      height={'6vh'}
      >
        <AppBar position="fixed" >
          <Toolbar >
            <Grid container
              alignItems={'center'}
              direction={'row'}
              justifyContent={'space-between'}
            >
              <Box
                height={'25px'}
                width={'120px'}
                sx={{
                  backgroundImage: 'url(./logo2.svg)',
                  backgroundRepeat: 'no-repeat',
                }} />
              {/* Icone do menu para alerta de lead } */}
              {listen &&
                <Grid item
                  justifyContent={'center'}
                >
                  <IconButton
                    size="large"
                    edge="end"
                    aria-label="Lead"
                    color='error'
                    onClick={disableAlertLead}
                    className={'btn-pisca'}
                  >
                    <CampaignOutlinedIcon fontSize='large' /> Lead
                  </IconButton>
                </Grid>
              }
            </Grid>
          </Toolbar>
        </AppBar>
      </Grid>
      {/* Corpo da Página */}
      {/* <Container> */}
      <Grid container
        // height={'94vh'}
        direction={'row'}
      // alignItems={'center'}
      >
        <Home
          account={account}
          handleToHome={handleToHome}
          setHasNewLead={setHasNewLead}
          listen={listen}
        />
      </Grid>
      {/* </Container> */}
    </Grid>
  );
}