
export function email(e) {
  let value = e.target.value;
  e.target.maxLength = 150
  let valid = /[!#¨$%^&*)(+=/,ç"\s]+/
  value = value.replace(valid, "")
  e.target.value = value
  return e
}

export function cep(e) {
  let value = e.target.value;
  e.target.maxLength = 9
  value = value.replace(/\D/g, "")  // Remove o que não é numero
  value = value.replace(/^(\d{5})(\d)/, "$1-$2")
  e.target.value = value
  return e
}
export function convite(e) {
  let value = e.target.value.toUpperCase();
  e.target.maxLength = 9
  value = value.replace(/[^\w@-]/, "")
  value = value.replace(/(\w{4})([A-Z]|[0-9])/g, "$1-$2")
  e.target.value = value
  return e
}

export function mesano(e) {
  let value = e.target.value;
  e.target.maxLength = 5
  value = value.replace(/\D/g, "")
  value = value.replace(/(\d)(\d{2})$/, "$1/$2")
  e.target.value = value.toUpperCase()
  return e.target.value
}

export function nome(e) {
  let value = e.target.value;
  e.target.maxLength = 150
  value = value.replace(/[0-9!@#¨$%^&*)(+=._-]+/g, "")
  e.target.value = value
  return e.target.value
}

export function nrcreci(e) {
  let value = e.target.value;
  e.target.maxLength = 7
  value = value.replace(/[^a-zA-Z0-9]/, '')
  e.target.value = value.toUpperCase()
  return e.target.value
}

// função para formatar moeda com ponto e vírgula
export function currency(e, moeda) {
  let value = e.target.value;

  // Remove todos os caracteres não numéricos
  let valor = value.replace(/\D/g, "");

  // Adiciona a vírgula para os centavos
  valor = valor.replace(/(\d{2})$/, ",$1");

  // Adiciona o ponto para os milhares
  valor = valor.replace(/(?=(\d{3})+(\D))\B/g, ".");

  e.target.value = moeda +' '+ valor;
  return e.target.value;
}


export function cnpj(e) {
  let value = e.target.value;
  e.target.maxLength = 14
  value = value.replace(/\D/g, "")
  value = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
  e.target.value = value
  return e.target.value
}

export function cpf(e) {
  let value = e.target.value;
  e.target.maxLength = 11
  value = value.replace(/\D/g, "")
  value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  e.target.value = value
  return e.target.value
}

export function url(e) {
  let value = e.target.value;
  e.target.maxLength = 150
  value = value.replace("^(http|https?://(?:www.|(?!www))[^s.]+.[^s]{2,3}|www.[^s]+.[^s]{2,3})$")
  e.target.value = value
  return e.target.value
}

// (00) 00000-0000
export function phone(e) {
  let value = e.target.value;
  e.target.maxLength = 14
  value = value.replace(/\D/g, "")
  value = value.replace(/(\d{2})(\d)/, "($1) $2")
  value = value.replace(/(\d{5})(\d{4})(\d)/, "$1-$2")
  e.target.value = value
  return e.target.value
}

// 00/00/0000 
export function date(e) {
  let value = e.target.value;
  e.target.maxLength = 10
  value = value.replace(/\D/g, "")
  value = value.replace(/(\d{2})(\d)/, "$1/$2")
  value = value.replace(/(\d{2})(\d)/, "$1/$2")
  value = value.replace(/(\d{4})(\d)/, "$1")
  e.target.value = value
  return e.target.value
}

// Aceita apenas que letras sejam digitadas
export function letters(e) {
  console.log(e)
  let value = e.target.value;
  value = value.replace(/[0-9!@#¨$%^&*)(+=._-]+/g, "")
  e.target.value = value
  return e.target.value
}

// Aceita apenas decimais
export function decimal(e) {
  let value = e.target.value;
  value = value.replace(/[^0-9.]/g, "")
  e.target.value = value
  return e.target.value
}

// Aceita apenas números
export function numbers(e) {
  let value = e.target.value;
  e.target.value = value.replace(/\D/g, "")
  return e.target.value
}

// Aceita letras, numeros e caracteres especiais
export function text(e) {
  let value = e.target.value;
  value = value.replace(/[/]/, "")
  // value = value.replace(/\s/g,"")
  e.target.value = value
  return e.target.value
}